import axios from "../../config/axios"
import { base_url_machine_learning, base_url_general } from '../../config/base_url'

export async function get3DAvatars() {
    try {
        const res = await axios.get(base_url_machine_learning + 'master_avatars', {
            params: {
                type: '3D',
            },
        });
        return res.data;
    } catch (error) {
        const data = { status: false, response: error };
        return data;
    }
}

export async function getHumanAvatars() {
    try {
        const res = await axios.get(base_url_machine_learning + 'master_avatars', {
            params: {
                type: 'Human',
            },
        });
        return res.data;
    } catch (error) {
        const data = { status: false, response: error };
        return data;
    }
}

export async function getAvatarsByCategory() {
    try {
        const res = await axios.get(base_url_machine_learning + 'master_avatars/get_by_category');
        return res.data;
    } catch (error) {
        const data = { status: false, response: error };
        return data;
    }
}
export async function toggleFavAvatars(id) {
    try {
        const res = await axios.get(base_url_machine_learning + 'master_avatars/favorite/'+id);
        return res.data;
    } catch (error) {
        const data = { status: false, response: error };
        return data;
    }
}

export async function getSVM(lang) {
    return axios.get(base_url_machine_learning + 'tts/v2/suggestions?lang=' + lang)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function getImageAiGenerator(formData) {
    return axios.post(base_url_machine_learning + 'tts/v2/image', formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = error.response.data
        return data;
    })
}

export async function voiceCloneSample(formData) {
    return axios.post(base_url_machine_learning + 'tts/v2/voice_sample', formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = error.response.data
        return data;
    })
}
export async function voiceCloneSampleGuest(formData) {
    return axios.post(base_url_machine_learning + 'tts/v2/voice_sample/guest', formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = error.response.data
        return data;
    })
}

export async function generateVoiceModel(formData) {
    return axios.post(base_url_machine_learning + 'tts/v2/generate_voice_models', formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = error.response.data
        return data;
    })
}

export async function getVoiceModel() {
    return axios.get(base_url_machine_learning + 'tts/v2/voice_models')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function clearVoiceModel() {
    return axios.get(base_url_machine_learning + 'tts/v2/clear_voice_models')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function useVoiceModel(formData) {
    return axios.post(base_url_machine_learning + 'tts/v2/use_voice_models', formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = error.response.data
        return data;
    })
}

export async function generateFinalTTV(formData) {
    return axios.post(base_url_machine_learning + 'prosa/char', formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = error.response.data
        return data;
    })
}

export async function getTTVWaitingList() {
    return axios.get(base_url_general + 'api/waiting-list-ttv/check')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function deleteVoiceModelCustom(id) {
    return axios.get(base_url_machine_learning + 'tts/v2/delete_voice_models/' + id)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function updateVoiceModel(formData, id) {
    return axios.post(base_url_machine_learning + 'tts/v2/update_voice_models/' + id, formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = error.response.data
        return data;
    })
}
