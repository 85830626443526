<template>
    <div>
        <b-modal id="modal-join-quiz" ref="subs" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md">
            <div class="float-right">
                <img src="@/assets/images/icons/ic_close_grup.svg" @click="$bvModal.hide('modal-join-quiz')" alt="close" class="mr-auto" type="button">
            </div>
            <div class="mt-5 text-center">
                <h3>Gabung dan seru-seruan bareng!</h3>
                <div class="container w-75 mt-3 mb-4">
                    <form @submit.prevent="joinQuiz" class="submit-pin">
                        <b-alert variant="danger" :show="alert" fade><small>Masukan PIN Quiz dengan benar ya!</small></b-alert>
                        <input type="number" v-model="code" class="form-control mb-2" placeholder="Masukan PIN" id="inputCode">
                        <button class="btn btn-purple btn-block"><p class="mb-0 font-weight-bold text-white">Gabung Sekarang</p></button>
                    </form>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data(){
        return {
            code: null,
            alert: false,
        }
    },
    watch: {
        code(val) {
            if(val.length == 6){
                this.alert = false;
            }
        }
    },

    methods: {
        joinQuiz(){
            if(this.code.length < 6){
                this.alert= true;
            } else {
                console.log(this.code.length)
                this.alert= false;
                window.location = "https://play.soca.ai/event/" + this.code
            }
        }
    }
}
</script>

<style scoped>
.submit-pin input{
  text-align: center;
}

.submit-pin input::-webkit-input-placeholder {
  text-align: center;
}

.submit-pin input :-moz-placeholder { /* Firefox 18- */
   text-align: center;
}

.submit-pin input ::-moz-placeholder {  /* Firefox 19+ */
   text-align: center; 
}

.submit-pin input:-ms-input-placeholder {  
   text-align: center; 
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


</style>

<style>
#modal-join-quiz .modal-content{
    border-radius: 20px !important;
    width: 90% !important;
}
</style>