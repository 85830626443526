<template>
    <b-modal
    id="info-access-autodubb"
    size="md"
    centered
    hide-header
    hide-footer
    dialog-class="svm__dialog d-flex justify-content-center"
    content-class="info-autodubb__content"
    body-class="p-0"
    @hidden="$emit('hidden')"
  >
  <div class="ad-uc">
      <div class="ad-uc__inner p-4 position-relative">
		<div class="close--wrapper">
            <button class="btn d-flex justify-content-center align-items-center p-0" @click="$bvModal.hide('info-access-autodubb')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            <!-- <button class="btn d-flex close--button justify-content-center align-items-center p-0" @click="$bvModal.hide('info-access-autodubb')"><b-icon icon="x"></b-icon></button> -->
        </div>

        <h6 class="title--modal__upload">Auto-Dubbing Studio is Coming Soon!</h6>
		<p class="mt-5">Imagine effortlessly dubbing your videos with <br> professional-grade voiceovers in just a few clicks. Our <br> Auto-Dubbing Studio is designed to make your content <br> not just visually stunning but audibly captivating too.</p>

		<p class="mt-3">Are you eager to be a pioneer in using our Auto-Dubbing Studio? Let us know, and you could be among the first to enjoy an exclusive early access preview! Simply <a class="contact__us-title" target="_blank" href="https://wa.me/message/2FGAFWFOX6MQE1">contact us</a> and explain your reasons. </p>
		<div class="d-flex align-items-center justify-content-center">
			<button class="btn btn--purple mt-3" @click="requestAccess">Request Access</button>
		</div>
		</div>
	</div>
  </b-modal>
</template>

<script>
import { AlertUtils } from "@/mixins/AlertUtils";
// import ffmpeg from 'ffmpeg.js/ffmpeg-mp4.js';

export default {
	mixins: [AlertUtils],
	data() {
		return {
		}
	},
	computed: {
	},
	methods: {
		requestAccess() {
            let url = "https://bit.ly/Regist-CC23";
            window.open(url, "_blank");
			this.$bvModal.hide('info-access-autodubb')
		}
	},
	mounted() {
	},
	beforeDestroy() {
	},
}
</script>

<style scoped>
.contact__us-title{
	color: #6D75F6;
	font-family: Avenir Next;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	text-decoration: none;
}
.btn--purple{
	border-radius: 4px;
	background: #6D75F6;
}
.btn--purple {
	background-color: #6D75F6;
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	border-radius: 5px;
	padding: 6px 52px;
	transition: background-color 0.25s;
}

.btn--purple:not(:disabled):hover {
	background-color: #545EED;
	transition: background-color 0.25s;
}

.ad-uc .title--modal__upload{
    color: #FFF;
    font-family: 'Avenir Next';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}
.ad-uc {
	padding: 2px;
	background-image: linear-gradient(to bottom right, #FFFFFF33, #FFFFFF11);
	border-radius: 8px;
}

.ad-uc__inner {
	background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
	border-radius: 8px;
	backdrop-filter: blur(2px);
	gap: 16px;
	padding-top: 22px;
	padding-bottom: 22px;
}

.close--wrapper {
	position: absolute;
	top: 10px;
	right: 10px;
}

.close--button{
    background-color: #EDEDED;
    border-radius: 50%;
    width: 27px;
    height: 27px;
}
</style>

<style>
.info-autodubb__content{
    width: 435px !important;
}
</style>