<template>
   <div id="parentx">
        <vs-sidebar ref="sidebar" default-index="1" :parent="parent" color="primary" class="sidebarx" spacer v-model="isSidebarActive" click-not-close>
            <div class="sidebar__container d-flex flex-column mx-3">
                <div v-for="(data, index) in updateSidebarAction" :key="index" class="wrapper">
                    <button :disabled="data.soon" class="sidebar__button btn" :class="{ 'sidebar__button-active' : data.isAccordionShow || data.routeName == $route.name }" @click="handleSidebarButton(index)">
                        <img :src="data.icon" alt="Home" class="sidebar__button-img">
                        <p class="mb-0 ml-2">{{ $t(data.name) }} <i v-if="data.soon" style="font-size:12px;">(soon)</i></p>
                        <p v-if="data.beta" style="font-size:8px !important;" class="mb-2">BETA</p>
                        <font-awesome-icon v-if="data.isDropdown" icon="fa-solid fa-chevron-right" class="sidebar__button-icon" :class="{ 'rotate' : data.isAccordionShow }"/>
                    </button>
                    <div v-if="data.isDropdown" class="accordion-wrapper" :class="{ 'open' : data.isAccordionShow }" key="accordion">
                        <button v-for="(accordion, subI) in data.accordionContent" :key="subI" class="sidebar__button-accordion btn py-2" :class="{ 'sidebar__button-accordion-active' : accordion.routeName == $route.name }" :disabled="accordion.unAvailable" @click="handleSubSidebarButton(accordion.routeName, accordion.params)">
                            {{ accordion.name }} <span v-if="accordion.unAvailable" class="soon">(soon)</span>
                        </button>
                    </div>
                    <hr v-if="index == 4" class="my-3">
                </div>
            </div>

            <div class="footer-sidebar" slot="footer">
                <div class="plans mx-3 mt-3 mb-2 py-3">
                    <div class="container-fluid">
                        <!-- <div class="d-flex align-items-center justify-content-between pt-3 pb-1">
                            <h6 class="text-white">Workspace</h6>
                            <div class="cs">
                                <b-form-checkbox v-model="status_subscriptions.community_mode" name="check-button" switch @change="switchCommunity"></b-form-checkbox>
                            </div>
                        </div> -->
                        <div v-if="status_subscriptions.community_name" class="text-left csnc mb-1">
                            <span :title="status_subscriptions.community_name" class="csn w-100 text-truncate">({{ status_subscriptions.community_name }})</span>
                        </div>
                        <div class="aq d-flex justify-content-between">
                            <button class="aq__button-left btn" :disabled="activeSlide == 0" @click="activeSlide--">
						        <font-awesome-icon icon="fa-solid fa-chevron-left" />
                            </button>
                            <ul class="aq__slider list-unstyled m-0 py-2">
                                <li>
                                    <div class="d-flex align-items-center justify-content-center text-left">
                                        <img src="@/assets/images/icons/ic_package_audio2.png" alt="audio" class="img-fluid quota-info__img">
                                        <div class="ml-3">
                                            <h4 class="m-0 quota-info__title" >{{ calculateAudioQuota(status_subscriptions) }}</h4>
                                            <h6 class="m-0 quota-info__subtitle">mins</h6>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex align-items-center justify-content-center text-left">
                                        <img src="@/assets/images/icons/ic_package_img.png" alt="image" class="img-fluid quota-info__img">
                                        <div class="ml-3">
                                            <h4 class="m-0 quota-info__title">{{ status_subscriptions.image_generates_quota ? status_subscriptions.image_generates_quota : '0' }}</h4>
                                            <h6 class="m-0 quota-info__subtitle">images</h6>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex align-items-center justify-content-center text-left">
                                        <img src="@/assets/images/icons/ic_package_swc.png" alt="SWC" class="img-fluid quota-info__img">
                                        <div class="ml-3">
                                            <h4 class="m-0 quota-info__title">{{ status_subscriptions.swc_quota ? status_subscriptions.swc_quota : '0' }}</h4>
                                            <h6 class="m-0 quota-info__subtitle">SWC</h6>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <button class="aq__button-right btn" :disabled="activeSlide == 2" @click="activeSlide++">
						        <font-awesome-icon icon="fa-solid fa-chevron-right" />
                            </button>
                        </div>
                    </div>
                    <div v-if="loading_subscriptions" class="pb-2">
                        <vs-button color="primary" type="filled" class="mt-2 btn-block-sax py-1">
                            <b-skeleton animation="wave" width="50%"></b-skeleton>
                        </vs-button>
                    </div>
                    <div v-else class="topup-content pb-2">
                        <vs-button color="primary" type="filled" class="mt-2 btn-block-sax py-1" @click="upgradePlans()"> <font-awesome-icon icon="fa-solid fa-plus" class="mr-1"/> {{ $t('top-up') }}</vs-button>
                    </div>
                </div>
                <p class="version__text mb-2">App Vers. 2.16.5</p>
            </div>
        </vs-sidebar>
        <!-- MODAL ALERT RESPONSE -->
        <b-modal id="alert-response" no-close-on-backdrop centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
                <div class="float-right">
                    <button class="btn btn-close d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('alert-response')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
                </div>
                <div class="mt-5 text-center mb-3">
                    <img :src="iconAlert" alt="sovia">
                    <div class="mt-2">
                        <h5 class="font-weight-bold">{{ titleAlert }}</h5>
                    </div>
                    <div class="mt-2">
                        <span>{{ textAlert1 }}</span>
                        <br>
                        <span>{{ textAlert2 }}</span>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                        <button class="btn btn-primary btn-purple-nb" @click="$bvModal.hide('alert-response')"><p class="mx-2 mb-0">Close</p></button>
                    </div>
                </div>
        </b-modal>
        <DropRight :show-menu='showMenu'/>
        <Nca :message="response_message" />
        <ADInfoAcessModal />
    </div>
</template>

<script>
import { subscriptionsStatus } from '@/services/subscriptions/subscriptions.service'
import { base_url_home } from '@/config/base_url'
import { switchCommunity } from '@/services/community/community.service'
import { EventBus } from '@/helper/event-bus.js';
import { getTTVWaitingList } from '@/services/ttv/ttv.service.js'
import { tns } from 'tiny-slider/src/tiny-slider';
import { createNewsReader } from '@/services/news-reader/news-reader.service'
import { autodubbCheckAccess } from '@/services/auto-dub/autodub.service'

import 'tiny-slider/dist/tiny-slider.css';
import Nca from '@/components/community/modal/Nca'
import DropRight from './DropRight.vue'
import ADInfoAcessModal from "@/components/home/modal/ModalAutoDubbAccess.vue"

// import $ from "jquery"

export default {
    name: "SideBar",
    components: {
        DropRight,
        Nca,
        ADInfoAcessModal
    },
    props: {
        parent: {
            type: String
        },
        sidebarLinks: {
            type: Array,
            required: true,
            },
            index: {
                default: null,
                type: [String, Number]
        }
    },
    data:()=>({
        open : true,
        quiz : true,
        community_active : false,
        windowWidth: window.innerWidth,
        users:{
            _id: null,
            email: null,
        },
        accountInfo : [],
        finger: '',
        loading: false,
        status_subscriptions: [],
        loading_subscriptions: false,
        showMenu : false,
        iconAlert: null,
        response_message : null,
        titleAlert: "",
        textAlert1: "",
        textAlert2: "",
        ai_generator : [
            {
                icon : 'ic_bulat.svg',
                title : 'AI Quiz Maker'
            },
            {
                icon : 'ic_kotak.svg',
                title : 'AI Voice Transcriptions'
            },
            {
                icon : 'ic_segienam.svg',
                title : 'AI Characters'
            },
        ],

        slider: null,
        activeSlide: 0,

        sidebarAction: [
            {
                name: 'home--sidebar',
                icon: require('@/assets/images/sidebar/ic_home.svg'),
                routeName : 'Home'
            },
            {
                name: 'generate-videos--sidebar',
                icon: require('@/assets/images/sidebar/ic_vs.svg'),
                routeName : 'Generate-Videos',
                params: true
            },
            {
                name: 'voice-cloning--sidebar',
                icon: require('@/assets/images/sidebar/ic_voice-clone.svg'),
                routeName : 'Personal-Voice'
            },
            {
                name: 'auto-dubbing--sidebar',
                icon: require('@/assets/images/sidebar/ic_auto-dubbing.svg'),
                routeName : 'AutoDubbing',
                params: true,
                beta: true,
                soon: false,

            },
            {
                name: 'news-reader--sidebar',
                icon: require('@/assets/images/sidebar/ic_news-reader.svg'),
                routeName : 'NewsReader'
            },
            // {
            //     name: 'VIDEOS',
            //     icon: require('@/assets/images/sidebar/ic_videos.svg'),
            //     isDropdown: true,
            //     isAccordionShow: false,
            //     accordionContent: [
            //         {
            //             name : 'Generate Videos',
            //             routeName : 'Generate-Videos',
            //             params: true
            //         },
            //         {
            //             name : 'AI Characters',
            //             routeName : 'Ai-Characters'
            //         },
            //         {
            //             name : 'Personal Voices',
            //             routeName : 'Personal-Voice'
            //         },
            //     ]
            // },
            // {
            //     name: 'QUIZZES',
            //     icon: require('@/assets/images/sidebar/ic_ttq_sidebar.svg'),
            //     isDropdown: true,
            //     isAccordionShow: false,
            //     accordionContent: [
            //         {
            //             name : 'Generate Quizzes',
            //             routeName : 'Generative-Ai',
            //             params: true
            //         },
            //         {
            //             name : 'Quiz Dashboard',
            //             routeName : 'Dashboard'
            //         },
            //     ],
            // },
            {
                name: 'projects--sidebar',
                icon: require('@/assets/images/sidebar/ic_collections_d2.svg'),
                routeName : 'My-Quiz'
            },
            {
                name: 'my-assets--sidebar',
                icon: require('@/assets/images/sidebar/ic_my-assets.svg'),
                routeName : 'My-Assets',
            },
            // {
            //     name: 'PLUGINS',
            //     icon: require('@/assets/images/sidebar/ic_plugins.svg'),
            //     routeName : 'Plugins'
            // },
            // {
            //     name: 'DASHBOARD',
            //     icon: require('@/assets/images/sidebar/ic_chart_d2.svg'),
            //     routeName : 'Dashboard'
            // },
        ],

        isCheck: false,

    }),
    computed: {
        //This is for mobile trigger
        isSidebarActive: {
            get() {
            return this.$store.state.isSidebarActive
            },
            set(val) {
            this.$store.commit('IS_SIDEBAR_ACTIVE', val)
            }
        },
        updateSidebarAction() {
            return this.sidebarAction
        }
    },
    watch: {
        activeSlide(newVal) {
            this.slider.goTo(newVal)
        }
    },

    created() {
        EventBus.$on('triggerSidebarMethod', () => {
            this.getStatusSubscriptions();
        });
    },
    mounted() {
        this.$nextTick(() => {
                window.addEventListener('resize', this.handleWindowResize);
            });
        this.setSidebar();
        document.addEventListener('click', this.hideSidebarWhenClickOutside);
        document.addEventListener('click', this.hideCreateWhenClickOutside)
        this.getParamsRouteDynamic();
        this.getStatusSubscriptions();
        this.sliderInit()
        this.checkSubMenuActive()
        // this.checkAccessAD()

        // this.loading = true;
        if(localStorage.getItem('users')){
            this.users = JSON.parse(localStorage.getItem('users'))
            this.loading = false;
        } else {
            this.loading = false;
        }

    },
    methods : {
        calculateAudioQuota(quota){

            let audio = 0
            let multi = 0
            
            if(quota.audio_generates_quota != null){
                audio += quota.audio_generates_quota
                
            }
            
            if(quota.audio_multilingual_generates_quota != null){
                multi += quota.audio_multilingual_generates_quota
            }
            return (audio  + multi).toFixed(2)
        },
        handleWindowResize(event) {
            this.windowWidth = event.currentTarget.innerWidth;
            this.setSidebar();
        },

        setSidebar() {
            if (this.windowWidth < 1169) {
                if (this.$store.state.isSidebarActive !== this.$store.state.isSidebarActiveByHamburger) {
                    this.$store.commit('IS_SIDEBAR_ACTIVE', false);
                }
            }
            else {
                this.$store.commit('IS_SIDEBAR_ACTIVE', true);
                // $("#main-container").addClass("stretch-margin")

            }
        },

        hideSidebarWhenClickOutside(e) {
            if (this.windowWidth < 1169 && this.$store.state.isSidebarActive && !this.$refs.sidebar.$el.contains(e.target) && !document.getElementById('hamburger-button').contains(e.target)) {
                this.$store.commit('IS_SIDEBAR_ACTIVE', false);
                this.$store.commit('IS_SIDEBAR_ACTIVE_BY_HAMBURGER', false);
            }
        },

        hideCreateWhenClickOutside(e) {
            if (this.showMenu && !this.$refs.createAction.contains(e.target)) {
                this.showMenu = false;
            }
        },

        getParamsRouteDynamic(){
            let users = localStorage.getItem('users')
            if(users == undefined){
                let usersId = sessionStorage.getItem("users_id")
                if(usersId == undefined){
                    this.$fingerprint.get((components) => {
                        this.finger = this.$fingerprint.x64hash128(components.map((pair) => { return pair.value }).join(), 31)
                        sessionStorage.setItem("users_id", this.finger);
                     });
                }
            } else {
                sessionStorage.removeItem("users_id");
            }

            // console.log("this user login",this.usersNotLogin)
        },

        buatKonten(){
            this.$store.commit('IS_SIDEBAR_ACTIVE', true);
            this.$bvModal.show('modal-create-content')
            // this.$router.push({ name: 'Create-Content'})
        },

        signIn(){
            this.$store.commit('IS_SIDEBAR_ACTIVE', true);
            this.$emit('signInModal')
        },

        upgradePlans(){
            if(this.users) {
                this.$router.push({ name : 'TopUp' })
            } else {
                this.signIn()
            }
        },

        getStatusSubscriptions(){
            this.loading_subscriptions = true;

            subscriptionsStatus()
            .then((response) =>{
                if(response.status == true ){
                    this.status_subscriptions = response.data
                    this.loading_subscriptions = false;
                } else if(response.status == false && response.message == 'No Allocations') {
                    this.loading_subscriptions = false;
                } else {
                    this.loading_subscriptions = false;
                    // localStorage.clear();
                    // this.$router.push({ name : 'SignIn' })
                    console.log(response)
                }
            })
        },

        openInNewTab() {
            window.open(base_url_home + 'products/library', "_blank");
        },

        waitingList() {
            window.open(base_url_home + 'products/text-to-video', "_blank");
        },

        showAlertTTQMaintenance(){
            this.iconAlert = require('@/assets/images/icons/modal_warning.svg');
            this.titleAlert = "Maintenance";
            this.textAlert1 = "We are preparing improvements to Text to Quiz. Get ready to get a better quiz-making experience";
            this.textAlert2 = "";
            this.$bvModal.show('alert-response');
        },

        toTtq(){
            localStorage.removeItem('listQuestionCreate')
            localStorage.removeItem('materiAi')
            localStorage.removeItem('subGradeAi')
            localStorage.removeItem('gradeAi')
            localStorage.removeItem('lengthQuestionAi')
            this.$router.push({ name: 'Generative-Ai'})
        },

        toTta(){
            this.$router.push({ name : 'Generate-Text-To-Audio' , params: { mode : 'new', id : '1' }} ).catch((err) => console.log(err))
        },

        switchCommunity() {
            switchCommunity()
            .then((response) => {
                if(response.status && response.code == 200){
                    this.getStatusSubscriptions()
                } else  if(!response.status && response.code == 400){
                    this.getStatusSubscriptions()
                    this.response_message = response.message
                    this.$bvModal.show('nca-modal')
                }
            })
        },

        beforeEnterTtv() {
            getTTVWaitingList()
            .then((response) => {
                if(response.status) {
                    this.$router.push({ name : 'Generative-Ai-Text-To-Video' })
                } else {
                    this.waitingList()
                }
            })
        },

        sliderInit() {
            this.$nextTick(() => {
                this.slider = tns({
                    container: '.aq__slider',
                    center: true,
                    controls: false,
                    nav: false,
                    speed: 500,
                    loop: false,
                    gutter: 24,
                })
            })
        },

        handleSidebarButton(index) {
            let data = this.sidebarAction[index]

            if(data.isDropdown) {
                this.sidebarAction.forEach((e, i) => {
                    if(e.isDropdown && i != index) {
                        e.isAccordionShow = false
                    }
                })
                data.isAccordionShow = !data.isAccordionShow
            } else {
                if(data.routeName == 'library') {
                    this.openInNewTab()
                } else if (data.routeName == 'NewsReader') {
                    if(this.$route.name != 'NewsReader') {
                        this.toNewsReader()
                    }
                } else if (data.routeName == 'AutoDubbing') {
                    this.checkAccessAD()
                } else {
                    if(data.params) {
                        this.handleSubSidebarButton(data.routeName, data.params)
                    } else {
                        this.$router.push({ name : data.routeName })
                    }
                }
            }
        },

        handleSubSidebarButton(val, params) {
            if(params) {
                this.$router.push({ name : val, params : { mode : 'new', id : '1' } })
            } else {
                this.$router.push({ name : val })
            }
        },
        checkSubMenuActive() {
            let routeName = this.$route.name
            if(routeName == 'Ai-Characters') {
                this.sidebarAction[1].isAccordionShow = true
            } else if(routeName == 'Dashboard') {
                this.sidebarAction[2].isAccordionShow = true
            }
        },
        toNewsReader() {
            createNewsReader()
            .then((response) => {
                if(response.status) {
                    this.removeDataNewsReaderFromStorage()
                    this.$router.push({ name : 'NewsReader', params : { mode : 'create', id : response.data.content_id } })
                }
            })
        },

        removeDataNewsReaderFromStorage() {
			sessionStorage.removeItem('news-reader::settings')
			sessionStorage.removeItem('news-reader::generated-settings')
			sessionStorage.removeItem('news-reader::avatar')
			sessionStorage.removeItem('news-reader::generated-avatar')
			sessionStorage.removeItem('news-reader::result-text')
			sessionStorage.removeItem('news-reader::generated-result-text')
			sessionStorage.removeItem('news-reader::web-url')
			sessionStorage.removeItem('news-reader::generated-web-url')
			sessionStorage.removeItem('news-reader::embed')
			sessionStorage.removeItem('news-reader::get-from-api')
		},

        checkAccessAD() {
            this.isCheck = true
            autodubbCheckAccess()
            .then((response) => {
                if(response.status) {
                    this.$router.push({ name : 'AutoDubbing', params : { mode : 'new', id : '1' } })
                } else {
                    this.$bvModal.show('info-access-autodubb')
                }
                this.isCheck = false
            })
            .catch(() => {
                this.isCheck = false
            })
        }

    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize);
        this.setSidebar();
        this.slider.destroy();
        document.removeEventListener('click', this.hideSidebarWhenClickOutside);
        document.removeEventListener('click', this.hideCreateWhenClickOutside)
    },

    destroy() {
        this.slider.destroy();
    }
}
</script>
<style scoped>
p {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.csnc {
    line-height: 5px;
}

.csn {
    font-size: 10px;
    color: #8C8C8C;
    text-align: left;
}

.vs-avatar--con-img img{
    display: none !important;
}
.vs-avatar--con-img {
    background: #c4c4c4 !important;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
}

.text-purple {
    color: #6D75F6;
}

.btn-block {
    width: 80% !important;
}

.btn-block-sax {
    width: 83% !important;
}

.f-12{
    font-size: 12px !important;
}

.plans{
    background-color: #222222;
    border-radius: 8px;
}

.plans small{
    font-size:10px;
}

.plans ul li::marker{
    font-size: 1.2em;
}

.card-swc, .swc-info{
    background-color: #222222;
    border-radius: 8px;
}

.swc-info{
    position: absolute;
}

hr.hr-swc{
    height: 0.5px;
    background: #585859
}

hr {
    height: 0.5px;
    background: #1C1C1C
}

.items-create {
    display: flex;
    align-items: center;
    color: white;
}

.sub-sidebar::-webkit-scrollbar {
    width: 6px;
}

        /* Track */
.sub-sidebar::-webkit-scrollbar-track {
    background: transparentx;
}

        /* Handle */
.sub-sidebar::-webkit-scrollbar-thumb {
    background: #1e1d1d;
    border-radius: 4px;
}

        /* Handle on hover */
.sub-sidebar::-webkit-scrollbar-thumb:hover {
    background: #161616;
}

.aq__button-left, .aq__button-right {
    box-shadow: none;
    padding: 0;
    color: #fff
}

.aq__button-left:disabled, .aq__button-right::disabled {
    color: #8C8C8C
}

.aq__slider {
    overflow: hidden;
}

.bb {
    border-bottom: 0.1px solid #2D2D2D;
}

.sidebar__button {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #8C8C8C;
    border-radius: 4px;
    width: 100%;
    padding: 10px 15px;
    transition: background-color 0.25s;
}

.sidebar__button-active {
    background-color: #1F1F1F;
    color: white;
}

.sidebar__button:hover:not(.sidebar__button-active):not(:disabled) {
    color: white;
    background-color: #1F1F1F;
}

.sidebar__button:hover:not(:disabled) .sidebar__button-img, .sidebar__button-img-active, .sidebar__button-active .sidebar__button-img {
    filter: brightness(4.5);
}

.sidebar__container {
    gap: 5px;
}

.sidebar__button-icon {
    margin-left: auto;
    font-size: 12px;
    transition: transform 0.1s ease;
}

.sidebar__button-icon.rotate {
    transform: rotate(90deg);
}

.sidebar__button-accordion {
    width: 100%;
    padding-left: 40px;
    box-shadow: none;
    background-color: transparent;
    color: #8C8C8C;
    text-align: left;
}

.sidebar__button-accordion-active {
    color: white;
}

.sidebar__button-accordion:hover:not(:disabled) {
    color: white;
}
.accordion-wrapper {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.1s ease;
}
.accordion-wrapper.open {
    max-height: 500px;
    transition: max-height 0.7s ease;
}

.soon {
    font-size: 10px;
}

.version__text {
    color:#585859;
    font-size: 12px !important;
}
</style>

<style>
.cs .custom-control-input:checked ~ .custom-control-label::before{
    background-color: #40BE45;
    border-color: #40BE45;
}

.vs-sidebar--item a{
    border-radius: 4px !important;
    color: #8C8C8C;
}

.vs-sidebar--item a span{
    font-size: 14px !important;
}

.vs-sidebar-primary .vs-sidebar--item a:hover {
    color: white;
}

.vs-sidebar-primary .vs-sidebar-item-active a:hover {
    color: white !important;
}

.vs-sidebar-primary .vs-sidebar--item:hover{
    font-weight: bold;
    color: white !important;
    background: #1F1F1F;
    opacity: 1 !important;
    border-radius: 4px;

}

.vs-sidebar-primary .vs-sidebar--item:hover a img{
    filter: brightness(4.5);
}

.vs-sidebar-primary .vs-sidebar-item-active a {
    color: #8C8C8C !important;
}

.tooltip {
    margin-top: 10px;
}

.sidebarx .dropright .dropdown-toggle::after {
    display: none !important;
}

.sidebarx .dropdown-menu.show {
    background: #1F1F1F;
    display: grid;
    width: 250px;
    text-align: left;
    box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
}

.sidebarx .dropdown-menu span {
    font-size: 12px;
    color: #585859;
}

.sidebarx .dropdown-item {
    color: white;
}

.sidebarx .dropdown-item:hover {
    background-color: #8C8C8C;
}

.sidebarx .router-link-active span {
    font-weight: 700 !important
}

.icon-sidebar {
    width: 14px;
}

.bar-plan {
    /* border-bottom: 0.1px solid #585859; */
}

.bar-plan .bg-primary {
    background-color: #6D75F6 !important;
}

.bar-plan .progress {
    background-color: #2D2D2D;
}

.icon-sidebar-16{
    width: 16px;
}

.topup-content .vs-button--text {
    position: relative;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
}

.quota-info__title {
    color: white;
    font-weight: 900;
    font-size: 18px;;
}

.quota-info__subtitle {
    color: #ffffff;
    font-size: 12px !important;
    font-weight: 100;
}

.quota-info__img {
    width: 42px;
    height: 42px;
}

#alert-response .modal-content{
        background-color: #1F1F1F;
        color: white;
        border-radius: 15.0499px;
}

.aq .tns-outer {
    width: 90%;
}
</style>