<template>
    <div class="main-wrapper">
		<!---Navigation-->
        <!-- <NavbarTtv v-if="$route.name == 'Text-To-Video-Ai' || $route.name == 'Generate-Text-To-Audio'" /> -->
        <Navbar v-if="$route.name != 'Text-To-Video-Ai' && $route.name != 'Generate-Text-To-Audio' && $route.name != 'Generative-Ai-True-False' && $route.name != 'Generative-Ai' && $route.name != 'Generative-Ai-Code-Test'" :topbarColor="topbarColor" :logo="require('@/assets/images/logo/logo-light-icon.png')" :title="logotitle" />
        <!---Sidebar-->

        <!-- <SideBar parent=".main-wrapper" :sidebarLinks="sidebarLinks" @buatQuizModal="openModal" @signUp="signUp"/> -->
        <SideBar v-if="sidebarOnlyIn()" parent=".main-wrapper" :sidebarLinks="sidebarLinks" @buatQuizModal="openModal" @signInModal="signInModal"/>

        <!---Page Container-->

        <div  v-if="mainContainerPadding()" class="main-container-fluid-np">

		<router-view ></router-view>

		</div>

        <div v-else class="main-container-fluid" id="main-container">

		<router-view ></router-view>

        </div>

        <!-- MODAL BUAT QUIZ -->
        <b-modal id="modal-buat-quiz" centered header-class="border-0 pt-4 pb-0" footer-class="border-0 pb-3 pt-0 mx-auto">
            <template #modal-header>
                <h4 class="mb-0 font-weight-bold">Buat Quiz</h4>
            </template>
            <b-form-group id="label-penilai" label-size="lg" label="Nama Quiz">
                <b-form-input id="input-penilai" type="text" placeholder="Masukan Nama Quiz" v-model="formData.quiz_name"></b-form-input>
            </b-form-group>
            <h4 for="grade" class="font-weight-normal">Pilih Grade</h4>
            <div class="row container">
                <div class="col-auto my-2 mr-2" v-for="(value, index) in grades" :key="index" @click="id_grade = value._id">
                    <a @click="setGrade(value._id)" href="#" class="badge" :class="value._id == id_grade ? 'badge-active' : 'badge-nonactive'"><h5 class="mx-2 my-1 font-weight-normal">{{ value.grade }}</h5></a>
                </div>
            </div>
            <h4 for="subjek" class="mt-3 font-weight-normal">Pilih Subjek</h4>
            <div class="row container">
                <div class="col-auto my-2 mr-2" v-for="(value, index) in categories" :key="index" @click="setSubject(value._id)">
                    <a @click="setCategory(value._id)" href="#" class="badge" :class="value._id == id_subject ? 'badge-active' : 'badge-nonactive'"><h5 class="mx-2 my-1 font-weight-normal">{{ value.category_name }}</h5></a>
                </div>
            </div>
            <h4 for="materi" class="mt-3 font-weight-normal">Pilih Materi Pembelajaran</h4>
            <div class="row container">
                <template v-if="learnings.length > 0">
                    <div class="col-auto my-2 mr-2" v-for="(value, index) in learnings" :key="index" @click="id_learning = value._id">
                        <a @click="setLearningCategory(value._id)" href="#" class="badge" :class="value._id == id_learning ? 'badge-active' : 'badge-nonactive'"><h5 class="mx-2 my-1 font-weight-normal">{{ value.learning_category }}</h5></a>
                    </div>
                </template>
                <template v-else>
                    <br>
                    <h5>Tidak ada</h5>
                </template>
            </div>
            <h4 for="materi" class="mt-3 font-weight-normal">Impor pertanyaan dari spreadsheet/excel</h4>
            <p class="text-none">Harap unggah spreadsheet/excel yang mengikuti <br /> contoh template.</p>
            <div class="excel d-flex mt-2 align-items-center">
                <img src="@/assets/images/logo/excel-logo.png" alt="" class="logo-excel">
                <p class="m-0 ml-2">Unduh Contoh Format</p>
            </div>
            <div class="impor mt-2">
                <button class="btn btn-warning text-white btn-impor"> <font-awesome-icon icon="fa-file-download" class="mr-1" />Import Question</button>
            </div>
            <template #modal-footer="{ ok }">
                <button type="button" class="btn btn-md btn-secondary mr-2" @click="ok()">
                Cancel
                </button>
                <b-button size="md" variant="success" @click="simpanQuiz()">
                Save
                </b-button>
            </template>
        </b-modal>

        <!-- MODAL USER NEED TO SIGN IN -->
        <b-modal id="signUp" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md">
            <div class="text-center my-4 mx-4">
                <h3 class="font-weight-normal">Selamat Datang di</h3>
                <img src="@/assets/images/logo/soca-black.svg" alt="" class="my-3">
                <div class="card mx-3 my-4">
                    <div class="card-body my-3">
                        <h4 class="font-weight-normal mb-3">Buat Akun Dengan</h4>
                        <button type="button" class="btn btn-light btn-ggl mt-2 mb-2" @click="signUpWithGoogle">
                            <div class="d-flex align-items-center mx-3">
                                <img src="@/assets/images/logo/google.svg" alt="" />
                                <p class="mb-0 ml-2">Daftar dengan Akun Google</p>
                            </div>
                        </button>
                        <p class="mb-0 mt-3 mb-4">Dengan melakukan daftar berarti Anda <br> menyetujui <a href="">syarat dan ketentuan</a> yang berlaku</p>
                        <p>
                            Sudah Mempunyai Akun ? <a href="#" @click="signInModal()">Masuk</a>
                        </p>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal id="signIn" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md">
            <div class="text-center my-4 mx-4 text-white">
                <h3 class="font-weight-normal">Selamat Datang di</h3>
                <img src="@/assets/images/logo/soca-black.svg" alt="" class="my-3 invert">
                <div class="card mx-3 my-4">
                    <div class="card-body my-3">
                        <button type="button" class="btn btn-light btn-ggl mt-2 mb-2" @click="signInWithGoogle">
                            <div class="d-flex align-items-center mx-3">
                                <img src="@/assets/images/logo/google.svg" alt="" />
                                <p class="mb-0 ml-2">Lanjutkan dengan Google</p>
                            </div>
                        </button>
                        <p class="mb-0 mt-3 mb-4">Dengan masuk ke dalam aplikasi berarti Anda <br> telah menyetujui <a href="" @click="toTerm">syarat dan ketentuan</a> yang berlaku</p>
                    </div>
                </div>
            </div>
        </b-modal>

        <Subscriptions />
        <CreateContent />
        <JoinQuiz />
        <EmailTidakTerdaftar />
        <EmailBerhasilDaftar />
        <AkunSudahTerdaftar />
        <UpgradePlans />
        <ImportQuizExcel />
        <ChooseAvatar />
        <ModalSaveDraftTTQ />
        <Logout />
        <UploadFile/>
        <!-- <Soca v-if="loadingContent"/> -->
	</div>
</template>

<script>
import Navbar from '@/components/navigation/Navbar'
// import NavbarTtv from '@/components/navigation/NavbarTtv'
    import SideBar from '@/components/navigation/Sidebar'
import sidebarLinks from '@/components/navigation/sidebarlinks.js'
import JoinQuiz from '@/components/modal/JoinQuiz'

// SERVICES
import {getLearningCategories} from '@/services/learning-categories/learning-categories.service'
import {addQuizCreator} from '@/services/quiz-creator/quiz-creator.service'
import { getTokenByEmail, registerUser, updateDataUser, getProjects } from '@/services/projects/projects.service'
import { base_url_home } from '@/config/base_url'
import { mapActions } from "vuex";
import { getQuizCreatorAll, getQuizCreatorDraft } from '@/services/quiz-creator/quiz-creator.service'
import { getAudio } from '@/services/audio/audio.service'
import { getAudioDrafts } from '@/services/drafts/drafts.service'

// import $ from "jquery"

import axios from "axios"
// import Swal from 'sweetalert2';

// MODAL
import EmailTidakTerdaftar from '@/components/modal/EmailTidakTerdaftar'
import EmailBerhasilDaftar from '@/components/modal/EmailBerhasilDaftar'
import AkunSudahTerdaftar from '@/components/modal/AkunSudahTerdaftar'
import Subscriptions from '@/components/modal/Subcriptions'
import UpgradePlans from '@/components/modal/UpgradePlans'
import CreateContent from '@/components/modal/CreateContent'
import ImportQuizExcel from '@/components/modal/ImportQuizExcel'
import UploadFile from '@/components/modal/UploadFile'
import ChooseAvatar from '@/components/modal/ChooseAvatar'
import ModalSaveDraftTTQ from '@/components/modal/ModalSaveDraftTTQ.vue'
import Logout from '@/components/modal/Logout.vue'
// import Soca from '@/components/loading/Soca'

export default {
    name: 'MainContainer',
    components: {
        Navbar,
        SideBar,
        Subscriptions,
        UpgradePlans,
        CreateContent,
        JoinQuiz,
        EmailTidakTerdaftar,
        EmailBerhasilDaftar,
        AkunSudahTerdaftar,
        ImportQuizExcel,
        ChooseAvatar,
        // NavbarTtv,
        ModalSaveDraftTTQ,
        Logout,
        UploadFile
    },
    data(){
        return {
            topbarColor: "#fff",
            logotitle: "SOCA Test Web Creator",
            sidebarLinks: sidebarLinks,
            url: null,
            id_grade: 1,
            id_subject: 1,
            id_learning: 1,
            grades: [],
            formData: {
                grade_id: null,
                category_id: null,
                learning_category_id: null,
                quiz_name: null,
            },
            categories: [],
            learnings: [],
            users:{
                _id: null,
                email: null,
            },

            loadingProject: false,
            loadingContent: false,
        }
    },
    mounted(){
        this.getURL()
        this.cekSizeWindow()

        if(localStorage.getItem('users')){
            this.users = JSON.parse(localStorage.getItem('users'))
        } else {
            if(localStorage.getItem('id_client')){
                this.getProject()
            }
        }

        this.$i18n.locale = this.getSavedLocale();

        this.axiosInstance = axios.create({
            headers : ""
        })

        // if(this.$store.state.dataQuizCollections.length == 0 || this.$store.state.dataAudioCollections.length == 0) {
            this.getContentsAll()
        // }
    },
    // computed: {
    //     isSidebar(){
    //         return this.$store.state.isSidebarActive
    //     }
    // },

    // watch: {
    //     isSidebar(val){
    //         if(val == true){
    //             $("#main-container").addClass("stretch-margin")
    //         } else {
    //             $("#main-container").removeClass("stretch-margin")
    //         }
    //     }
    // },

    created() {
        window.addEventListener("resize", this.cekSizeWindow);
    },
    destroyed() {
        window.removeEventListener("resize", this.cekSizeWindow);
    },
    methods: {
        ...mapActions(["setUsers"]),


        mainContainerPadding(){
            if(this.$route.name == 'Use-Template' || this.$route.name == 'Subscriptions' || this.$route.name == 'Create-Content' || this.$route.name == 'Create-Question' || this.$route.name == 'Create-Question-Creator' || this.$route.name == 'Update-Question-Creator' || this.$route.name == 'Leaderboard-Quiz' || this.$route.name == 'Live-Quiz' || this.$route.name == 'Create-Question-Polling' || this.$route.name == 'Detail-Report-Peserta' || this.$route.name == 'PaymentMethod'){
                return true;
            } else {
                return false;
            }
        },

        sidebarOnlyIn(){
            if(this.$route.name == 'Ai-Generator' || this.$route.name == 'Dashboard' || this.$route.name == 'My-Quiz' || this.$route.name == 'Templates' || this.$route.name == 'Quiz' || this.$route.name == 'Report' || this.$route.name == 'All-Notifications' || this.$route.name == 'MyAccount' || this.$route.name == 'Home' || this.$route.name == 'Plugins' || this.$route.name == 'Ai-Characters' || this.$route.name == 'Personal-Voice' || this.$route.name == 'My-Assets' || this.$route.name == 'NewsReader' || this.$route.name == 'AutoDubbing' || this.$route.name == 'ApiLog'){
                return true;
            } else {
                return false;
            }
        },

        cekSizeWindow(){
            if(screen.width < 800) {
                this.$router.push({ name : 'MobileNotFound' })
            }
            // var split_url = window.location.href
            // var res = split_url.split("/")

            // var realLocation = null

            // if(res[8] != undefined) {
            //     realLocation = res[3] + "/" + res[4] + "/" + res[5] + "/" + res[6] + "/" + res[7] + "/" + res[8]
            // } else if (res[7] != undefined) {
            //     realLocation = res[3] + "/" + res[4] + "/" + res[5] + "/" + res[6] + "/" + res[7]
            // } else if (res[6] != undefined) {
            //     realLocation = res[3] + "/" + res[4] + "/" + res[5] + "/" + res[6]
            // } else if (res[5] != undefined) {
            //     realLocation = res[3] + "/" + res[4] + "/" + res[5]
            // } else if (res[4] != undefined) {
            //     realLocation = res[3] + "/" + res[4]
            // } else if (res[3] != undefined) {
            //     realLocation = res[3]
            // }

            // if (screen.width < 800){
                // if(realLocation != null) {
                //     window.location = "https://m-creator.soca.ai/" + realLocation
                // } else {
                //     window.location = "https://m-creator.soca.ai/"
                // }
            // }
        },

        openModal(){
            this.$bvModal.show('modal-buat-quiz')
        },
        signInModal(){
            this.$bvModal.hide('signUp')
            this.$bvModal.show('signIn')
        },
        signUp(){
            this.$bvModal.hide('signIn')
            this.$bvModal.show('signUp')
        },
        simpanQuiz(){
            addQuizCreator(this.formData).then((response) => {
                if(response.status && response.status == true){
                    this.$router.push({ name: 'Setup-Quiz', query:{key: response.data._id}})
                    this.$bvModal.hide('modal-buat-quiz')
                }
                else {
                    this.$vs.loading.close();
                }
            })

        },

        getURL(){
            var split_url = window.location.href
            var res = split_url.split("/")
            this.url = res[6]
            // console.log("url " + this.url)
        },
        getLearningCategories(id){
            getLearningCategories(id)
            .then(response => {
                if(response.status && response.status == true){
                    this.learnings = response.data
                }
            }).catch(error => {
                console.log(error)
            })
        },
        setSubject(value){
            this.learnings = []
            this.id_subject = value
            this.getLearningCategories(value)
        },
        setGrade(value){
            this.formData.grade_id = value
        },
        setCategory(value){
            this.formData.category_id = value
        },
        setLearningCategory(value){
            this.formData.learning_category_id = value
        },
        signInWithGoogle(){
            var dataPost = {
                name: null,
                email: null
            }

            const client = window.google.accounts.oauth2.initTokenClient({
                client_id: process.env.VUE_APP_SECRET_KEY_GOOGLE,
                scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
                callback: (response) => {
                    this.axiosInstance.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                        headers: {
                         Authorization: `Bearer ${response.access_token}`
                        }
                    })
                    .then((res) => {
                        dataPost = {
                            name: res.data.name,
                            email: res.data.email
                        }
                        const email = res.data.email;
                        this.updateUser(email, res)
                        return getTokenByEmail(email);
                    }).then((res) => {
                        if(res.status) {
                                // window.location = "http://localhost:8080/";
                                this.setUsers(JSON.stringify(res.data))
                                localStorage.setItem("users", JSON.stringify(res.data))
                                window.location = base_url_home + 'ai-generator';
                        } else {
                                this.registerUser(dataPost)
                        }
                    })
                        console.log(response)

                },
            });

            client.requestAccessToken();
        },
        signUpWithGoogle() {
            const client = window.google.accounts.oauth2.initTokenClient({
                client_id: process.env.VUE_APP_SECRET_KEY_GOOGLE,
                scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
                callback: (response) => {
                    this.axiosInstance.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                        headers: {
                            Authorization: `Bearer ${response.access_token}`
                        }
                    })
                    .then((res) => {
                        this.registerUser(res)
                    })
                },
            });

            client.requestAccessToken();
        },

        updateUser(email, user_data){
            var dataPost = {
                email: user_data.data.email,
                name : user_data.data.name,
                picture: user_data.data.picture
            }
            updateDataUser(email, dataPost).then((response) => {
                console.log(response)
            })
        },
        registerUser(user_data) {

            var vm = this

            new Promise(function(resolve) {
                setTimeout(() => resolve(registerUser(user_data)))
            })
            .then(function(result) {
                if(!result.status) {
                    vm.$bvModal.show('akun-sudah-terdaftar-modal')
                } else {
                    window.location = base_url_home + 'templates'
                    localStorage.setItem("id_client", result.data.user._id)
                    // vm.$bvModal.show('email-berhasil-terdaftar-modal')
                }
            })
        },
        toTerm(){
            this.$router.push({ name: 'Terms-Conditions'})
        },

        getProject() {
            this.loadingProject = true

            getProjects()
            .then((response) => {
                console.log(response)
                if(response.status && response.status == true){
                    this.users = response.data;
                    localStorage.setItem('users', JSON.stringify(this.users))
                    this.$vs.loading.close();
                    this.loadingProject = false
                }else{
                    localStorage.removeItem('users')
                    this.$vs.loading.close();
                    this.loadingProject = false
                }
            }).catch((err) => {
                this.loadingProject = false
                this.$vs.loading.close();
                console.log("data err", err)
            });
        },

        getContentsAll(){
            this.loadingContent = true

            this.$store.state.dataQuizCollections = []
            this.$store.state.dataAudioCollections = []

            this.$store.state.loadingAllContents = true

            Promise.all([
                getQuizCreatorAll(),
                getQuizCreatorDraft(),
                getAudio(),
                getAudioDrafts(),
            ]).then((response) => {
                let dataQuiz = response[0].data

                dataQuiz.forEach((e) => {
                    this.$store.state.dataQuizCollections.push({
                        "_id" : e._id,
                        "content_name" : e.quiz_name != null ? e.quiz_name : 'Quiz',
                        "thumbnail" : e.images_cover,
                        "is_draft" : false,
                        "type_content" : 'quiz',
                    })
                })

                let dataQuizDraft = response[1].data

                dataQuizDraft.forEach((e) => {
                    this.$store.state.dataQuizCollections.push({
                        "_id" : e._id,
                        "content_name" : e.quiz_name != null ? e.quiz_name : 'Quiz draft',
                        "thumbnail" : e.images_cover,
                        "is_draft" : true,
                        "type_content" : 'quiz',
                    })
                })

                let dataAudio = response[2].data

                dataAudio.forEach((e) => {
                    this.$store.state.dataAudioCollections.push({
                        "_id" : e._id,
                        "content_name" : e.content_name != null ? e.content_name : 'Audio',
                        "thumbnail" : e.image_thumbnail,
                        "is_draft" : false,
                        "type_content" : 'audio',
                    })
                })

                let dataAudioDraft = response[3].data

                dataAudioDraft.forEach((e) => {
                    this.$store.state.dataAudioCollections.push({
                        "_id" : e._id,
                        "content_name" : e.content_name != null ? e.content_name : 'Audio draft',
                        "thumbnail" : e.image_thumbnail,
                        "is_draft" : true,
                        "type_content" : 'audio',
                    })
                })

                this.$vs.loading.close();
                this.loadingContent = false
                this.$store.state.loadingAllContents = false
            }).catch((error) => {
                console.log(error);
                this.loadingContent = false
                this.$store.state.loadingAllContents = false
                this.$vs.loading.close();
            });

        },
        getSavedLocale() {
            return localStorage.getItem('locale') || 'en';
        },
    }
}
</script>

<style scoped>
.badge{
    border-radius: 15px;
    width: auto;
    height: auto;
    text-align: center;
    font-size: 10px;
}
.badge-active {
    background-color: #6D75F6;
    color: #fff;
}
.badge-nonactive {
    background-color: #E5E5E5;
    color: #000;
}
.text-none{
    color: #8C8C8C;
    margin: 0;
    font-size: 14px;
}
.logo-excel{
    width: 18px;
    height: 18px;
}
.btn-impor{
    border-radius: 15px;
}

.btn-ggl{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    background: white;
}

.card{
    border-radius: 20px;
}

.main-wrapper{
    background-color: black;
    min-height: 100vh;
}

.invert {
    filter: invert(1);
}
</style>

<style>
#signIn .modal-content{
    background-color: #222222;
}
</style>