<template>
    <div>
        <b-modal id="alert-savedraft" centered no-close-on-backdrop header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn d-flex justify-content-center align-items-center pr-0" @click="close()"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="sovia-delete">
                <div class="mt-3">
                    <h5 class="font-weight-bold mb-2">Wait..</h5>
                    <div class="d-flex flex-column">
                        <span class="">{{ $t('your-content-almost-finished') }}</span>
                        <span class="">{{ $route.params.mode == 'new' ?  $t('save-it-as-draft')  : 'Do you want to save changes?' }}</span>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-secondary text-white mr-2" @click="$emit('delete')"><p class="mb-0 font-weight-bold">{{ $t('delete') }}</p></button>
                    <button class="btn btn-primary btn-purple-nb" @click="$emit('save-draft')"><p class="mx-2 mb-0 font-weight-bold">{{ $route.params.mode == 'new' ? $t('save-to-draft') : 'Save Changes' }}</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { addQuizCreator } from '@/services/quiz-creator/quiz-creator.service'
import { postQuestionCreator } from '@/services/question-creator/question_creator.service'
export default{
    data(){
        return {

        }
    },
    emits: ['delete', 'save-draft', 'close'],
    methods: {
        deleteQuizAndBackHome(){
            localStorage.removeItem('listQuestionCreate');
            localStorage.removeItem('lengthQuestionAi');
            localStorage.removeItem('subGradeAi');
            localStorage.removeItem('sub_grade');
            localStorage.removeItem('category_id');
            localStorage.removeItem('index_grade');
            localStorage.removeItem('index_grade_ai');
            localStorage.removeItem('grade');
            localStorage.removeItem('gradeAI');
            localStorage.removeItem('category_name');
            localStorage.removeItem('category_id_Backup');

            if(localStorage.feedback){
                localStorage.removeItem("feedback");
            }

            if(localStorage.trans_id){
                localStorage.removeItem("trans_id");
            }

            this.$bvModal.hide('alert-savedraft')
            this.$router.push({ name : 'My-Quiz' })
        },
        saveDraft(){
            // let vm = this
            let formData = {
                quiz_name: localStorage.quiz_name ? localStorage.quiz_name : 'Untitled',
                description: localStorage.quiz_description ? localStorage.quiz_description : null,
                images_cover: localStorage.images_cover ? localStorage.images_cover : undefined,
                category_id: localStorage.category_id ? localStorage.category_id : null,
                grade_id: localStorage.grade ? localStorage.grade : null,
                sub_grade_id: localStorage.sub_grade ? localStorage.sub_grade : null,
                is_draft: true,
            }

            let listQuestion = JSON.parse(localStorage.listQuestionCreate)
            addQuizCreator(formData)
                .then((response) => {
                    if (response.status && response.status == true) {
                        listQuestion.forEach((item) => {

                            item._id = null; //re-create id for new question
                            if (item.voice != null && item.voice != '') {
                                item.question = null
                            }

                            if (item.video != null && item.video != '') {
                                item.question = null
                            }
                            postQuestionCreator(item, response.data._id)
                                .then((response) => {
                                    if (response.status && response.status == true) {
                                        // state.validationCreate = false
                                    } else {
                                        console.log(response)
                                    }
                                })
                        })
                        // var id_quiz = response.data._id

                        const length = listQuestion.reduce((totalDuration, question) => {
                            return totalDuration + (question.duration || 30);
                        }, 0);

                        if (localStorage.getItem("quizTime")) {
                            localStorage.removeItem("quizTime")
                        }

                        if (localStorage.getItem("sisaWaktu")) {
                            localStorage.removeItem("sisaWaktu")
                        }
                        localStorage.setItem("quizTime", length)
                        localStorage.removeItem("listQuestionCreate")
                        localStorage.removeItem("quiz_name")
                        localStorage.removeItem("quiz_description")
                        localStorage.removeItem("images_cover")
                        localStorage.removeItem("category_id")
                        localStorage.removeItem("createTag")
                        localStorage.removeItem("materiAi")
                        localStorage.removeItem("gradeAi")
                        localStorage.removeItem('lengthQuestionAi')
                        localStorage.removeItem("subGradeAi")
                        localStorage.removeItem("descriptionAi")
                        localStorage.removeItem("grade")
                        localStorage.removeItem("sub_grade")
                        localStorage.removeItem("index_grade")
                        localStorage.removeItem("feedback")
                        localStorage.removeItem("trans_id")
                        localStorage.removeItem("gradeAI")
                        localStorage.removeItem("category_id_Backup")
                        localStorage.removeItem("category_name")
                        localStorage.removeItem("backUpCreateQuestion")

                        this.$router.push({ name : 'My-Quiz' })
                    } else {
                        console.log(response)
                    }
                })
        },
        close() {
            history.pushState(null, null, location.href);
            this.$bvModal.hide('alert-savedraft')
            this.$emit('close');
        }
    }
}
</script>
<style>
#alert-savedraft .modal-content {
    background-color: #1F1F1F;
    color: white;
    border-radius: 15.0499px;
}
.btn-primary{
    background-color: #6D75F6;
    border: none;
}
.btn-primary:hover{
    background-color: #6D75F6;
    /* border: none; */
}
.btn-secondary{
    background-color: #2D2D2D;
    border: #2D2D2D;
    color: #ffffff;
}
.btn-secondary:hover{
    background-color: #2D2D2D;
    border: #2D2D2D;
    color: #ffffff;
}
</style>
<style scoped>
button, .btn{
    box-shadow: none;
}

/* .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,10);
}

.modal-backdrop {
    opacity: 0.8;
    backdrop-filter: blur(3px);
} */
</style>