import axios from "../../config/axios"

import { base_url_rewards } from "../../config/base_url";

export async function claimReward(id) {
    return axios.put(base_url_rewards + 'api/claim-reward/' + id)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}
