<template>
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="12.418" width="10.5804" height="10.5804" rx="0.920038" :fill="fill"/>
        <rect width="10.5804" height="10.5804" rx="0.920038" :fill="fill"/>
        <rect x="12.418" y="12.4199" width="10.5804" height="10.5804" rx="0.920038" :fill="fill"/>
        <rect y="12.4199" width="10.5804" height="10.5804" rx="0.920038" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    props: {
        fill:{
            type: String,
            default: '#2D2D2D'
        }
    }
}
</script>