<template>
    <div>
        <b-modal :id="id" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
            <div class="d-flex justify-content-between my-3 mx-4">
                <div class="text-left">
                    <h6 class="font-weight-normal">Please upload the spreadsheet/excel following the sample template</h6>
                    <!-- <h5 class="mb-0 mt-2 font-weight-normal">Please upload the spreadsheet/excel following the sample template</h5> -->
                </div>
                <div class="text-right">
                    <button class="btn btn-close d-flex justify-content-center align-items-center" @click="$bvModal.hide(id)"><b-icon icon="x"></b-icon></button>
                </div>
            </div>
            <div class="mx-4" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent>
                <label @drop="dragFile($event)" class="btn btn-impor-custom w-100 d-flex flex-column py-5 align-items-center text-center">
                    <div v-if="filename == ''" class="h-100 d-flex align-items-center">
                        <div>
                            <img src="@/assets/images/logo/paper_impor.svg" alt="">
                            <h4 class="font-weight-normal mt-2" style="color:#6D75F6;">Choose File or Drag Here</h4>
                            <h5 class="font-weight-normal text-muted mt-2"><i>format file : .xls .xlsx</i></h5>
                            <h5 class="font-weight-normal text-muted mt-1"><i>max. file size 1MB</i></h5>
                        </div>
                    </div>
                    <div v-else class="h-100 d-flex align-items-center justify-content-center w-100">
                        <div class="w-75">
                            <!-- <p class="mb-2 text-white">Uploading...</p> -->
                            <img src="@/assets/images/icons/ic_excel_smaller.svg" alt="" class="img-logo">
                            <h5 class="mt-3 text-white">{{ typeof formData.file === "string" ? formData.file : filename }}</h5>
                            <div v-if="showProgress" class="w-100 mt-3">
                                <div class="progress progress__upload" height="10px">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="{ width: uploadProgress + '%' }"></div>
                                </div>
                            </div>
                            <button v-if="showProgress" class="btn btn-grey mt-4" @click="cancelWhileUpload"><p class="mb-0 font-weight-bold mx-2">Cancel</p></button>
                        </div>
                    </div>
                    <input type="file" style="display: none" ref="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="fileUpload($event)"/>
                </label>
                <div v-if="!showProgress" class="d-flex align-items-center justify-content-between py-3">
                    <a href="/file/QuestionsUpload.xlsx" download>
                        <div class="d-flex align-items-center text-decoration-none text-white">
                            <img src="@/assets/images/icons/ic_excel_smaller.svg" alt="" class="img-logo">
                            <h4 class="font-weight-normal ml-2">Download Template</h4>
                        </div>
                    </a>
                    <div class="d-flex align-items-center">
                        <button class="btn btn-grey text-white" @click="$bvModal.hide(id)">Cancel</button>
                        <button v-if="filename == ''" class="btn btn-purple text-white ml-2" style="cursor : not-allowed;" disabled>Import</button>
                        <button v-else class="btn btn-purple text-white ml-2" @click="importQuestion">Import</button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { uploadQuestionCreator } from '@/services/question-creator/question_creator.service'
import { base_url } from "../../config/base_url";
import { AlertUtils } from "@/mixins/AlertUtils";

import axios from "axios"

export default {
    mixins: [AlertUtils],
    props: {
        id: {
            type: String,
            default: 'import-quiz-excel',
        },
        initialQuestions: {
            type: Array,
            default: null,
        },
        onPage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showAlert: false,
            showProgress: false,
            uploadProgress: 0,
            cancelTokenSource: null,
            responseValidate: "",
            filename : "",
            formData: {
                file: "",
            },
        }
    },

    methods: {
        dragFile(e) {
            const file = [...e.dataTransfer.files];
            const fileSize = file[0].size;

            if (
                file[0].type ==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file[0].type == "application/vnd.ms-excel"
            ) {
                if (fileSize > 1000000) {
                    this.alertFail(this.$t("file-size-max-1mb"))
                } else {
                    this.showAlert = false;
                    this.formData.file = file[0];
                    this.filename = file[0].name
                        ? file[0].name
                        : "Undefined";
                }
            } else {
                this.alertFail('The file must be in XLS or XLSX format')
                this.responseValidate = "The file must be in XLS or XLSX format";
                this.showAlert = true;
                return false;
            }
        },

        importQuestion(){
            let formData = new FormData();
            formData.append('uploadfile', this.formData.file);

            const config = {
                onUploadProgress: progressEvent => {
                    this.showProgress = true;
                    this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                },
                cancelToken: new axios.CancelToken(cancel => {
                    this.cancelTokenSource = cancel; // simpan cancel token ke properti cancelSource
                })
            };

            if(this.$route.name != 'Create-My-Quiz'){
                localStorage.removeItem("listQuestionCreate")
                localStorage.removeItem("quiz_name")
                localStorage.removeItem("quiz_description")
                localStorage.removeItem("images_cover")
                localStorage.removeItem("category_id")
                localStorage.removeItem("createTag")
                localStorage.removeItem("materiAi")
                localStorage.removeItem("gradeAi")
                localStorage.removeItem("subGradeAi")
                localStorage.removeItem("descriptionAi")
                localStorage.removeItem('lengthQuestionAi')
                localStorage.removeItem("grade")
                localStorage.removeItem("sub_grade")
                localStorage.removeItem("index_grade")
            }

            uploadQuestionCreator(formData, config).then((response) => {
                var tempArray = []
                if(this.initialQuestions || localStorage.listQuestionCreate){
                    tempArray = this.initialQuestions || JSON.parse(localStorage.listQuestionCreate)
                    var data = response.data
                    data.forEach(data => {
                        // var num = Math.floor(Math.random()*90000) + 10000;//random number for dummy id
                        // data._id = num
                        data.image_url = data.image_url ? data.image_url : null
                        data.voice = data.voice ? data.voice : null
                        data.video = data.video ? data.video : null

                        if(data.question_type == 'Multiple Choice') {
                            data.layout = 'text-only'
                            data.layoutThumbnail = base_url +'/layout-thumbnail/fill_the_blank_wide.svg'
                        } else if (data.question_type == 'True or False') {
                            data.layout = 'true-false'
                            data.layoutThumbnail = base_url +'/layout-thumbnail/true_false_wide.svg'
                        }

                        tempArray.push(data)
                    });

                    localStorage.listQuestionCreate = JSON.stringify(tempArray)
                    localStorage.question_active = tempArray.length

                    this.listQuestion = JSON.parse(localStorage.getItem("listQuestionCreate"))
                    this.filename = "",
                    this.formData = {
                        file: "",
                    }
                } else {
                    var excelData = []
                    excelData = response.data
                    excelData.forEach((e) => {
                        // var num = Math.floor(Math.random()*90000) + 10000;//random number for dummy id
                        // e._id = num
                        e.image_url = e.image_url ? e.image_url : null
                        e.voice = e.voice ? e.voice : null
                        e.video = e.video ? e.video : null

                        if(e.question_type == 'Multiple Choice') {
                            e.layout = 'text-only'
                            e.layoutThumbnail = base_url +'/layout-thumbnail/fill_the_blank_wide.svg'
                        } else if (e.question_type == 'True or False') {
                            e.layout = 'true-false'
                            e.layoutThumbnail = base_url +'/layout-thumbnail/true_false_wide.svg'
                        }
                    })

                    localStorage.listQuestionCreate = JSON.stringify(excelData)
                    localStorage.question_active = excelData.length

                    this.listQuestion = JSON.parse(localStorage.getItem("listQuestionCreate"))
                    this.$store.state.check_length = this.listQuestion.length
                    this.filename = "",
                    this.formData = {
                        file: "",
                    }
                }

                if (this.onPage) {
                    this.$emit('import');
                    this.$bvModal.hide(this.id);
                } else {
                    this.$router.push({ name : 'Generative-Ai', params : { mode: 'new', id: '1' } })
                }

                this.showProgress = false;
                this.uploadProgress = 0;

            })

            // this.$bvModal.hide('impor-soal-excel')

        },

        cancelWhileUpload(){
            this.cancelTokenSource(); // panggil cancel() pada cancel token
            this.showProgress = false;
            this.progress = 0;
        },

        fileUpload(e){
            const fileSize = e.target.files[0].size;
            var file = e.target.files[0];
            if (
                file.type ==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type == "application/vnd.ms-excel"
            ) {
                if (fileSize > 1000000) {
                    this.alertFail(this.$t("file-size-max-1mb"))
                } else {
                    this.showAlert = false;
                    this.formData.file = e.target.files[0];
                    this.filename = e.target.files[0].name
                        ? e.target.files[0].name
                        : "Undefined";
                }
            } else {
                this.alertFail('The file must be in XLS or XLSX format')
                return false;
            }
        },
    }
}
</script>

<style>
    #impor-soal-excel .modal-content{
        background-color: #1F1F1F;
        color: white;
    }
</style>

<style scoped>
    .btn-close{
        background-color: #EDEDED;
        border-radius: 50%;
        width: 35px;
        height: 35px;
    }

    .btn-impor-custom{
        border : 2px dashed #2D2D2D;
        height: 20rem;
        border-radius: 10px;
    }

    .img-logo{
        width: 1.5rem;
        height: 1.5rem;
    }

    .btn-grey{
        background-color: #2D2D2D !important;
        color: white;
        border: 0px !important;
    }

    .btn-purple {
        background-color: #6D75F6;
        border: 1px solid #6D75F6;
        color: white;
    }

    .progress__upload .progress-bar {
        background-color: #6D75F6;
    }
</style>