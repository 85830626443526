<template>
    <div>
        <b-modal id="validate-quiz" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn btn-close d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('validate-quiz')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center text-white mb-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="Script Cannot Blank" class="img-fluid">
                <div class="mt-3">
                    <h4 class="font-weight-bold mb-2">{{ title }}</h4>
                    <h5 class="font-weight-normal">{{ subTitle }}</h5>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-purple" @click="$bvModal.hide('validate-quiz')"><p class="mx-2 mb-0 text-white font-weight-bold">Try Again</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
	props: {
		title : {
			type: String,
			default : ''
		},

		subTitle : {
			type : String,
			default : ''
		}
	},

	setup() {

	},
}
</script>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#validate-quiz .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#validate-quiz .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

#validate-quiz .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
    box-shadow: none !important;
}

button {
    box-shadow: none !important;
}
</style>