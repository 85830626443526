<template>
    <div>
        <b-modal id="nca-modal" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('nca-modal')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="Email Already Registered">
                <div class="mt-4 text-white">
                    <h4 class="font-weight-bold mb-4 mx-2">{{ message }}</h4>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-purple" @click="$bvModal.hide('nca-modal'), $router.push({ name : 'MyAccount', query : { activeTab : 'community' } })"><p class="mx-2 mb-0 font-weight-bold text-white">Choose Workspace</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
	data() {
		return {

		}
	},

	props : {
		message : {
			type : String,
			default : null
		}
	}
}
</script>

<style scoped>
.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
    box-shadow: none !important;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#nca-modal .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#nca-modal .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

#nca-modal .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

button {
    box-shadow: none !important;
}
</style>