import axios from "../../config/axios"
import ApiRoute from '../api.route';

export async function getTotalDrafts(){
    return axios({
        method : 'GET',
        url : ApiRoute.contents + '/total-drafts'
      }).then((res) => {
        const data = {
            status:res.data.status, 
            data:res.data.data, 
            message:res.data.message
        }
        return data;
      }).catch((error) => {
        const data = {status : false , response : error }    
        return data;
      })
}

export async function getAudioDrafts(){
    return axios({
        method : 'GET',
        url : ApiRoute.contents + '/drafts/audio'
      }).then((res) => {
        const data = {
            status:res.data.status, 
            data:res.data.data, 
            message:res.data.message
        }
        return data;
      }).catch((error) => {
        const data = {status : false , response : error }    
        return data;
      })
}