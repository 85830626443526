<template>
  <header class="gridx">
    <vs-navbar
      v-model="indexActive"
      :color="topbarColor"
      class="topnavbar"
      text-color="rgba(255,255,255,0.7)"
      active-text-color="rgba(255,255,255,1)"
    >

    <!--- Mobile toggle -->
    <div class="d-flex container-fluid w-100" :class="this.$route.name == 'Quiz' || users ? 'justify-content-between' : ''">
      <div class="d-flex align-items-center" :style="{ flexGrow: 1 }">
        <button
          v-if="validationSocaLogo()"
          id="hamburger-button"
          type="button"
          class="hamburger-button"
          :title="$store.state.isSidebarActive ? 'Hide Sidebar' : 'Show Sidebar'"
          @click="toggleSidebar"
        >
          <font-awesome-icon icon="fa-solid fa-bars" />
        </button>
        <div class="d-flex align-items-center w-auto">
          <!--- Template logo -->
          <div v-if="validationSocaLogo()" slot="title" class="themelogo d-flex align-items-end cursor-pointer" @click="backToHome">
            <img
              src="@/assets/images/logo/soca-w-logo.svg"
              class="app-logo"
              alt="Dashboard"
            />
          </div>
          <div v-else-if="routerHistory()" class="d-flex align-items-center pl-4 cursor-pointer" @click="$router.go(-1)">
            <img src="@/assets/images/icons/ic_back_nav.svg" alt="back" class="mr-2">
            <h3> Back</h3>
          </div>
          <div v-else-if="$route.name === 'WatchVideo'" class="d-flex align-items-center pl-4 cursor-pointer" @click="$router.go(-1)">
            <img src="@/assets/images/icons/ic_back_nav.svg" alt="back" class="mr-2">
            <h1 class="payment-title ml-2"> {{ $t('tutorial') }}</h1>
          </div>
          <div v-else class="d-flex align-items-center pl-4 cursor-pointer" @click="batalSimpan">
            <img src="@/assets/images/icons/ic_back_nav.svg" alt="back" class="mr-2">
            <!-- <h3> Back</h3> -->
            <h1 v-if="$route.name === 'Subscriptions' || $route.name === 'PaymentMethod'" class="payment-title ml-2"> Payment</h1>
            <h1 v-if="$route.name === 'WatchVideo'" class="payment-title ml-2" @click="$router.go(-1)"> Tutorials</h1>
          </div>
        </div>

        <div v-if="checkNavbarItem()" class="header d-flex align-items-center justify-content-start cursor-pointer" :class="users.token == null && 'ml-10'">
          <label for="search" class="m-0">
            <input
              type="text"
              class="search-bar"
              v-model="$store.state.filterSearchEngine"
              :placeholder="$t('find-content--placeholder')"
              @input="setSearch"
              :disabled="$store.state.loadingAllContents"
              :style="$store.state.loadingAllContents ? 'cursor: wait;' : ''"
            />

            <b-icon v-if="$store.state.filterSearchEngine != null && $store.state.filterSearchEngine != ''" @click="clearSearch()" class="clear position-absolute cursor-pointer" icon="x"></b-icon>
            <div v-if="$store.state.searchStat == false" class="mt-4">
              <div
                class="result-search px-3 py-4 shadow" style="background-color:#1F1F1F;"
                v-if="$store.state.filterSearchEngine != null && $store.state.filterSearchEngine != ''"
              >
                <div v-if="searchQuiz.length > 0 || searchAudio.length > 0 || searchNewsReader.length > 0 || searchAutoDubbing.length > 0" :class="searchQuiz.length > 5 || searchAudio.length > 5 || searchNewsReader.length > 5 || searchAutoDubbing.length > 5 ? 'result' : ''">
                  <div v-if="searchQuiz.length > 0" class="text-left">
                    <h5 class="mb-0 ml-2 text-white">Quiz</h5>
                    <hr style="background-color: #2D2D2D;" class="mt-1">
                  </div>
                  <div v-for="(quiz, index) in searchQuiz" :key="index">
                    <div
                      @click="detailQuiz(quiz._id)"
                      class="d-flex align-items-center ml-2 mb-2 cursor-pointer"

                    >
                      <img
                        v-if="quiz.thumbnail && quiz.thumbnail != 'undefined' && quiz.thumbnail != 'null'"
                        :src="quiz.thumbnail"
                        alt=""
                        class="img-search-engine"
                      />
                      <img
                        v-else
                        src="@/assets/images/default-cover.png"
                        alt=""
                        class="img-search-engine"
                      />
                      <p class="mb-0 ml-2 text-white" v-html="getSubQuiz(quiz.content_name)"></p>
                    </div>
                  </div>
                  <div v-if="searchAudio.length > 0" class="text-left" :class="searchQuiz.length > 0 ? 'mt-4' : ''">
                    <h5 class="mb-0 ml-2 text-white">Video</h5>
                    <hr style="background-color: #2D2D2D;" class="mt-1">
                  </div>
                  <div v-for="(audio, index) in searchAudio" :key="index + searchQuiz.length">
                    <div
                      @click="detailAudio(audio._id, audio.is_draft)"
                      class="d-flex align-items-center ml-2 mb-2 cursor-pointer"

                    >
                      <img
                        v-if="audio.thumbnail && audio.thumbnail != 'undefined' && audio.thumbnail != 'null'"
                        :src="audio.thumbnail"
                        alt=""
                        class="img-search-engine"
                      />
                      <div v-else class="nw__wrapper d-flex align-items-center justify-content-center">
                        <img src="@/assets/images/icons/ic_generates-videos-project.svg" width="12">
                      </div>
                      <p class="mb-0 ml-2 text-white" v-html="getSubQuiz(audio.content_name)"></p>
                    </div>
                  </div>
                  <div v-if="searchNewsReader.length > 0" class="text-left" :class="searchAudio.length > 0 ? 'mt-4' : ''">
                    <h5 class="mb-0 ml-2 text-white">News Reader</h5>
                    <hr style="background-color: #2D2D2D;" class="mt-1">
                  </div>
                  <div v-for="(news, index) in searchNewsReader" :key="index + news._id">
                    <div
                      @click="detailNewsReader(news._id)"
                      class="d-flex align-items-center ml-2 mb-2 cursor-pointer"

                    >
                      <div class="nw__wrapper d-flex align-items-center justify-content-center">
                        <img src="@/assets/images/icons/ic_news-reader-project.svg" width="12">
                      </div>
                      <p class="mb-0 ml-2 text-white" v-html="getSubQuiz(news.content_name)"></p>
                    </div>
                  </div>
                  <div v-if="searchAutoDubbing.length > 0" class="text-left" :class="searchAutoDubbing.length > 0 ? 'mt-4' : ''">
                    <h5 class="mb-0 ml-2 text-white">Auto Dubbing</h5>
                    <hr style="background-color: #2D2D2D;" class="mt-1">
                  </div>
                  <div v-for="(ad, index) in searchAutoDubbing" :key="index + ad._id">
                    <div
                      class="d-flex align-items-center ml-2 mb-2"
                      :class="{
                        'cursor-pointer' : ad.content_file
                      }"
                      @click="handlePreviewAutoDubb(ad)"
                    >
                      <img
                        v-if="ad.image_thumbnail && ad.image_thumbnail != 'undefined' && ad.image_thumbnail != 'null'"
                        :src="ad.image_thumbnail"
                        alt=""
                        class="img-search-engine"
                      />
                      <div v-else class="nw__wrapper d-flex align-items-center justify-content-center">
                        <img src="@/assets/images/icons/ic_generates-videos-project.svg" width="12">
                      </div>
                      <p class="mb-0 ml-2 text-white" v-html="getSubQuiz(ad.content_name)"></p>
                    </div>
                  </div>
                </div>
                <div v-else class="d-flex align-items-center justify-content-center">
                  <p class="mb-0 my-3" style="color:#8C8C8C;">{{ $t('content-not-found') }}</p>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>

        <div class="d-flex align-items-center mr-3" v-if="checkNavbarItem()">
          <button class="btn btn-outline-purple d-flex" @click="$bvModal.show('upload-file')">{{ $t('import-file') }}</button>
          <!-- <button class="btn btn-purple d-flex align-items-center" @click="$router.push({ name:'Generate-Videos', params: { mode: 'new', id: '1' } })" >
            <img src="@/assets/images/plus-white.svg" alt="Create new" class="mr-1">
            Create Video</button> -->
        </div>

      <div v-if="(checkNavbarItem()) && users" class="d-flex align-items-center mr-4 profile">
        <div class="dropdown d-notif">
          <button type="button" class="btn btn-notif position-relative" data-toggle="dropdown" aria-expanded="false" @click="getDataNotifications">
            <img id="notifications" src="@/assets/images/icons/ic_notification.svg" alt="notif" class="cursor-pointer">
              <span
              class="badge badge-danger dot-notif"
              v-if="notif_length > 0"
              >{{ notif_length }}</span
            >
          </button>
          <div class="dropdown-menu dropdown-menu-right mt-3 container-notifications">
             <div class="container-fluid d-flex align-items-center justify-content-between mt-2">
                <h6 class="text-white font-weight-normal">{{ $t('notifications') }}</h6>
                <img src="@/assets/images/icons/ic_close_notif.svg" alt="close" class="img-fluid cursor-pointer" @click="closeNotification">
            </div>
              <hr class="mt-3" style="background: #2D2D2D">
              <div v-show="data_notif.length > 0 && !isGetNotif" class="pr--notify">
                <div v-for="(data, index) in data_notif" :key="index" class="container-fluid">
                  <div v-if="data?.notification_type == 'reward'" class="row text-dark text-left px-2 my-2">
                    <div class="col-md-1 d-flex align-items-start justify-content-center">
                      <img v-if="!data.read" src="@/assets/images/icons/ic_notif_ellipse.svg" alt="dot" class="img-fluid mt-1">
                    </div>
                    <div class="col-md-11 pr-1">
                      <div class="d-flex align-items-center">
                        <img src="@/assets/images/icons/ic_daily-reward.svg">
                        <p class="notif__title ml-2 mb-0">{{ data.text }}</p>
                      </div>
                      <div class="dr__content w-100 mt-3">
                        <div v-if="!data.claim_status" class="dr__content-wrapper d-flex flex-column align-items-center">
                          <p class="dr__content--text mb-0">You get</p>
                          <div class="dr__slider--wrapper w-100 d-flex" :class=" data.length_reward > 1 ? 'justify-content-between' : 'justify-content-center'">
                            <button v-if="data.length_reward > 1" class="dr__button-left btn" :disabled="activeSlide == 0" @click.stop="activeSlide--">
						                  <font-awesome-icon icon="fa-solid fa-chevron-left" />
                            </button>
                            <div class="dr__slider list-unstyled m-0">
                              <div>
                                <div v-show="data?.audio_duration_reward != 0" class="dr__content--reward d-flex align-items-center justify-content-center">
                                  <img src="@/assets/images/icons/ic_package_audio2.png" alt="audio" class="img-fluid dr__content--icon">
                                  <p class="drcr--title mb-0">+{{ data?.audio_duration_reward }}</p>
                                  <span class="drcr--subtitle mb-0">{{ data?.audio_reward_label }}</span>
                                </div>
                              </div>
                              <div>
                                <div v-show="data?.image_generates_reward != 0" class="dr__content--reward d-flex align-items-center justify-content-center">
                                  <img src="@/assets/images/icons/ic_package_img.png" alt="images" class="img-fluid dr__content--icon">
                                  <p class="drcr--title mb-0">+{{ data?.image_generates_reward }}</p>
                                  <span class="drcr--subtitle mb-0">{{ data?.image_reward_label }}</span>
                                </div>
                              </div>
                              <div>
                                <div v-show="data?.question_generates_reward != 0" class="dr__content--reward d-flex align-items-center justify-content-center">
                                  <img src="@/assets/images/icons/ic_package_question.png" alt="questions" class="img-fluid dr__content--icon">
                                  <p class="drcr--title mb-0">+{{ data?.question_generates_reward }}</p>
                                  <span class="drcr--subtitle mb-0">{{ data?.question_reward_label }}</span>
                                </div>
                              </div>
                              <div>
                                <div v-show="data?.swc_quota_reward != 0" class="dr__content--reward d-flex align-items-center justify-content-center">
                                  <img src="@/assets/images/icons/ic_package_swc.png" alt="SWC" class="img-fluid dr__content--icon">
                                  <p class="drcr--title mb-0">+{{ data?.swc_quota_reward }}</p>
                                  <p class="drcr--subtitle mb-0">{{ data?.swc_reward_label }}</p>
                                </div>
                              </div>
                            </div>
                            <button v-if="data.length_reward > 1" class="dr__button-right btn" :disabled="activeSlide == (data.length_reward - 1)" @click.stop="activeSlide++">
						                  <font-awesome-icon icon="fa-solid fa-chevron-right" />
                            </button>
                          </div>
                        </div>
                        <div v-else class="dr__content-wrapper d-flex flex-column align-items-center">
                          <p class="dr__content--text text-center mb-0">Please come back tomorrow <br> to receive more credits</p>
                        </div>
                      </div>
                      <button class="dr__button--claim btn mt-2" :title="isClaim ? 'Loading...' : data.claim_status ? 'CLAIMED' : 'CLAIM'" :disabled="isClaim || data.claim_status" @click.stop="claimDailyReward(data.customer_reward_id)">{{ isClaim ? 'Loading...' : data.claim_status ? 'CLAIMED' : 'CLAIM' }}</button>
                      <i style="font-size:10px; color:#8C8C8C;" class="mb-1">{{ getDate(data?.createdAt) }}</i>
                    </div>
                  </div>
                  <!-- <div v-else-if="data.notification_type == 'text' || data.notification_type == 'pending_payment' || data.notification_type == 'subscription_expires_soon'" class="row text-dark text-left px-2 my-2"> -->
                  <div v-else class="row text-dark text-left px-2 my-2">
                    <div class="col-md-1 d-flex align-items-start justify-content-center">
                      <img v-if="!data.read" src="@/assets/images/icons/ic_notif_ellipse.svg" alt="dot" class="img-fluid mt-1">
                    </div>
                    <div class="col-md-11">
                      <h5 class="font-weight-bold text-white mr-1 mb-2" v-html="getTitleNotif(data.title)"></h5>
                      <p class="font-weight-normal text-white mr-1 mb-0">{{ data.text }}</p>
                      <button class="dr__button--claim btn mt-2"  v-if="data?.notification_type == 'pending_payment'" @click.stop="$router.push({ name : 'Subscriptions', query : { id: data.transaction_id  } })">Proceed</button>
                      <button class="dr__button--claim btn mt-2" v-else-if="data?.notification_type == 'subscription_expires_soon'" @click.stop="$router.push({name: 'TopUp'})">Buy Now</button>
                      <button class="dr__button--claim btn mt-2" :disabled="load_to_invoice" v-if="data?.notification_type == 'subscription_expired'" @click.stop="createInvoice(data.package_id)">{{ load_to_invoice ? 'Loading...' : 'Buy Now' }}</button>
                      <button class="dr__button--claim btn mt-2" :disabled="load_to_invoice || data?.disable_button" v-if="data?.notification_type == 'low_alloc_multi_go'" @click.stop="createInvoice(data.package_id)">{{ load_to_invoice ? 'Loading...' : 'Upgrade Now' }}</button>
                      <button class="dr__button--claim btn mt-2" :disabled="load_to_invoice" v-if="data?.notification_type == 'low_alloc_multi_pro'" @click.stop="checkStatusPin(data.package_id)">{{ load_to_invoice ? 'Loading...' : 'Buy Now' }}</button>
                      <i style="font-size:10px; color:#8C8C8C;" class="mb-1">{{ getDate(data?.createdAt) }}</i>
                    </div>
                  </div>
                  <hr v-if="data_notif.length != index+1" class="my-1" style="background: #2D2D2D">
                </div>
              </div>
              <div v-show="data_notif.length == 0 && !isGetNotif" class="container-fluid">
                <div class="text-center my-5">
                  <p class="">You have no notification.</p>
                </div>
              </div>
              <div v-if="isGetNotif" class="container-fluid d-flex justify-content-center">
                <div class="spinner-border spinner-border-sm text-light my-5" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <!-- <div v-if="data_notif.length > 0">
                <hr class="mt-2" style="background: #2D2D2D">
                <div class="container-fluid text-left mb-2 ml-1">
                  <p class="mb-0 cursor-pointer" style="color:#6D75F6;" @click="$router.push({ name : 'All-Notifications' })">View all notifications</p>
                </div>
              </div> -->
          </div>
        </div>
        <!-- <img :src="users.picture" v-if="users.picture != null" :alt="users.name" @click="toProfile" class="rounded-circle ml-3 cursor-pointer" style="width:40px; height:40px">
        <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + users.name" v-else :alt="users.name" @click="toProfile" class="rounded-circle ml-3 cursor-pointer" style="width:40px; height:40px"> -->
        <div class="dropdown">
          <img :src="users.picture" v-if="users.picture != null" class="rounded-circle cursor-pointer dropdown-toggle profile-user" data-toggle="dropdown" aria-expanded="false">
          <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + users.name" v-else class="rounded-circle cursor-pointer dropdown-toggle profile-user" data-toggle="dropdown" aria-expanded="false">
          <div class="dropdown-menu dropdown-profile">
            <div class="container position-relative">
              <div class="row mt-3">
                <div class="col-md-12 d-flex justify-content-center">
                  <img :src="users.picture" v-if="users.picture != null" class="rounded-circle cursor-pointer" style="width:40px; height:40px">
                  <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + users.name" v-else class="rounded-circle cursor-pointer" style="width:40px; height:40px">
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-12 d-flex flex-column justify-content-center">
                  <span class="text-center mt-2">{{ users.name }}</span>
                  <span class="text-center email-users">{{ users.email }}</span>
                </div>
              </div>
              <div @click="toProfile" class="cursor-pointer mt-3 section-profile">
                <div class="row d-flex align-items-center justify-content-center mx-lang">
                  <div class="col-md-2">
                    <img src="@/assets/images/icons/ic_profile_dropdown.svg" alt="ic-profile">

                  </div>
                  <div class="col-md-8">
                      <span>{{ $t('my-account') }}</span>
                  </div>
                  <div class="col-md-2 pl-1">
                    <img src="@/assets/images/icons/ic-arrow-profile.svg" alt="ic-profile" class="pl-1">
                  </div>
                </div>
                <div class="row mx-3 my-2">
                  <div class="col-md-12 pb-1">
                    <span style="margin-left: 1.2rem; color: #8C8C8C;">{{ $t('edit-profile') }}</span>
                  </div>
                </div>
              </div>
                <hr class="mt-1 mb-2">
                <div @click="!load_status_referral ? $bvModal.show('modal--generate_referral') : ''" class="cursor-pointer section-referral">
                <div class="row d-flex align-items-center justify-content-center mx-lang">
                  <div class="col-md-2">
                    <img src="@/assets/images/icons/ic_referral_dropdown.svg" alt="ic-referral">

                  </div>
                  <div class="col-md-8">
                      <span>Referral</span>
                  </div>
                  <div class="col-md-2 pl-1">
                    <img src="@/assets/images/icons/ic-arrow-profile.svg" alt="ic-profile" class="pl-1">
                  </div>
                </div>
                <div class="row mx-3">
                  <div class="col-md-12 pb-1">
                    <span style="margin-left: 1.2rem; color: #8C8C8C;">{{ $t('share-earn') }}</span>
                  </div>
                </div>
              </div>
                <hr class="mt-1 mb-3">
              <div class="row d-flex align-items-center justify-content-center mx-lang pb-2">
                <div class="col-md-2">
                  <img src="@/assets/images/icons/ic_lang.svg" alt="ic-lang" class="invert">

                </div>
                <div class="col-md-10">
                    <div class="dropdown">
                        <button class="btn d-flex align-items-center justify-content-between box-change-lang w-100  " @click.stop="" data-toggle="dropdown" @click="toggleDropdownLang">
                          <span class="selected--lang">{{ lang }}</span>
                          <img src="@/assets/images/icons/arrow_down_drop_down.svg" alt="ic-profile" class="arrow_lang" :class="{'arrow_lang--active': isDropdownOpen}">
                        </button>
                        <div class="dropdown-menu options-lang pb-1" :class="{ 'show': isDropdownOpen }">
                          <div class="container">
                            <div class="row px-4 py-1 item-lang" :key="index" v-for="item,index in optionsLang" @click="changeLang(optionsLang[index].value)">
                              <div class="col-md-3 text-left">
                                <span>{{ item.lang }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
              <hr class="mt-1 mb-2">
              <div class="row d-flex align-items-center justify-content-center mt-1 mx-lang py-2 section-logout ml-0" @click="$bvModal.show('logout-modal')">
                <div class="col-md-2">
                  <img src="@/assets/images/icons/ic_logout.svg" alt="ic-lang" class="invert">

                </div>
                <div class="col-md-10">
                    <span class="ml-1">{{ $t('logout') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      <!-- Button for before create quiz -->
      <div v-if="this.$route.name == 'Create-Question'" class="pr-5 d-flex">
        <!-- <button class="btn btn-light mr-2" @click="batalSimpanTemplate"> Cancel</button> -->
        <button v-if="$store.state.showAlertSoalTemp == false && $store.state.showAlertAnswer1Temp == false && $store.state.showAlertAnswer2Temp == false && $store.state.showAlertAnswer3Temp == false && $store.state.showAlertAnswer4Temp == false" class="btn btn-success d-flex" @click="simpanTemplate">Save</button>
        <button v-else class="btn btn-success disabled d-flex" style="cursor: not-allowed">Save</button>
      </div>
      <!-- Button for before create quiz -->

      <!-- Button for publish template -->
      <div v-if="this.$route.name == 'Edit-Template'" class="pr-5 d-flex justify-content-end w-100">
        <button class="btn btn-dark mr-2" @click="batalSimpan">Cancel</button>
        <button class="btn btn-purple" @click="publishFromTemplate">Save & Publish</button>
        <!-- <button v-else class="btn btn-success disabled">Publish</button> -->
      </div>
      <!-- Button for publish template -->

      <!-- Button for simpan question creator -->
      <div v-if="this.$route.name == 'Create-Question-Creator'" class="pr-5 d-flex">
        <!-- <button class="btn btn-light mr-2" @click="batalSimpanCreator"> Cancel</button> -->
        <button v-if="$store.state.showAlertSoalCreate == false && $store.state.showAlertAnswer1Create == false && $store.state.showAlertAnswer2Create == false && $store.state.showAlertAnswer3Create == false && $store.state.showAlertAnswer4Create == false" class="btn btn-success d-flex" @click="simpanCreateQuestionCreator">Save</button>
        <button v-else class="btn btn-success disabled d-flex" style="cursor: not-allowed">Save</button>
      </div>
      <!-- Button for simpan question creator -->

      <!-- Button for update question creator -->
      <div v-if="this.$route.name == 'Update-Question-Creator'" class="pr-5 d-flex">
        <!-- <button class="btn btn-light mr-2" @click="batalSimpanUpdate"> Cancel</button> -->
        <button v-if="$store.state.showAlertSoalUpdate == false && $store.state.showAlertAnswer1Update == false && $store.state.showAlertAnswer2Update == false && $store.state.showAlertAnswer3Update == false && $store.state.showAlertAnswer4Update == false" class="btn btn-success d-flex" @click="updateQuestionCreator">Save</button>
        <button v-else class="btn btn-success disabled d-flex" style="cursor: not-allowed">Save</button>
      </div>
      <!-- Button for update question creator -->

      <!-- Button for publish -->
      <div v-if="this.$route.name == 'Edit-My-Quiz'" class="pr-5 d-flex justify-content-end w-100">
        <button class="btn btn-dark mr-2" @click="batalSimpan" :disabled="loadingUpdate">Cancel</button>
        <button class="btn btn-purple" @click="publish" :disabled="loadingUpdate">Save & Publish</button>
        <!-- <button v-else class="btn btn-success disabled" style="cursor : not-allowed">Publish</button> -->
      </div>
      <!-- Button for publish -->

      <!-- Button for publish -->
      <div v-if="['Create-My-Quiz', 'Generative-Ai', 'Generative-Ai-True-False', 'Generative-Ai-Code-Test'].includes($route.name)" class="pr-5 d-flex align-items-center justify-content-end w-100">
        <template v-if="$route.name == 'Generative-Ai' || $route.name == 'Generative-Ai-True-False' || $route.name == 'Generative-Ai-Code-Test'">
          <div class="dropdown information">
            <img src="@/assets/images/icons/ic_information.svg" alt="information" width="18" class="mr-2 pb-1 cursor-pointer" v-if="!status_subscriptions.subscribe">
            <img src="@/assets/images/icons/ic_information.svg" alt="information" width="18" class="mr-2 pb-1" v-else>
            <div class="dropdown-menu tool-tip">
              <div class="container">
                <div class="row ml-1">
                  <span>{{ status_subscriptions.question_generates_quota}} questions remaining to generate. </span>
                  <span>Top Up to get more questions.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mr-3 d-flex align-items-center"><span class="text-white text-nowrap">{{ status_subscriptions.question_generates_quota }}</span></div>
        </template>
        <button class="btn btn-dark mr-2" @click="confirmBackQuiz" :disabled="loadingCreate">Cancel</button>
        <button class="btn btn-purple" @click="publishCreateQuestion" :disabled="($route.name === 'Generative-Ai' || $route.name === 'Generative-Ai-True-False' || $route.name === 'Generative-Ai-Code-Test') && !$store.state.isTtqGenerated">Save & Publish</button>
        <!-- <button v-else class="btn btn-success disabled" style="cursor : not-allowed">Publish</button> -->
      </div>
      <!-- Button for publish -->

      <!-- Button for simpan question polling creator -->
      <div v-if="this.$route.name == 'Create-Question-Polling'" class="pr-5 d-flex">
        <button class="btn btn-light mr-2" @click="batalSimpanCreatePolling"> Cancel</button>
        <button v-if="$store.state.showAlertSoalCreatePolling == false && $store.state.showAlertAnswer1CreatePolling == false && $store.state.showAlertAnswer2CreatePolling == false && $store.state.showAlertAnswer3CreatePolling == false && $store.state.showAlertAnswer4CreatePolling == false" class="btn btn-success" @click="simpanCreateQuestionPolling">Save</button>
        <button v-else class="btn btn-success disabled" style="cursor: not-allowed">Save</button>
      </div>
      <!-- Button for simpan question creator -->

      <!-- Button for publish -->
      <div v-if="this.$route.name == 'Setup-Polling'" class="pr-5 d-flex">
        <button class="btn btn-light mr-2" @click="batalSimpan"> Cancel</button>
        <button class="btn btn-success" @click="publishCreatePolling">Publish</button>
      </div>

      <!-- Button lanjutkan generative ai -->
      <!-- <div v-if="$route.name == 'Generative-Ai' || $route.name == 'Generative-Ai-True-False'" class="pr-5 d-flex align-items-center">
        <div class="dropdown information">
          <img src="@/assets/images/icons/ic_information.svg" alt="information" width="18" class="mr-2 pb-1 cursor-pointer" @click="upgradePlans()" v-if="!status_subscriptions.subscribe">
          <img src="@/assets/images/icons/ic_information.svg" alt="information" width="18" class="mr-2 pb-1" v-else>
          <div class="dropdown-menu tool-tip">
            <div class="container">
              <div class="row ml-1">
                <span>{{ status_subscriptions.question_generates_remaining}} questions remaining to generate. </span>
                <span>Top Up to get more questions.</span>
              </div>
            </div>
          </div>
        </div>
        <div class="mr-3 d-flex align-items-center"><span class="text-white text-nowrap">{{ status_subscriptions.question_generates_remaining }} /</span> <span class="text-plan pl-1"> {{ status_subscriptions.question_generates_quota }}</span></div>
        <button v-if="generatedQuiz" class="btn btn-purple" @click="nextGenerativeAi"><span class="mx-4">Next</span></button>
        <button v-else class="btn btn-disabled" disabled><span class="mx-4">Next</span></button>
      </div> -->

      <!-- Button for Generate Text To Audio -->
      <div v-if="this.$route.name == 'Generate-Text-To-Audio' && this.$store.state.generatedAudioContent" class="pr-5 d-flex justify-content-end w-100">
        <button class="btn btn-dark mr-2" @click="batalSimpan">Cancel</button>
        <button class="btn btn-purple" @click="publishTextToAudio">Save & Publish</button>
        <!-- <button v-else class="btn btn-success disabled" style="cursor : not-allowed">Publish</button> -->
      </div>

      <!-- Button for Generate Text To Audio -->
      <div v-if="this.$route.name == 'Generate-Text-To-Audio-New-Ui'" class="pr-5 d-flex justify-content-end w-100">
        <button class="btn btn-dark mr-2" @click="batalSimpan">Cancel</button>
        <button class="btn btn-purple" @click="publishTextToAudioNewUI">Save & Publish</button>
      </div>
      <!-- Button for Generate Text To Audio -->
      <audio
        ref="notifPlayer"
      />
    </vs-navbar>
    <ValidateQuiz :title="title_alert" :subTitle="sub_alert" />
    <SetupPin :package-id="id_package" @setup-pin="requestPin"/>
    <VerificationCode v-on:otp-success="openCreatePin"/>
    <CreatePin name="topup-create-pin-modal" type="create"/>
    <ADPreviewModal id="show-preview-modal" :name="previewAutoDubName" :job-id="jobIdAutodub" :src="previewAutoDub" :is-feedback="isFeedback" @click:feedback-autodub="$bvModal.hide('show-preview-modal'), $bvModal.show('feedback-autodub-modal')" :content-id="idAutodubActive" feedback/>
    <ADFeedbackModal id="feedback-autodub-modal" @click:submit-feedback="handleFeedbackAutodub" :is-submit="isSubmitFeedback"/>
    <GenerateLinkReferral :token_client="token_client" :link_referral="link_referral" :lang="val_lang" :load_generate_link="load_generate_link" @click:generate-link="generateLinkUnique"/>
  </header>
</template>

<script>
import Swal from 'sweetalert2';
import ValidateQuiz from '@/components/modal/ValidateQuiz.vue'
import moment from 'moment';
import $ from 'jquery';
import 'tiny-slider/dist/tiny-slider.css';

import { mapGetters, mapActions } from "vuex";
import { postContent } from '@/services/quiz-creator/quiz-creator.service'
import { claimReward } from '@/services/rewards/rewards.service'
import { getNotifications, getTotalNotifications } from '@/services/notifications/notifications.service'
import { tns } from 'tiny-slider/src/tiny-slider';
import { EventBus } from '@/helper/event-bus.js';
import { base_url_qgen } from "@/config/base_url"
import { getDataNewsReader } from "@/services/news-reader/news-reader.service"
import { topupSwc } from '@/services/subscriptions/subscriptions.service'
import { checkStatusPin, requestSetPin } from '@/services/pin/pin.service'
import { createSwcPayment } from "@/services/swc_payment/swc_payment.service";
import { AlertUtils } from "@/mixins/AlertUtils";
import { getAutodubList, postFeedbackAutodub } from '@/services/auto-dub/autodub.service'
import { checkLinkReferral, generateLink } from '@/services/referral/referral.service'

import VerificationCode from '@/components/pin/VerificationCode'
import SetupPin from '@/components/payment/modals/SetupPin'
import CreatePin from '@/components/pin/CreatePin'
import ADPreviewModal from "@/components/auto-dubbing/modal/PreviewAutoDub.vue"
import ADFeedbackModal from "@/components/auto-dubbing/modal/Feedback.vue"
import GenerateLinkReferral from "@/components/modal/referral/GenerateLink.vue"

// import { base_url_user_project } from '@/config/base_url'
export default {
  name: "Navbar",
  props: {
    topbarColor: {
      type: String,
      default: "#2962ff",
    },
    title: {
      type: String,
    },
    logo: {
      type: String,
    },
    dataLiveNow: [],
    detailJadwalMataUji: {},
    status_subscriptions: {},
  },
  mixins: [AlertUtils],

  components : {
    ValidateQuiz,
    VerificationCode,
    SetupPin,
    CreatePin,
    ADPreviewModal,
    ADFeedbackModal,
    GenerateLinkReferral
  },

  data: () => ({
    indexActive: 0,
    lang: "English",
    val_lang: 'en',
    showToggle: true,
    backUrl: null,
    data: {},
    isDropdownOpen: false,
    optionsLang:[
        { lang:'English', value:'en' },
        { lang:'Indonesian', value:'id'},
    ],
    category_id: null,
    quiz_name: null,
    listQuestion : [],
    category_id_temp: null,
    quiz_name_temp: null,
    title_alert: null,
    sub_alert : null,
    users:{},
    filter: null,
    show_notifications: false,
    show_create_content: false,

    notif_length: 0,
    activeSlide: 0,
    sliderDr: null,
    isClaim: false,
    isGetNotif: false,
    data_notif: [],
    newsReader: [],
    autoDubbing: [],
    load_to_invoice: false,
    id_package: null,

    previewAutoDub: null,
    idAutodubActive: null,
    jobIdAutodub: null,
    previewAutoDubName: null,
    isFeedback: false,
    isSubmitFeedback: false,
    load_status_referral: false,
    link_referral: null,
    load_generate_link: false,
    token_client: null
  }),

  computed: {
    ...mapGetters({
      usersGet : 'users',
      loadingCreate : 'loadingCreate',
      loadingUpdate : 'loadingUpdate',
      generatedQuiz : 'generatedQuiz'
    }),

    searchQuiz() {
      if (this.$store.state.filterSearchEngine) {
        return this.$store.state.dataQuizCollections.filter((item) => {
          return this.$store.state.filterSearchEngine
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.content_name.toLowerCase().includes(v)
            );
        });
      } else {
        return this.$store.state.dataQuizCollections;
      }
    },

    searchAudio() {
      if (this.$store.state.filterSearchEngine) {
        return this.$store.state.dataAudioCollections.filter((item) => {
          return this.$store.state.filterSearchEngine
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.content_name.toLowerCase().includes(v)
            );
        });
      } else {
        return this.$store.state.dataAudioCollections;
      }
    },

    searchNewsReader() {
      if (this.$store.state.filterSearchEngine) {
        return this.newsReader.filter((item) => {
          return this.$store.state.filterSearchEngine
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.content_name.toLowerCase().includes(v)
            );
        });
      } else {
        return this.newsReader;
      }
    },

    searchAutoDubbing() {
      if (this.$store.state.filterSearchEngine) {
        return this.autoDubbing.filter((item) => {
          return this.$store.state.filterSearchEngine
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.content_name.toLowerCase().includes(v)
            );
        });
      } else {
        return this.autoDubbing;
      }
    },

    getUsers(){
      return this.users
    },

    getGenerativeAiQuestion(){
      return localStorage.getItem('listQuestionCreate') ? true : false;
    }
  },


  mounted() {
  //   $(document).ready(function() {
  //   $('.dropdown-menu').click(function(e) {
  //     e.stopPropagation();
  //   });
  // });
    // this.backUrl = base_url_user_project;
    if(localStorage.quiz_name){
        this.quiz_name = localStorage.quiz_name
    }
    if(localStorage.quiz_description){
        this.quiz_description = localStorage.quiz_description
    }
    if(localStorage.images_cover){
        this.images_cover = localStorage.images_cover
    }
    if(localStorage.category_id){
        this.category_id = localStorage.category_id
    }
    if(localStorage.listQuestionCreate){
        this.listQuestion = JSON.parse(localStorage.listQuestionCreate)
    }

    // TEMPLATE
    if(localStorage.quiz_name_temp){
        this.quiz_name_temp = localStorage.quiz_name_temp
    }
    if(localStorage.category_id_temp){
        this.category_id_temp = localStorage.category_id_temp
    }

    if(localStorage.getItem('token_client')){
      this.token_client = localStorage.getItem('token_client');
    }
    if(localStorage.getItem('users')){
      this.users = JSON.parse(localStorage.getItem('users'))
      if(this.users.token){
        this.token_client = this.users.token;
      }
    }

    if(this.$route.name == 'Home' && this.$route.query.action){
      this.$bvModal.show('setup-pin-modal')
    }

    this.getLengthNotifications()
    this.getNewsReader()
    this.getAutoDubb()
    this.handleChangeLang(localStorage.getItem('locale') || 'en')
    this.checkReferral()
    this.handleChangeLang(localStorage.getItem('locale') || 'en')

    $('.container-notifications').on('click', function(e) {
      e.stopPropagation();
    });
  },
  watch: {
    quiz_name(newQuizName) {
      localStorage.quiz_name = newQuizName;
    },
    category_id(newCategoryID) {
      localStorage.category_id = newCategoryID;
    },
    listQuestion(newListQuestion) {
      localStorage.listQuestionCreate = JSON.stringify(newListQuestion);
    },
    getUsers(val){
        if(val != null) {
            this.users = val
        }
    },
    activeSlide(newVal) {
      this.sliderDr.goTo(newVal)
    }

  },
  methods: {
    ...mapActions(["setAuthor", "logout"]),
    toggleDropdownLang() {
        this.isDropdownOpen = !this.isDropdownOpen;
    },

    generateLinkUnique(){
      this.load_generate_link = true;
      generateLink().then((res)=>{
        if(res.status){
          this.link_referral = res.data.link_referral;
          this.alertSuccess(res.message)
          this.load_generate_link = false;
        }else{
          this.alertFailIcon(res.message)
          this.link_referral = null;
          this.load_generate_link = false;
        }
      }).catch((err)=>{
        this.load_generate_link = false;
        console.log(err)
      });
    },

    checkReferral(){
      this.load_status_referral = true;
      checkLinkReferral().then((res)=>{
        if(res.data){
          this.link_referral = res.data.link_referral
        }
        this.load_status_referral = false;
      }).catch((err)=>{
        console.log(err)
        this.load_status_referral = false;
      })
    },

    openCreatePin() {
			this.$bvModal.hide('verif-code-modal')
			this.$bvModal.show('topup-create-pin-modal')
		},

    requestPin(){
			requestSetPin()
			.then((response) => {
				if(response.status) {
					this.$bvModal.show('verif-code-modal')
					this.$bvModal.hide('setup-pin-modal')
				} else {
					if(response.response?.response?.status == 429) {
						this.alertFail("Too many requests, please try again later.")
					}
				}
			})
		},

    checkStatusPin(id){
			this.id_package = id
      console.log("MASUK SEBELUM HIT");
			checkStatusPin()
			.then((response) => {
				if(response.status) {
					let formData = {
						package_id : id
					}
          console.log("MASUK OKE");
					createSwcPayment(formData)
					.then((res) => {
						if(res.status) {
							this.$router.push({ name : 'Swc-Payment', query : { key : res.data.top_up_swc_transaction_id } })
						} else {
							this.alertFail(res.message)
						}
					})
				} else {
					this.$bvModal.show('setup-pin-modal')
				}
			})
		},
    createInvoice(id){
      this.load_to_invoice = true;

			let formData = {
				package_id : id,
				community_id : null
			}

			topupSwc(formData)
			.then((response) => {
				if(response.status == true){
					this.load_to_invoice = false;
					this.$router.push({ name : 'Subscriptions', query : { id : response.data.subscription_id } })
				}
			})
			.catch((err) => {
				this.load_to_invoice = false;
				console.log(err)
			})
		},

    getTitleNotif(title){
      if(title == 'Purchase Successful'){
        return `${title} 😊`
      }else if(title == 'Pending Payment'){
        return `${title} ☹️`
      }else if(title == 'Congratulations!'){
        return `${title} 🎉`
      }else {
        return `${title}`
      }
    },

    upgradePlans(){
      this.$bvModal.show('upgrade-plans-modal')
    },

    signOut(){
        this.logout().then(() => {
        this.$router.push({ name : 'SignIn' })
      })
    },

    changeLang(item){
      this.$i18n.locale = item;
      this.val_lang = item;
      localStorage.setItem('locale', item);
      let lang = localStorage.getItem('locale');
      this.handleChangeLang(lang)
    },
    handleChangeLang(lang) {

      if(lang){
          switch(lang){
              case 'en':
                  this.lang = "English";
                  break;
              case 'id':
                  this.lang = "Indonesian";
                  break;
          }
      }
    },
    confirmBackQuiz(){
      let listQuestionCreate = localStorage.getItem('listQuestionCreate');

      if(listQuestionCreate != null && listQuestionCreate != undefined){
        this.$bvModal.show('alert-savedraft')
      }else{
        localStorage.removeItem('listQuestionCreate');
        localStorage.removeItem('lengthQuestionAi');
        localStorage.removeItem('subGradeAi');
        localStorage.removeItem('sub_grade');
        localStorage.removeItem('category_id');
        localStorage.removeItem('index_grade');
        localStorage.removeItem('index_grade_ai');
        localStorage.removeItem('grade');
        localStorage.removeItem('gradeAI');
        localStorage.removeItem('levelAi');
        localStorage.removeItem('category_name');
        localStorage.removeItem('category_id_Backup');

        this.$router.push({ name : 'My-Quiz' })
      }
    },
    navbarItem(val){
      if(val == 'content'){
        this.show_notifications = false;
        if(this.show_create_content == false){
          this.show_create_content = true;
        } else {
          this.show_create_content = false
        }
      } else if(val == 'notif'){
        this.show_create_content = false;
        this.show_notifications = true;
      } else {
        this.show_create_content = false;
        this.show_notifications = false;
      }
    },

    checkNavbarItem(){
      if(
        this.$route.name == 'Templates' ||
        this.$route.name == 'Quiz' ||
        this.$route.name == 'Dashboard' ||
        this.$route.name == 'My-Quiz' ||
        this.$route.name == 'Ai-Generator' ||
        this.$route.name == 'All-Notifications' ||
        this.$route.name == 'MyAccount' ||
        this.$route.name == 'Home' ||
        this.$route.name == 'Plugins' ||
        this.$route.name == 'Ai-Characters' ||
        this.$route.name == 'Personal-Voice' ||
        this.$route.name == 'Report' ||
        this.$route.name == 'NewsReader' ||
        this.$route.name == 'AutoDubbing' ||
        this.$route.name == 'ApiLog' ||
        this.$route.name == 'My-Assets'
        ){
        return true
      } else {
        return false
      }
    },

    validationBurger(){
      if(this.$route.name != 'Use-Template' && this.$route.name != 'Create-My-Course' && this.$route.name != 'Detail-Quiz' && this.$route.name != 'Topup-SWC' && this.$route.name != 'Detail-Dashboard' && this.$route.name != 'Subscriptions' && this.$route.name == 'PaymentMethod' && this.$route.name != 'Leaderboard-Final' && this.$route.name != 'Edit-My-Quiz' && this.$route.name != 'Edit-Template' && this.$route.name != 'Create-Content' && this.$route.name != 'Create-Question' && this.$route.name != 'Create-Question-Creator' && this.$route.name != 'Profile-Creator' && this.$route.name != 'Profile-Creator-nl' && this.$route.name != 'Update-Question-Creator' && this.$route.name != 'Leaderboard-Quiz' && this.$route.name != 'Live-Quiz' && this.$route.name != 'Create-My-Quiz' && this.$route.name != 'Generative-Ai' && this.$route.name != 'Generative-Ai-Code-Test' && this.$route.name != 'MyRewards' && this.$route.name != 'Generative-Ai-Options' && this.$route.name != 'Generate-Text-To-Audio' && this.$route.name != 'Edit-Text-To-Audio' && this.$route.name != 'Publish-Text-To-Audio' ){
        return true;
      } else {
        return false
      }
    },

    validationSocaLogo(){
      if(this.$route.name != 'Create-My-Quiz' && this.$route.name != 'Create-My-Course' && this.$route.name != 'Subscriptions' && this.$route.name != 'PaymentMethod' &&  this.$route.name != 'Use-Template' && this.$route.name != 'Detail-Quiz' && this.$route.name != 'Topup-SWC' && this.$route.name != 'Detail-Dashboard' && this.$route.name != 'Edit-Template' && this.$route.name != 'Leaderboard-Final' && this.$route.name != 'Edit-My-Quiz' && this.$route.name != 'Update-Question-Creator' && this.$route.name != 'Create-Question-Creator' && this.$route.name != 'Create-Question' && this.$route.name != 'Generative-Ai' && this.$route.name != 'MyRewards' && this.$route.name != 'Generative-Ai-Options' && this.$route.name != 'Generate-Text-To-Audio' && this.$route.name != 'Edit-Text-To-Audio' && this.$route.name != 'Publish-Text-To-Audio' && this.$route.name != 'Generative-Ai-True-False' && this.$route.name != 'Generative-Ai-Code-Test' && this.$route.name != 'Detail-Report' && this.$route.name != 'Detail-Report-Peserta' && this.$route.name != 'WatchVideo'){
        return true;
      } else {
        return false;
      }
    },

    routerHistory(){
      if(this.$route.name == 'Detail-Dashboard'){
        return true;
      } else {
        return false
      }
    },


    //This is for sidebar trigger in mobile
    activeSidebar() {
      if(this.showToggle){
        this.showToggle = false
      } else {
        this.showToggle = true
        // $("header.gridx").parent().addClass("stretch-margin");
      }
      this.$store.commit("IS_SIDEBAR_ACTIVE", this.showToggle);
    },
    publishQuiz(){
      this.$router.push({ name: 'Create-Publish-Quiz' })
    },
    backToHome(){
      this.$router.push({ name: 'Home' })
    },
    publishCreateQuestion(){
        const idTemp = this.$route.query.idTemp != undefined ? this.$route.query.idTemp : null;
        const authorTemp = this.$route.query.author != undefined ? this.$route.query.author : null;

        let quizName = localStorage.quiz_name || null
        let kategoriId = localStorage.category_id || null
        let list = localStorage.getItem("listQuestionCreate")

        if(quizName && kategoriId && list) {
          this.$store.commit("publishCreateQuestion", { id: idTemp, author : authorTemp })
        }

        // if(quizName == null || quizName == ''){
        //   this.title_alert = 'Quiz Name cannot be Empty'
        //   this.sub_alert = 'You must first input a Quiz name'
        //   this.$bvModal.show('validate-quiz')
        // } else if(kategoriId == null || kategoriId == '') {
        //   this.title_alert = 'Topic cannot be Empty'
        //   this.sub_alert = 'You must first choose a Topic'
        //   this.$bvModal.show('validate-quiz')
        // }if( list == undefined ) {
        //   this.title_alert = 'Questions cannot be Empty'
        //   this.sub_alert = 'You must first input or create a Question'
        //   this.$bvModal.show('validate-quiz')
        // } else {
        //   this.$store.commit("publishCreateQuestion", { id: idTemp, author : authorTemp })
        // }
    },
    publish(){
      const id = this.$route.query.key

        let quizName = localStorage.quiz_name_edit || null
        let kategoriId = localStorage.category_id_edit || null
        let list = localStorage.getItem("listQuestionCreator")

        if(quizName && kategoriId && list) {
          this.$store.commit("publishQuiz", id)
        }

        // if(quizName == null || quizName == ''){
        //   this.title_alert = 'Quiz Name cannot be Empty'
        //   this.sub_alert = 'You must first input a Quiz name'
        //   this.$bvModal.show('validate-quiz')
        // } else if(kategoriId == null || kategoriId == '') {
        //   this.title_alert = 'Topic cannot be Empty'
        //   this.sub_alert = 'You must first choose a Topic'
        //   this.$bvModal.show('validate-quiz')
        // } else if( list == undefined ) {
        //   this.title_alert = 'Questions cannot be Empty'
        //   this.sub_alert = 'You must first input or create a Question'
        //   this.$bvModal.show('validate-quiz')
        // } else {
        //   this.$store.commit("publishQuiz", id)
        // }
    },
    simpanQuestionCreator(){
      const id = this.$route.query.key
      this.$store.commit("simpanQuestionCreator", id)
    },
    simpanCreateQuestionCreator(){
      const id = this.$route.query.key
      this.$store.commit("simpanCreateQuestionCreator", id)
    },
    batalSimpan(){
      if(this.$route.name == 'Subscriptions' || this.$route.name == 'PaymentMethod') {
        this.$router.go(-1)
      } else {
        this.$router.push({ name : 'My-Quiz' })
      }
    },
    updateQuestionCreator(){
      const id = this.$route.query.key
      this.$store.commit("updateQuestionsCreator", id)
    },
    simpanTemplate(){
      const id = this.$route.query.key
      this.$store.commit("editTemplate", id)
    },
    publishFromTemplate(){
        const id = this.$route.query.key
        const author = this.$route.query.author

        let quizName = localStorage.quiz_name_temp || null
        let kategoriId = localStorage.category_id_temp || null
        let list = localStorage.getItem("listQuestion")
        console.log(quizName)

        if(quizName == null || quizName == ''){
          Swal.fire({
            title: 'Terjadi Kesalahan!',
            text: 'Nama Quiz tidak boleh kosong !',
            icon: 'error',
            confirmButtonText: 'Back'
          })
        } else if(kategoriId == null || kategoriId == '') {
          Swal.fire({
            title: 'Terjadi Kesalahan!',
            text: 'Subjek tidak boleh kosong !',
            icon: 'error',
            confirmButtonText: 'Back'
          })
        } else if( list == undefined ) {
          Swal.fire({
            title: 'Terjadi Kesalahan!',
            text: 'Soal tidak boleh kosong !',
            icon: 'error',
            confirmButtonText: 'Back'
          })
        } else {
          this.$store.commit("publishFromTemplate", id, author)
        }
    },
    batalSimpanCreator(){
      this.$store.state.canceledCreate = true
      this.$router.go(-1);
    },

    batalSimpanUpdate(){
      this.$store.state.canceledUpdate = true

      this.$router.go(-1);
    },
    batalSimpanTemplate(){
      this.$store.state.canceledTempalte = true

      this.$router.go(-1);
    },

    simpanCreateQuestionPolling(){
      const id = this.$route.query.key
      this.$store.commit("simpanCreateQuestionPolling", id)
    },

    batalSimpanCreatePolling(){
      this.$store.state.canceledCreatePolling = true
      this.$router.go(-1);
    },

    publishCreatePolling(){
        let quizName = localStorage.quiz_name_polling || null
        let list = localStorage.getItem("listQuestionCreatePolling")

        if(quizName == null || quizName == ''){
          Swal.fire({
            title: 'Terjadi Kesalahan!',
            text: 'Nama Quiz tidak boleh kosong !',
            icon: 'error',
            confirmButtonText: 'Back'
          })
        } else if( list == undefined ) {
          Swal.fire({
            title: 'Terjadi Kesalahan!',
            text: 'Soal tidak boleh kosong !',
            icon: 'error',
            confirmButtonText: 'Back'
          })
        } else {
          this.$store.commit("publishCreatePolling")
        }
    },

    toProfile(){
      this.$router.push({ name: 'MyAccount' })
    },

    setSearch(e) {
      if (e.target.value == null || e.target.value == "") {
        this.$store.state.searchStat = false;
        if(this.$route.name == 'Quiz'){
          this.$router.push({ name: 'My-Quiz' })
        }
      }
    },

    clearSearch(){
      this.$store.state.filterSearchEngine = null;
      this.$store.state.searchStat = false;
      if(this.$route.name == 'Quiz'){
          this.$router.push({ name: 'My-Quiz' })
        }
    },

    getSubQuiz(str){
      const value = str.toUpperCase();
      const search = this.$store.state.filterSearchEngine.toUpperCase();
      const result = value.indexOf(search);
      if (!search || result === -1) {
          return str; // bail early
      }
      const final = search.length;
      return '<b>' + str.substr(0, result) + '</b>' + '<span style="color:#8C8C8C;">' + str.substr(result, final) + '</span>' +'<b>' + str.substr(result + final) + '</b>';
    },

    searchEngine(){
      // if(this.$route.name != 'Ai-Generator' ){
      //   this.$router.push({ name: 'Ai-Generator' });
      // }
      this.$router.push({ name: 'Quiz' });

      this.$store.commit("searchEngine", this.$store.state.filterSearchEngine)
    },

    detailQuiz(id) {
      let token = null

      if(localStorage.getItem("users")) {
          var users = JSON.parse(localStorage.getItem("users"))
          if(users.token) {
              token = users.token
          } else {
              token = localStorage.getItem("token_client") ? localStorage.getItem("token_client") : null
          }
      } else if (localStorage.getItem("token_client")) {
          token = localStorage.getItem("token_client")
      }
      // this.$router.push({ name : 'Generative-Ai', params: { mode: mode, id: id } })
      window.open(base_url_qgen + 'login?token=' + token + '&request=q-gen&mode=edit' + "&id=" + id);
    },

    nextGenerativeAi(){
      this.$store.commit("nextGenerativeAi")
    },

    addQuiz(tipe){
        if(this.users){
            localStorage.removeItem("listQuestionCreator");
            localStorage.removeItem("newListQuestionCreator")
            localStorage.removeItem("questionNew")
            localStorage.removeItem("listQuestion")
            localStorage.removeItem("listQuestionCreate")
            localStorage.removeItem("listQuestionCreatePolling")
            localStorage.removeItem("backUpPollingQuestion")
            localStorage.removeItem("backUpCreateQuestion")
            localStorage.removeItem("backUpUpdateQuestion")
            localStorage.removeItem("quiz_name")
            localStorage.removeItem("quiz_description")
            localStorage.removeItem("images_cover")
            localStorage.removeItem("category_id")
            localStorage.removeItem("createTag")
            localStorage.removeItem("quiz_name_polling")
            localStorage.removeItem("quiz_description_polling")
            localStorage.removeItem("images_cover_polling")
            localStorage.removeItem("category_id_polling")
            localStorage.removeItem("materiAi")
            localStorage.removeItem("gradeAi")
            localStorage.removeItem("subGradeAi")
            localStorage.removeItem("descriptionAi")
            localStorage.removeItem('lengthQuestionAi')
            localStorage.removeItem("grade")
            localStorage.removeItem("sub_grade")
            localStorage.removeItem("index_grade")

            this.$store.state.dataQuiz.category_id = null,
            this.$store.state.dataQuiz.quiz_name = null,
            this.$store.state.dataQuiz.description = null,
            this.$store.state.dataQuiz.images_cover = null

            let formData = {
                content_type : tipe
            };

            postContent(formData)
            .then((response) =>{
                if(response.status && response.status == true){
                    if(tipe == 'quiz'){
                        this.$router.push({ name: 'Create-My-Quiz'})
                    } else if (tipe == 'polling'){
                        this.$router.push({ name: 'Setup-Polling'})
                    } else if(tipe == 'generative-ai') {
                        // if(this.users.subscribe == true) {
                            this.$router.push({ name: 'Generative-Ai-Options'})
                        // }
                    }
                }
            })
        } else {
            this.$bvModal.show('signIn')
        }
    },
    addCourse() {
        if(this.users){
            // if(this.users.subscribe == true) {
                this.$router.push({ name: 'Create-My-Course'})
            // }
        } else {
            this.$bvModal.show('signIn')
        }
    },

    publishTextToAudio() {
      this.$router.push({ name: 'Publish-Text-To-Audio' })
    },

    publishTextToAudioNewUI(){
      this.$router.push({ name: 'Publish-Text-To-Audio' })
    },

    // Detail audio by mode
    detailAudio(id, status){
      this.$router.push({ name : 'Generate-Videos', params : { mode : status == true ? 'draft' : 'edit', id : id } })
    },

    toggleSidebar() {
      if (this.$store.state.isSidebarActive) {
        this.$store.commit('IS_SIDEBAR_ACTIVE', false);
        this.$store.commit('IS_SIDEBAR_ACTIVE_BY_HAMBURGER', false);
      } else {
        this.$store.commit('IS_SIDEBAR_ACTIVE', true);
        this.$store.commit('IS_SIDEBAR_ACTIVE_BY_HAMBURGER', true);
      }
    },

    getDataNotifications() {
      this.isGetNotif = true
      getNotifications()
      .then((response) => {
        if(response.status) {
          this.data_notif = response.data
          if(!this.data_notif[0]?.claim_status) {
            this.drSliderInit()
          }
          this.getLengthNotifications()
          this.isGetNotif = false
          this.isClaim = false
        } else {
          this.isGetNotif = false
          this.data_notif = []
        }
      })
      .catch((error) => {
        this.isGetNotif = false
        this.isClaim = false
        this.data_notif = []
        console.error(error)
      })
    },
    getLengthNotifications() {
      getTotalNotifications()
      .then((response) => {
        if(response.status) {
          this.notif_length = response.data
          if(this.notif_length > 0) {
            this.$nextTick(() => {
              if(this.$route.name == 'Home') {
                this.handlePlayNotif('notif')
              }
            })
          }
        }
      })
    },
    getDate(item){
      const date = moment(item);
      let now = moment();
      now = now.add(420, 'minutes')
      const diff = now.diff(date, 'seconds');

      if (diff < 1) {
        return this.$t('a-few-secs-ago');
      } else if (diff < 60) {
        return diff + ' ' + this.$t('secs-ago');
      } else if (diff < 3600) {
        const minutes = Math.floor(diff / 60);
        return minutes + ' ' + this.$t('minutes-ago');
      } else if (diff < 86400) {
        const hours = Math.floor(diff / 3600);
        return hours + ' ' + this.$t('hours-ago');
      } else if (diff < 604800) {
        const days = Math.floor(diff / 86400);
        return days + ' ' + this.$t('days-ago');
      } else if (diff < 2592000) {
        const weeks = Math.floor(diff / 604800);
        return weeks + ' ' + this.$t('weeks-ago');
      } else if (diff < 31536000) {
        const months = Math.floor(diff / 2592000);
        return months + ' ' + this.$t('months-ago');
      } else {
        const years = Math.floor(diff / 31536000);
        return years + ' ' + this.$t('years-ago');
      }
    },
    drSliderInit() {
      this.$nextTick(() => {
          this.sliderDr = tns({
              container: '.dr__slider',
              center: true,
              controls: false,
              nav: false,
              speed: 500,
              loop: false,
              gutter: 24,
          })
      })
    },
    claimDailyReward(id) {
      this.isClaim = true
      claimReward(id)
      .then((response) => {
        if(response.status) {
          this.getDataNotifications()
          this.handlePlayNotif('claim')
          EventBus.$emit('triggerSidebarMethod');
        }
      })
    },
    closeNotification() {
      $('.container-notifications').removeClass('show');
    },
    async handlePlayNotif(type) {
      let notif = require('@/assets/audio/notif.mp3')
      let claimNotif = require('@/assets/audio/claim_fx.mp3')
      if(type == 'claim') {
        this.$refs.notifPlayer.src = notif
      } else {
        this.$refs.notifPlayer.src = claimNotif
      }
      await this.$refs.notifPlayer.play();
    },
    async getNewsReader() {
      await getDataNewsReader()
      .then((res) => {
          if(res.status) {
              this.newsReader = res.data
          }
        })
        .catch((err)=>{
          console.log("data err", err)
        })
    },
    getAutoDubb(shouldShowPreview) {
        getAutodubList()
        .then((response) => {
            if(response.status) {
                this.autoDubbing = response.data;
                if(shouldShowPreview) {
                    this.isSubmitFeedback = false
                    this.$bvModal.hide('feedback-autodub-modal')
                    this.showPreviewAfterFeedback()
                }
            }
            this.loading_autodubb = false
        })
        .catch((error) => {
            this.loading_autodubb = false
            console.error(error)
        })
    },
    detailNewsReader(id) {
      this.clearSearch()
      this.$router.push({ name : 'NewsReader', params : { mode: 'edit', id: id } })
    },
    handlePreviewAutoDubb(data) {
        if(data.content_file) {
            this.previewAutoDub = data.content_file
            this.isFeedback = data.feedback_status
            this.idAutodubActive = data._id
            this.jobIdAutodub = data.job_id
            this.previewAutoDubName = data.content_name
            if(this.jobIdAutodub && this.previewAutoDub) {
              this.$bvModal.show('show-preview-modal')
            }
        }
    },
    handleFeedbackAutodub(val) {
        this.isSubmitFeedback = true
        let formData = {
            feedback_option : val.option,
            feedback_comment : val.comment
        }
        postFeedbackAutodub(formData, this.idAutodubActive)
        .then((response) => {
            if(response.status) {
                this.loading_autodubb = true
                this.getAutoDubb(this.isSubmitFeedback)
            }
        })
        .catch(() => {
            this.isSubmitFeedback = false
            this.alertFail('Something went wrong!')
            this.$bvModal.hide('feedback-autodub-modal')
        })
    },
    showPreviewAfterFeedback() {
        let previousPreview = this.autoDubbing.find((item) => item._id == this.idAutodubActive)
        this.handlePreviewAutoDubb(previousPreview)
    },
  },
};
</script>

<style scoped>
.selected--lang{
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
}
.pr--notify{
  max-height: 380px;
  overflow-y: auto;
}
.profile-user{
  width: 40px;
  height: 40px;
  border: 2px solid #29292d !important;
  transition: all .35s;
}

@media (width >= 576px) {
  .profile-user {
    margin-left: 1rem;
  }
}

.profile-user:hover{
  transition: all .35s;
  border: 2px solid #6D75F6 !important;
}
.section-profile, .section-referral{
  padding-top: 7px;
}
.section-logout{
    padding-left: 8px;
    cursor: pointer;
}
.section-logout:hover{
    font-weight: bold;
    color: white !important;
    background-color: #2D2D2D;
    opacity: 1 !important;
    border-radius: 4px;
    padding-left: 8px;
}
.section-profile:hover, .section-referral:hover{
  font-weight: bold;
  color: white !important;
  background-color: #2D2D2D;
  opacity: 1 !important;
  border-radius: 4px;
  padding-top: 7px;
}
.email-users{
  color: #8C8C8C;
}
.options-lang{
    background-color: #2D2D2D;
    color: #ffffff;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 5%);
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 33px;
    min-width: 0;
    padding-top: 5px;
    padding-bottom: 5px !important;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.50);
}
.item-lang:hover{
    background: #E3DEFF !important;
    border-radius: 5px;
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    color: #6D75F6;
}
/* .show{
  transition: all 0.7s;
  opacity: 1;
  visibility: visible;
} */

.arrow_lang--active{
    animation-name: animation-arrow-toup;
    transform: rotate(180deg);
    animation-duration: 0.5s;
}
@keyframes animation-arrow-toup {
  0%   {transform: rotate(90deg);}
  100% {
    transform: rotate(180deg);
  }
}
/* .arrow_lang{
  transform: rotate(90deg);
} */

.mx-lang{
  margin-left: 0.4rem;
  margin-right: 0.001rem;
}

.box-change-lang{
  border: 1px solid #2D2D2D;
  border-radius: 6px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  cursor: pointer;
}
.position-relative{
  position: relative;
}

.dropdown-profile hr{
  border: none;
    background: #2D2D2D;
}
.dropdown-profile{
  width: 207px;
  background-color: #1F1F1F;
  color: #ffffff;
  left: -30px !important;
  top: 2px !important;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
  /* position: relative !important; */
  font-size: 12px;
}
.information:hover .tool-tip{
  display: block;
}
.tool-tip {
    left: -157px !important;
    width: 232px !important;
    background-color: #1F1F1F;
    color: #ffffff;
    font-size: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
}
.text-plan{
  color: #8C8C8C
}
#name_admin {
  float: right;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 800;
  color: #50626f;
}
#title_page {
  color: #98a2ae;
  margin-left: 15px;
  font-weight: 900;
  font-size: 12.5px;
}

.cc-icon {
  width: 14px;
  height: 14px;
}

i.vs-icon.notranslate.icon-scale.material-icons.null {
  color: #a2acb6;
}
.topnavbar{
  background-color: #000 !important;
  top:0;
}

.header {
  flex-grow: 1;
  width: 100%;
  max-width: 555px;
}

@media (width >= 576px) {
  .header {
    margin: 0 2rem;
  }
}

@media (width >= 768px) {
  .header {
    margin: 0 4rem;
  }
}

@media (width >= 992px) {
  .header {
    margin: 0 6rem;
  }
}

.header label {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 25px !important;
  z-index: 2;
}

.header label:before {
  content: "";
  position: absolute;
  left: 0.5rem;
  top: -1px;
  bottom: 0;
  width: 40px;
  background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
  background-size: 20px;
  z-index: 10;
}

.profile label {
  position: relative;
  height: 40px;
}

.search-bar {
  height: 2.3rem;
  color: white;
  width: 100%;
  border-radius: 6px;
  background: #1F1F1F;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border: 0px;
  padding-left: 48px !important;
  padding-right: 20px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  font-size: 14px;
}

.result-search {
  margin-top: 10px;
  border-radius: 6px;
}

.result-search .result {
  overflow-y: auto;
  height: 15rem;
}

.result-search .result::-webkit-scrollbar, .inner-notifications::-webkit-scrollbar {
    width: 6px;
    margin-right: 10px ;
}

        /* Track */
.result-search .result::-webkit-scrollbar-track, .inner-notifications::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

        /* Handle */
.result-search .result::-webkit-scrollbar-thumb, .inner-notifications::-webkit-scrollbar-thumb{
    background: #B6B6B6;
    border-radius: 4px;
}

        /* Handle on hover */
.result-search .result::-webkit-scrollbar-thumb:hover, .inner-notifications::-webkit-scrollbar-thumb:hover{
    background: #888;
}

.img-search-engine, .nw__wrapper {
  width: 38px;
  height: 30px;
  border-radius: 2px;
}

.img-search-engine {
  object-fit: cover;
}

.nw__wrapper {
  background-color: #2d2d2d;
}

.container-notifications{
  border-radius: 10px;
  width: 250px;
  background-color: #1F1F1F;
  color: white !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
}

.container-notifications .card {
  border-radius: 10px;
  border: 1px solid #1F1F1F;
}

.container-notifications .desc{
  font-size: 10px;
}

.container-notifications p {
  font-size : 12px;
  color: #585859;
}

.img-notif {
  width: 4.25rem;
  height: 3.375rem;
  border-radius: 3px;
}

.inner-notifications{
  max-height: 23.75rem;
}

.dot-notif {
    border-radius: 10px;
    position: absolute;
    top: 5px;
    font-size: 10px;
    right: 10px;
}

.clear {
    right: 10px;
    top: 12px;
}

.btn-gradient{
  color: #6D75F6;
  border: 2px solid #6D75F6;
  background: transparent;
}

.btn-purple{
  background-color: #6D75F6;
  color: white;
  transition: background-color 0.25s;
}

.btn-purple:hover {
  background-color: #545EED;
}

.btn-outline-purple{
  background-color: transparent;
  border: 1px solid #6D75F6;
  color: #6D75F6;
}

.plus-filter{
  filter: invert(.5) sepia(1) saturate(38) hue-rotate(220deg);
}

.make-content-container{
  background-color: #222222;
  border-radius: 5px;
}

.w-115{
  width:115%;
}

.ml-10{
  margin-left: 10rem !important;
}

.btn-disabled {
    background-color: #2D2D2D;
    border: 1px solid #2D2D2D;
    color: #8C8C8C;
}

.btn-notif {
  box-shadow: none;
  color: white !important;
}
.invert{
    filter: invert(100%);
}

.hamburger-button {
  display: inline-flex;
  margin-right: 0.5rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #FFFFFF;
}

@media screen and (min-width: 1169px) {
  .hamburger-button {
    display: none;
  }
}

.payment-title {
  font-size: 20px;
  font-weight: 500;
}

.notif__title {
  color: #FFF !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dr__content {
  border-radius: 8px;
	background-image: linear-gradient(to bottom right, #FFFFFF33, #FFFFFF11);
	padding: 1px;
}

.dr__content-wrapper {
  background-color: #121418;
	background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
	border-radius: 8px;
	backdrop-filter: blur(2px);
  padding: 15px 10px;
  gap: 6px;
}

.dr__content--text {
  color: #FFF !important;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dr__content--icon {
  width: 24px;
  height: 24px;
}

.dr__content--reward {
  gap: 4px;
}

.drcr--title {
  color: #FFF !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.drcr--subtitle {
  color: #8C8C8C !important;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dr__button--buy {
  background-color: #ec1814;
  color: #fff !important;
  font-size: 10px;
  font-weight: 600;
  border-radius: 4px;
  width: 100%;
}
.dr__button--claim {
  background-color: #6D75F6;
  color: #fff !important;
  font-size: 10px;
  font-weight: 600;
  border-radius: 4px;
  width: 100%;

  transition: background-color 0.25s;
}

.dr__button--claim:not(:disabled):hover {
  background-color: #545EED;
}

.dr__button--claim:disabled {
  background-color:  #2D2D2D;
  color: #585859 !important;
}

.dr__button-left, .dr__button-right {
    box-shadow: none;
    padding: 0;
    color: #fff
}

.dr__button-left:disabled, .dr__button-right::disabled {
    color: #8C8C8C
}

.dr__slider {
    overflow: hidden;
}
</style>

<style>
.dr__slider--wrapper .tns-outer{
  width: 88%;
}

@media (width < 1169px) {
  .topnavbar .vs-navbar--header {
    padding-right: 15px;
  }
}

@media (width <= 800px) {
  .vs-navbar, .vs-con-items {
    display: flex;
  }
}
</style>
