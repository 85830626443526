<template>
  <div v-if="showMenu" class="drop-menu" ref="dropMenu">
    <div class="inner-drop__menu">
		<div class="my-3">
			<div class="px-4 mt-1">
				<span>Start with</span>
				<div class="items-create mt-2 cursor-pointer">
				<div class="container-fluid">
					<div
					class="row align-items-center cursor-pointer"
					@click="$bvModal.show('impor-soal-excel')"
					>
					<div
						class="col-1 d-flex align-items-center justify-content-center"
					>
						<img
						src="@/assets/images/icons/ic_excel_smaller.svg"
						alt="Import Quiz"
						/>
					</div>
					<div class="col-11">
						<p class="mb-0 ml-2">Import Quiz</p>
					</div>
					</div>
				</div>
				</div>
			</div>
			<hr style="background: #2d2d2d" />
			<div class="px-4 mb-2">
				<span>Or start from an AI Generator</span>
				<div
				v-for="(data, index) in ai_generator"
				:key="index"
				class="items-create mt-2 cursor-pointer"
				@click="
					index == 1
					? $router.push({
						name: 'Generate-Text-To-Audio',
						params: { mode: 'new', id: '1' },
						})
					: index == 0
					? toTtq()
					: ''
				"
				>
				<div class="container-fluid">
					<div class="row align-items-center">
					<div
						class="col-1 d-flex align-items-center justify-content-center"
					>
						<img
						:src="require(`@/assets/images/icons/${data.icon}`)"
						:alt="data.title"
						/>
					</div>
					<div class="col-11">
						<p class="mb-0 ml-2">
						{{ data.title }}
						<small v-if="index == 2" style="color: #585859"
							><i>soon</i></small
						>
						</p>
					</div>
					</div>
				</div>
				</div>
			</div>
		</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ai_generator: [
        {
          icon: "ic_bulat.svg",
          title: "AI Quiz Maker",
        },
        {
          icon: "ic_kotak.svg",
          title: "AI Voice Transcriptions",
        },
        {
          icon: "ic_segienam.svg",
          title: "AI Characters",
        },
      ],
    };
  },
  props: {
	showMenu : {
		type: Boolean,
		default: false
	}
  },
  methods: {
	toTtq() {
		this.$router.push({ name: 'Generative-Ai', params : { mode: 'new', id : '1' }})
	}
  }
};
</script>

<style scoped>
.drop-menu {
	position: fixed;
	z-index: 99999;
    left: 250px;
    top: 74px;
	color: white;
}
.inner-drop__menu{
    background: #1F1F1F;
    display: grid;
    width: 250px;
    text-align: left;
	border-radius: 6px;
    box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
}

.inner-drop__menu span {
    font-size: 12px;
    color: #585859;
}
</style>
