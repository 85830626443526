<template>
    <div>
        <b-modal id="verif-code-modal" centered header-class="border-0 pt-4 pb-0" no-close-on-backdrop :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md" @hidden="pin_wrong = null, otp = null" @shown="handleShownModal" @hide="handleHideModal">
          <div class="d-flex justify-content-end mb-2">
            <button
              class="btn btn-close d-flex justify-content-center align-items-center close-image"
              @click="$bvModal.hide('verif-code-modal'), pin_wrong = null"
            >
              <b-icon icon="x"></b-icon>
            </button>
          </div>
          <div class="input-pin container-fluid my-3 px-5">
            <h3 class="input-pin__header">Verification Code</h3>
            <h5 class="font-weight-normal mt-2">Input your code that has been sent to your email</h5>
            <form @submit.prevent="submitOtp">
              <div class="my-3 mx-md-3">
                <div style="display: flex; flex-direction: row;" class="verivikasi-pin d-flex justify-content-center mb-1">
                    <v-otp-input
                        ref="otpInput"
                        :input-classes="pin_wrong != null && pin_wrong == true ? 'otp-input form-control text-center not-match' : 'otp-input form-control text-center'"
                        separator="&nbsp;&nbsp;&nbsp;"
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        @on-change="handleOnChange"
                        @on-complete="handleOnComplete"
                    />
                </div>
                <p v-if="pin_wrong != null && pin_wrong == true" class="validator__input">The code you entered is incorrect.</p>
              </div>
              <button :disabled="!otp || otp.toString().length < 6" type="submit" class="btn btn-submit btn-md btn-block mt-3">{{ loading ? 'Loading...' : 'Submit' }}</button>
              <h5 class="my-4 font-weight-normal">Don’t received code? <span class="rotp" @click="resendOtp">Resend</span></h5>
            </form>
          </div>
        </b-modal>
    </div>
</template>

<script>
import { confirmPinOtp, requestSetPin } from "@/services/pin/pin.service";
import { AlertUtils } from "@/mixins/AlertUtils";

export default {
  mixins: [AlertUtils],
	data() {
		return {
			otp: null,
			pin_wrong: null,
      loading: false,
		}
	},

  emits: ['otp-success'],

	methods: {
		handleOnChange(val){
      		this.otp = val
		},
		handleOnComplete(val) {
      		this.otp = val
		},

    submitOtp(){
      if(this.otp && this.otp.toString().length == 6) {
        this.loading = true

        let formData = {
          otp : this.otp
        }

        confirmPinOtp(formData)
        .then((response) => {
          console.log(response)
          if(response.status) {
            this.loading = false
            this.$emit('otp-success')
          } else {
            if(response.status == false && response.message == 'OTP not match') {
              this.loading = false
              this.pin_wrong = true;
            } else {
              this.loading = false
              this.$bvModal.hide('verif-code-modal')
              this.pin_wrong = null
              this.alertFail("Too many requests, please try again later.")
            }
          }
        })
      }
    },

    resendOtp() {
      requestSetPin()
      .then((response) => {
        if(response.status == true) {
          this.alertSuccess(this.$t('otp-sent-email'))
        } else {
          if(response.response?.response?.status == 429) {
            this.alertFail("Too many requests, please try again later.")
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
    },
    addEnterKeyListener(event) {
      if (event.key === 'Enter') {
        this.submitOtp()
      }
    },
    handleShownModal() {
      window.addEventListener("keydown", this.addEnterKeyListener);
    },
    handleHideModal() {
      window.removeEventListener("keydown", this.addEnterKeyListener);
    }
	},
}
</script>

<style scoped>
.btn-submit {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
	  font-weight: bold;
	  font-size: 16px;
    box-shadow: none !important;
	  padding: 15px;
	  border-radius: 10px;
}

.btn-submit:disabled {
	background-color: #2D2D2D;
    border: 1px solid #2D2D2D;
	color: #585859;
}

.validator__input {
	color: #DF2723;
	font-weight: 400;
	margin: 0;
}

.rotp {
  color: #6D75F6;
  cursor: pointer;
  font-weight: 500;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#verif-code-modal .modal-content{
    border-radius: 12px !important;
    background-color: #222222;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

.input-pin {
	color: white;
	text-align: center;
}

.input-pin__header {
	font-size: 30px;
	font-weight: 900;
}

.btn-close {
  background-color: #ededed;
  border-radius: 50%;
  width: 15px;
  height: 26px;
}
</style>

<style scoped lang="less">
  .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
      border: 1px solid red !important;
    }
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>

<style>
.verivikasi-pin .form-control{
    color: white;
    background-color:transparent;
    border: 1px solid #383838;
    max-width: 45px;
	height: 50px;
    padding: 0;
}

.verivikasi-pin input:focus{
    color: white;
    background-color:transparent;
}

.not-match {
	border: 1px solid #DF2723 !important;
}
</style>