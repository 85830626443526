<template>
    <div class="subscriptions">
        <b-modal id="subs-modal" ref="subs" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
            <div class="float-right">
                <button class="btn btn-close d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('subs-modal')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="subscrip">
                <div class="container-fluid mx-4">
                    <div class="row align-items-center mt-5">
                        <div class="col-1">
                            <img src="@/assets/images/icons/subs-dm.svg" alt="">
                        </div>
                        <div class="col-11">
                            <div class="ml-3 d-flex align-items-center">
                                <h3 class="font-weight-normal"><b>Upgrade</b> ke</h3>
                                <img class="ml-2 mt-1" src="@/assets/images/icons/pro-text.svg" alt="">
                            </div>
                            <p class="ml-3 mb-0 text-muted mt-1">Nikmati serunya menggunakan fitur tanpa batas</p>
                        </div>
                    </div>
                </div>
                <div class="content-price mt-4 mx-4">
                    <div class="mx-4 mt-3 mb-4"> 
                        <div class="d-flex align-items-end">
                            <h1>$29</h1>
                            <h4 class="font-weight-normal mb-2">/host/month</h4>
                        </div>
                        <p class="text-muted mb-0">Biilled Annually</p>
                        <h3 class="mt-2 fw-500">Unlimited Quizzes Up to 100 Participants</h3>
                    </div>
                </div>

                <div class="mx-4 mt-4">
                    <div class="d-flex align-items-center">
                        <img src="@/assets/images/icons/ic_unlimited.svg" alt="content">
                        <h4 class="ml-2 fw-500">Buat konten tanpa batas</h4>
                    </div>
                    <div class="d-flex align-items-center mt-3">
                        <img src="@/assets/images/icons/ic_participant.svg" alt="participant">
                        <h4 class="ml-2 fw-500">Mengundang lebih banyak participants</h4>
                    </div>
                    <div class="d-flex align-items-center mt-3">
                        <img src="@/assets/images/icons/ic_space.svg" alt="space">
                        <h4 class="ml-2 fw-500">Dapat menggunakan Avatars dan Spaces yang beragam</h4>
                    </div>

                    <div class="w-100 mt-4">
                        <button type="button" class="btn btn-primary btn-lg btn-block btn-purple" @click="goSubs"><h4 class="fw-500 my-1">Aktifkan Sekarang</h4></button>
                    </div>

                    <div class="text-center mt-3 mb-3">
                        <p class="mb-0 text-muted">Informasi lebih lanjut hubungi kami</p>
                        <p class="mb-0 font-weight-bold text-purple"><a class="text-purple" href="mailto:support@soca.ai">support@soca.ai</a></p>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal id="payment-modal" ref="subs" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="xl">
            <div class="float-right">
                <button class="btn btn-close d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('payment-modal')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="subscrip mt-5 mx-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-5">
                            <h3>Masuk Detail Pembayaran</h3>
                            <div class="form text-left mt-4">
                                <div class="email">
                                    <p class="fw-500 mb-2">Email</p>
                                    <b-form-input type="text" v-model="formPayment.email" readonly id="email"></b-form-input>
                                </div>
                                <div class="informasi-kartu mt-3">
                                    <div class="d-flex align-items-center  mb-2">
                                        <p class="fw-500 mb-0">Informasi kartu</p>
                                        <img class="ml-3" src="@/assets/images/icons/ic_visa.svg" alt="visa">
                                    </div>
                                    <b-form-input type="text" id="informasi-kartu" placeholder="cth: 1234 XXXX XXXX XXXX"></b-form-input>
                                </div>
                                <div class="form-row mt-2">
                                    <div class="col-md-6">
                                        <b-form-input type="text" id="mmyy" placeholder="MM/YY" class=""></b-form-input>
                                    </div>
                                    <div class="col-md-6">
                                        <b-form-input type="text" id="cvv" placeholder="CVV" class=""></b-form-input>
                                    </div>
                                </div>

                                <div class="nama-lengkap mt-3">
                                    <p class="fw-500 mb-2">Nama Lengkap</p>
                                    <b-form-input type="text" id="nama-lengkap" placeholder="masukkan nama pada kartu"></b-form-input>
                                </div>

                                <div class="alamat mt-3">
                                    <p class="fw-500 mb-2">Alamat</p>
                                    <b-form-textarea size="lg" id="alamat" placeholder="masukkan alamat lengkap"></b-form-textarea>
                                </div>

                                <button type="button" class="btn btn-primary btn-lg btn-block btn-purple mt-3" @click="goSubs"><h4 class="fw-500 my-1">Bayar Sekarang</h4></button>
                            </div>
                        </div>
                        <div class="col-md-1 d-flex justify-content-center line-row">
                            <hr>
                        </div>
                        <div class="col-6 my-5">
                            <div class="container-fluid mx-4 my-5">
                                <div class="row align-items-center">
                                    <div class="col-1">
                                        <img src="@/assets/images/icons/subs-dm.svg" alt="">
                                    </div>
                                    <div class="col-11">
                                        <div class="ml-3 d-flex align-items-center">
                                            <h3 class="font-weight-normal"><b>Upgrade</b> ke</h3>
                                            <img class="ml-2 mt-1" src="@/assets/images/icons/pro-text.svg" alt="">
                                        </div>
                                        <p class="ml-3 mb-0 text-muted mt-1">Nikmati serunya menggunakan fitur tanpa batas</p>
                                    </div>
                                </div>
                            </div>
                            <div class="content-price mx-4">
                                <div class="mx-4 mt-3 mb-4"> 
                                    <div class="d-flex align-items-end">
                                        <h1>$29</h1>
                                        <h4 class="font-weight-normal mb-2">/host/month</h4>
                                    </div>
                                    <p class="text-muted mb-0">Biilled Annually</p>
                                    <h3 class="mt-2 fw-500">Unlimited Quizzes Up to 100 Participants</h3>
                                </div>
                            </div>
                            <div class="mx-4 mt-4 mb-5">
                                <div class="d-flex align-items-center">
                                    <img src="@/assets/images/icons/ic_unlimited.svg" alt="content">
                                    <h4 class="ml-2 fw-500">Buat konten tanpa batas</h4>
                                </div>
                                <div class="d-flex align-items-center mt-3">
                                    <img src="@/assets/images/icons/ic_participant.svg" alt="participant">
                                    <h4 class="ml-2 fw-500">Mengundang lebih banyak participants</h4>
                                </div>
                                <div class="d-flex align-items-center mt-3">
                                    <img src="@/assets/images/icons/ic_space.svg" alt="space">
                                    <h4 class="ml-2 fw-500">Dapat menggunakan Avatars dan Spaces yang beragam</h4>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { subscriptions } from '@/services/subscriptions/subscriptions.service'
export default {
    data(){
        return{
            formPayment: {
                email: null,
                cth: null,
                mmyy:null,
                cvv: null,
                nama: null,
                alamat: null,
            }
        }
    },

    mounted() {
        let users = localStorage.getItem("users")

        if(users){
            let userParse = JSON.parse(users)
            this.formPayment.email = userParse.email
        }
    },
    methods: {
        toPayment(){
            this.$bvModal.hide('subs-modal')
            this.$bvModal.show('payment-modal')
        },
        
        goSubs(){
            let user = ""
            let id_client = ""
            if(localStorage.getItem('users')){
                user = JSON.parse(localStorage.getItem('users'))
            } else {
                id_client = localStorage.getItem('id_client')
            }

            let formData = {
                user_id : user._id ? user._id : id_client,
                email : user.email
            }

            subscriptions(formData)
            .then((response) => {
                if(response.status && response.status == true){
                    this.$router.push({ name: "Subscriptions", query: { key: response.data.invoice_url },})
                }
            })
        }
    }
}
</script>

<style scoped>
.subscrip h1{
    font-size: 58px !important;
}

.subscrip h3{
    font-size: 23px !important;
}

.subscrip p{
    font-size: 15px !important;
}

.content-price{
    border: 1px solid #7280FF;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(215,219,255,1) 100%);
    border-radius: 7px;
    /* height:10rem */
}

.fw-500{
    font-weight: 500;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
}

.text-purple {
    color: #6D75F6 !important;
}

.line-row hr {
    border:none;
    border-left: 1px hsla(200, 10%, 50%,100);
    color: #D9D9D9;
    width:1.5px;
    height: auto; 
}
</style>

<style>
@media (min-width: 1169px){
    .subscriptions .modal-lg, .modal-xl {
        max-width: 90vw;
    }
}
.subscriptions .modal-content{
    border-radius: 15px !important;
    max-width: 90vw !important;

}
</style>