<template>
    <div>
        <b-modal :id="name" centered header-class="border-0 pt-4 pb-0" no-close-on-backdrop :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md" @shown="handleShownModal" @hide="handleHideModal">
          <div class="d-flex justify-content-end mb-2">
            <button
              class="btn btn-close d-flex justify-content-center align-items-center close-image"
              @click="closeModal"
            >
              <b-icon icon="x"></b-icon>
            </button>
          </div>
          <div class="input-pin container-fluid my-3 px-5">
			<template v-if="type == 'create'">
				<h3 v-if="titleType == 'create'" class="input-pin__header">{{ step == 1 ? 'Create PIN' : 'Confirm PIN' }}</h3>
				<h3 v-else class="input-pin__header">{{ step == 1 ? 'Create New PIN' : 'Confirm New PIN' }}</h3>
				<h5 v-if="titleType == 'create'" class="font-weight-normal mt-2">{{ step == 1 ? 'Enter your 6 digit Soca PIN' : 'Re-enter your Soca PIN' }}</h5>
				<h5 v-else class="font-weight-normal mt-2">{{ step == 1 ? 'Enter your new 6 digit Soca PIN' : 'Re-enter your new Soca PIN' }}</h5>
			</template>
			<template v-else>
				<h3 class="input-pin__header">Change PIN</h3>
				<h5 class="font-weight-normal mt-2">Enter your existing 6 digit Soca PIN</h5>
			</template>
			<div class="my-3 mx-md-3">
				<div style="display: flex; flex-direction: row;" class="verivikasi-pin d-flex justify-content-center mb-1">
					<v-otp-input
						ref="otpInput"
						:input-classes="pin_wrong != null && pin_wrong == true ? 'otp-input form-control text-center not-match' : 'otp-input form-control text-center'"
						input-type="password"
						separator="&nbsp;&nbsp;&nbsp;"
						:num-inputs="6"
						:should-auto-focus="true"
						:is-input-num="true"
						@on-change="handleOnChange"
						@on-complete="handleOnComplete"
					/>
				</div>
				<p v-if="pin_wrong != null && pin_wrong == true" class="validator__input">The code you entered is incorrect.</p>
			</div>
			<h5 v-if="type == 'create'" class="text-center font-weight-normal" :class="pin_wrong != null && pin_wrong == true ? 'mt-4' : 'mt-5'">PIN must be a number, avoid using a combination easy-to-guess numbers, such as birthdays or cell phone number.</h5>
			<button :disabled="type == 'change' ? isButtonDisabledPin : type == 'create' && step == 1 ? isButtonDisabledOtp : type == 'create' && step == 2 ? isButtonDisabledOtpConfirm : ''" class="btn btn-next btn-md btn-block mt-4" @click="next()">{{ loading ? 'Loading...' : 'Next' }}</button>
			<h5 v-if="type != 'create'" class="forgot-pin__action cursor-pointer mt-3" @click="$emit('forgot-pin')">Forgot PIN</h5>
          </div>
        </b-modal>
    </div>
</template>

<script>
import { setPin } from '@/services/pin/pin.service'
import { AlertUtils } from "@/mixins/AlertUtils";
import { confirmPin } from '@/services/pin/pin.service'

export default {
	mixins: [AlertUtils],
	data() {
		return {
			otp: null,
			otp_confirm: null,
			pin: null,
			pin_wrong: null,
			loading: false,
			step: 1
		}
	},

	emits: ['forgot-pin'],

	props: {
		type: {
			validator(value) {
				return ['create', 'change'].includes(value);
			},
			default: 'create',
		},
		titleType: {
			validator(value) {
				return ['create', 'change'].includes(value);
			},
			default: 'create',
		},
		name: {
			type: String,
			required: true,
		},
	},

	computed: {
      isButtonDisabledOtp() {
        return !this.otp || this.otp.toString().length < 6
      },

	  isButtonDisabledOtpConfirm() {
        return !this.otp_confirm || this.otp_confirm.toString().length < 6
      },

	  isButtonDisabledPin() {
        return !this.pin || this.pin.toString().length < 6
      }
    },

	methods :{
		handleOnChange(val) {
			if(this.type == 'create') {
				if(this.step == 1) {
					this.otp = val
				} else {
					this.otp_confirm = val
				}
			} else {
				this.pin = val
			}
		},

		handleOnComplete(val) {
			if(this.type == 'create') {
				if(this.step == 1) {
					this.otp = val
				} else {
					this.otp_confirm = val
				}
			} else {
				this.pin = val
			}
		},

		next() {
			if(this.type == 'create') {
				if(this.step == 1 && !this.isButtonDisabledOtp) {
					this.step++
					this.$refs.otpInput.clearInput();
				} else {
					if(!this.isButtonDisabledOtpConfirm) {
						if(this.otp_confirm != this.otp) {
							this.pin_wrong = true
						} else {
							this.loading = true
							let formData = {
								newPIN: this.otp,
								confirmPIN : this.otp_confirm
							}

							this.pin_wrong = null
							setPin(formData)
							.then((response) => {
								if(response.status) {
									this.alertSuccess("PIN has been set")
									this.closeModal()
									this.loading = false
								} else {
									this.loading = false
								}
							})
						}
					}
				}
			} else {
				if(!this.isButtonDisabledPin) {
					this.confirmPin()
				}
			}
		},

		confirmPin() {
			let formData = {
				pin : this.pin
			}

			this.loading = true

			confirmPin(formData)
			.then((response) => {
				if(response.status) {
					this.$refs.otpInput.clearInput();
					this.$emit('update:type', 'create');
					this.pin_wrong = null
					this.loading = false
				} else {
					this.$refs.otpInput.clearInput();
					this.$emit('update:type', 'change');
					this.pin_wrong = true
					this.loading = false
				}
			})
		},

		closeModal() {
			this.$bvModal.hide(this.name)
			this.step = 1
			this.otp = null,
			this.otp_confirm = null,
			this.pin_wrong = null
			this.ping = null
			this.$refs.otpInput.clearInput();
			this.$emit('update:type', 'checkPin');
		},

		addEnterKeyListener(event) {
			if (event.key === 'Enter') {
				this.next()
			}
		},
		handleShownModal() {
			window.addEventListener("keydown", this.addEnterKeyListener);
		},
		handleHideModal() {
			window.removeEventListener("keydown", this.addEnterKeyListener);
		}
	}
}
</script>

<style scoped>
.btn-next {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
	font-weight: bold;
	font-size: 16px;
    box-shadow: none !important;
	padding: 15px;
	border-radius: 10px;
}

.btn-next:disabled {
	background-color: #2D2D2D;
    border: 1px solid #2D2D2D;
	color: #585859;
}

.validator__input {
	color: #DF2723;
	font-weight: 400;
	margin: 0;
}

.forgot-pin__action {
	color: #6D75F6;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#profile-create-pin-modal .modal-content, #topup-create-pin-modal .modal-content, #forgot-create-pin-modal .modal-content{
    border-radius: 12px !important;
    background-color: #222222;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

.input-pin {
	color: white;
	text-align: center;
}

.input-pin__header {
	font-size: 30px;
	font-weight: 900;
}

.btn-close {
  background-color: #ededed;
  border-radius: 50%;
  width: 15px;
  height: 26px;
}
</style>

<style scoped lang="less">
  .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
      border: 1px solid red !important;
    }
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>

<style>
.verivikasi-pin .form-control{
    color: white;
    background-color:transparent;
    border: 1px solid #383838;
    max-width: 45px;
	height: 50px;
    padding: 0;
}

.verivikasi-pin input:focus{
    color: white;
    background-color:transparent;
}

.not-match {
	border: 1px solid #DF2723 !important;
}
</style>