import axios from "../../config/axios"
import { base_url_machine_learning, base_url_asset } from "../../config/base_url";

export async function postVideos(formData) {
    return axios.post(base_url_machine_learning + 'vgen/post_vids', formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error.response }
        return data;
    })
}

export async function postDocuments(formData) {
    return axios.post(base_url_machine_learning + 'quiz/file-summary', formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error.response }
        return data;
    })
}

export async function trimVideos(formData) {
    return axios.post(base_url_machine_learning + 'vgen/trim_vids', formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error.response }
        return data;
    })
}

export async function postImageConverter(formData) {
    return axios.post(base_url_asset + 'api/converter/file/image', formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch(error => {
        const data = { status : false, response: error.response }
        return data
    })
}

export async function getAssets(type) {
    return axios.get(base_url_asset + 'api/assets?type=' + type)
    .then((res) => {
        const data = res.data
        return data
    })
    .catch((error) => {
        const data = { status : false, response: error.response }
        return data
    })
}

export async function getDetailAssets(id) {
    return axios.get(base_url_asset + 'api/assets-groups/detail/' + id)
    .then((res) => {
        const data = res.data
        return data
    })
    .catch((error) => {
        const data = { status : false, response: error.response }
        return data
    })
}

export async function saveToAssets(formData) {
    return axios.post(base_url_asset + 'api/assets/save-to-assets', formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error.response }
        return data;
    })
}

export async function renameAsset(id, formData) {
    return axios.patch(base_url_asset + 'api/assets/rename/' + id, formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function deleteAsset(id) {
    return axios.delete(base_url_asset + 'api/assets/delete/' + id)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function deleteFolder(id) {
    return axios.delete(base_url_asset + 'api/assets/file-group/' + id)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function getStockVideo(search) {
    return axios.get(base_url_asset + 'api/free-stock-video?search=' + search)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error.response }
        return data;
    })
}