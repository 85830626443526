var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showMenu)?_c('div',{ref:"dropMenu",staticClass:"drop-menu"},[_c('div',{staticClass:"inner-drop__menu"},[_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"px-4 mt-1"},[_c('span',[_vm._v("Start with")]),_c('div',{staticClass:"items-create mt-2 cursor-pointer"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row align-items-center cursor-pointer",on:{"click":function($event){return _vm.$bvModal.show('impor-soal-excel')}}},[_vm._m(0),_vm._m(1)])])])]),_c('hr',{staticStyle:{"background":"#2d2d2d"}}),_c('div',{staticClass:"px-4 mb-2"},[_c('span',[_vm._v("Or start from an AI Generator")]),_vm._l((_vm.ai_generator),function(data,index){return _c('div',{key:index,staticClass:"items-create mt-2 cursor-pointer",on:{"click":function($event){index == 1
					? _vm.$router.push({
						name: 'Generate-Text-To-Audio',
						params: { mode: 'new', id: '1' },
						})
					: index == 0
					? _vm.toTtq()
					: ''}}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-1 d-flex align-items-center justify-content-center"},[_c('img',{attrs:{"src":require(`@/assets/images/icons/${data.icon}`),"alt":data.title}})]),_c('div',{staticClass:"col-11"},[_c('p',{staticClass:"mb-0 ml-2"},[_vm._v(" "+_vm._s(data.title)+" "),(index == 2)?_c('small',{staticStyle:{"color":"#585859"}},[_c('i',[_vm._v("soon")])]):_vm._e()])])])])])})],2)])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-1 d-flex align-items-center justify-content-center"},[_c('img',{attrs:{"src":require("@/assets/images/icons/ic_excel_smaller.svg"),"alt":"Import Quiz"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-11"},[_c('p',{staticClass:"mb-0 ml-2"},[_vm._v("Import Quiz")])])
}]

export { render, staticRenderFns }