import axios from "../../config/axios"
import { base_url_api_referral } from '../../config/base_url'

export async function checkLinkReferral() {
    return axios.get(base_url_api_referral +'api/check-link-referral')
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}
export async function generateLink() {
    return axios.get(base_url_api_referral +'api/generate')
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}
export async function checkDiscount() {
    return axios.get(base_url_api_referral +'api/check-discount-package')
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}
