import axios from "../../config/axios";
import ApiRoute from "../api.route";
import { base_url_landing_page }  from "../../config/base_url";

export async function createNewsReader() {
    return axios.post(ApiRoute.newsReader + '/create')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function generateEmbedCode(formData, id) {
    return axios.post(ApiRoute.newsReader + '/generate-embed-code/' + id, formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function saveToProjectNewsReader(formData, id) {
    return axios.put(ApiRoute.newsReader + '/save/' + id, formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function getDetailNewsReader(id) {
    return axios.get(ApiRoute.newsReader + '/detail/' + id)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function getDataNewsReader() {
    return axios({
      method: 'GET',
      url: ApiRoute.newsReader + '/list'
    }).then((res) => {
      const data = {
        status: res.data.status,
        data: res.data.data,
        message: res.data.message
      }
      return data;
    }).catch((error) => {
      const data = { status: false, response: error }
      return data;
    })
  }

  export async function getAllowWatermark(id) {
    return axios.get(ApiRoute.newsReader + '/check/watermark/' + id)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function paymentRemoveWM(formData, id) {
    return axios.post(ApiRoute.swcPay + '/remove-watermark/' + id, formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}

export async function checkBalanceSwcRemoveWM() {
    return axios.get(base_url_landing_page + ApiRoute.swcPay + '/check-price-remove-watermark')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        return data;
    })
}