import axios from "../../config/axios"
import ApiRoute from '../api.route';

import { base_url_notifications } from "../../config/base_url";

export async function getNotifications() {
    return axios.get(base_url_notifications + ApiRoute.notifications)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}

export async function getTotalNotifications() {
    return axios.get(base_url_notifications + ApiRoute.notifications + '/number')
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}
