<template>
    <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.046875" width="24.3458" height="5.16427" rx="0.920038" :fill="fill"/>
        <rect x="0.046875" y="8.85156" width="24.3458" height="5.16427" rx="0.920038" :fill="fill"/>
        <rect x="0.046875" y="17.708" width="24.3458" height="5.16427" rx="0.920038" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    props: {
        fill:{
            type: String,
            default: '#2D2D2D'
        }
    }
}
</script>