<template>
	<b-modal :id="id" centered hide-header hide-footer dialog-class="adf__dialog d-flex align-items-center justify-content-center" :no-close-on-backdrop="isSubmit" content-class="adf" size="md" body-class="adf__body-class position-relative" @hidden="handleHideModal">
        <button :disabled="isSubmit" class="btn d-flex p-0 button--close-srt justify-content-center align-items-center pr-0" @click="$bvModal.hide(id)"><b-icon icon="x"></b-icon></button>
		<div class="feedback__content d-flex flex-column">
			<p class="feedback__content--title text-center">We'd love to hear about your experience with our Auto Dubbing feature. <br>Please choose from the options below or share your thoughts:</p>
			<div class="feedback__content-list d-flex flex-column w-100">
				<button v-for="(data, index) in feedbackList" :key="index" class="btn button--feedback w-100 py-4" :class="{ 'button--feedback-active' : choosedFeedback == data.id }" @click="choosedFeedback = data.id">{{ data.text }}</button>
			</div>
			<p class="feedback__content--text text-center mb-0 mt-4">Feel free to share additional comments or suggestions. <i style="color: #585859;">(optional)</i></p>
			<div class="feedback__content-tw position-relative">
				<textarea v-model="feedbackText" maxlength="200" class="feedback__content--textarea text-white"></textarea>
				<span class="feedback__text--counter">{{ feedbackText?.length ? feedbackText?.length : '0' }}/200</span>
			</div>
			<button class="btn btn--adf-submit mx-auto mt-3" :disabled="!choosedFeedback || isSubmit" @click="submitFeedback">{{ isSubmit ? 'Submiting...' : 'Submit' }}</button>
		</div>
    </b-modal>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			default: 'ad-feedback-modal'
		},
		isSubmit: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			feedbackText: null,
			choosedFeedback: null,
			feedbackList: [
				{
					id: 1,
					text: '👍 It worked seamlessly!'
				},
				{
					id: 2,
					text: '😐 The speech and audio are not accurate'
				},
				{
					id: 3,
					text: '👎 Speaker assigned incorrectly'
				},
			]
		}
	},
	methods: {
		submitFeedback() {
			this.$emit('click:submit-feedback', { option : this.choosedFeedback, comment : this.feedbackText })
		},
		handleHideModal() {
			this.feedbackText = null
			this.choosedFeedback = null
		}
	}
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");

.button--close-srt {
	width: 28px;
	height: 28px;
	font-size: 16px;
	border-radius: 50%;
	color: #000;
	background-color: #fff;
	position: absolute ;
	top: -8px;
	right: -8px;
}

.btn--adf-submit {
	background-color: #6D75F6;
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	border-radius: 5px;
	padding: 10px 14px;
	width: 161px;

	transition: background-color 0.25s;
}

.btn--adf-submit:not(:disabled):hover {
	background-color: #545EED;
}

.btn--adf-submit:disabled {
	background-color: #2d2d2d;
	color: #8C8C8C;
}


.feedback__content {
	padding: 14px 16px;
	gap: 8px;
}

.feedback__content--title {
	color: #FFF;
	text-align: center;
	font-family: Avenir Next;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 140%; /* 25.2px */
}

.feedback__content-list {
	padding: 0 126px;
	gap: 12px;
}

.button--feedback {
	border-radius: 10px;
	border: 1px solid #2D2D2D;
	background-color: #2D2D2D;

	color: #FFF;
	text-align: center;
	font-family: Avenir Next;
	font-size: 18px;
	font-weight: 500;

	transition: border 0.25s;
}

.button--feedback:hover, .button--feedback-active {
	border: 1px solid #6D75F6;
}

.feedback__content--text {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.feedback__content--textarea {
	background: transparent;
	border-radius: 10px;
	border: 1px solid #585859;
	padding: 12px;
	resize: none;
	height: 100%;
	width: 100%;
}

.feedback__content-tw {
	margin: 0 126px;
	height: 100px;
	position: relative;
}

.feedback__text--counter {
	color: #585859;
	font-family: Avenir Next;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	position: absolute;
	bottom: 10px;
	right: 10px;
}
</style>

<style>
.adf__dialog {
  max-width: none;
}

.adf {
  width: 761px;
  background-color: #1F1F1F;
  border-radius: 10px;
}
</style>