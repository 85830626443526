export default [
	{
		url:"/quiz",
		name:"Template Quiz",
		icon:"article"
	},
	{
		url:"/my-quiz",
		name:"My Quiz",
		icon:"article"
	},
	{
		url:"/report",
		name:"Report",
		icon:"assessment"
	},
]