<template>
    <b-modal id="modal--generate_referral" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" modal-class="cr__modal" content-class="cr__content--referral" dialog-class="cr__dialog d-flex justify-content-center" size="md" no-close-on-backdrop @show="setupdata">
			<div class="container-fluid">
				<div class="d-flex justify-content-end mb-2">
					<button class="btn button--cr__close d-flex align-items-center justify-content-center" @click="$bvModal.hide('modal--generate_referral')">
						<font-awesome-icon icon="fa-solid fa-xmark"/>
					</button>
				</div>

            <div class="header--modal">
                <h5 class="title--modal mb-3">{{ $t('referral-title') }}</h5>
                <p class="desc--title_modal"> <span v-html="$t('referral-desc')"></span> <a href="https://get.soca.ai/referral-program/" target="_blank">{{ $t('here') }}.</a></p>
            </div>

            <div class="list__step mt-4">
                <h6 class="mb-2">{{ $t('how-to-get-commision') }}</h6>
                <div class="d-flex align-items-start justify-content-between">
                    <div class="step" v-for="(step, index) in list_step" :key="index">
                        <span class="number--step">{{ $t('step') }} {{ index+1 }}</span>
                        <h5 class="title__step my-1">{{ step.title }}</h5>
                        <div class="desc__step" v-html="step.desc"></div>
                    </div>
                </div>
            </div>

            <div class="action--generate mt-5 mb-3">
                <h6 class="title mb-2">{{ $t('your-unique-referral-link')  }}</h6>
                <button v-if="!link_referral" class="btn btn--purple mb-3" :disabled="load_generate_link" @click="$emit('click:generate-link')">{{ load_generate_link ? 'Loading...' : $t('btn-generate-referral') }}</button>
                <div v-else class="position-relative col-8 mt-3 mb-2">
                    <input type="text" v-model="link_referral" id="link_unique" class="form-control credentials" disabled>
                    <div class="tools">
                        <!-- <div class="eye--btn cursor-pointer" @click="showPassword">
                            <font-awesome-icon :icon="typeInput == 'password' ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'" />
                        </div> -->
                        <div class="copy--btn cursor-pointer d-flex align-items-center" @click="copy" data-toggle="tooltip" data-placement="top" title="Copy">
                            <CopyIcons />
                        </div>
                    </div>
                </div>
            <p class="referral--terms mb-4">{{ $t('term-of-use-referral') }} <a href="https://get.soca.ai/referral-program/syarat-dan-ketentuan/" target="_blank">{{ $t('referral-program-terms') }}</a> {{ $t('we') }}.</p>
                <p class="after__have-key mb-5" v-if="link_referral"> <span v-html="$t('access-dashboard-referral')"></span> , <a :href="base_url_referral+'?auth='+token_client+'&lang='+lang" target="_blank">{{ $t('here') }}.</a></p>
            </div>
		</div>
    </b-modal>
</template>

<script>
import { CopyIcons } from '@/components/icons/creator';
import { AlertUtils } from "@/mixins/AlertUtils";
import { base_url_referral } from "@/config/base_url"
export default {
    mixins: [AlertUtils],
    components: {
        CopyIcons
    },
    props: {
        link_referral: {
            type: String,
            default: null
        },
        token_client: {
            type: String,
            default: null
        },
        load_generate_link: {
            type: Boolean,
            default: false
        },
        lang: {
            type: String,
            default: 'en'
        },
    },
	data() {
		return {
            base_url_referral,
			list_step: [],
            typeInput: 'password',
            result_key: null,
		}
	},

	methods: {
        setupdata(){
            this.list_step = [
                {
                    title: this.$t('generate-link'),
                    desc: this.$t('step--1')
                },
                {
                    title: this.$t('share-link'),
                    desc: this.$t('step--2')
                },
                {
                    title: this.$t('earn'),
                    desc: this.$t('step--3')
                }
            ]
        },
		handleChooseRole() {
			this.$router.push({ name : 'Soca-Certified-Creator' })
		},
        showPassword(){
            if(this.show_password){
                this.show_password = false
                this.typeInput = 'password'
            } else {
                this.show_password = true
                this.typeInput = 'text'
            }
        },
        copy(){
            navigator.clipboard.writeText(this.link_referral);
            this.alertSuccess('Unique referral link successfully copied')
        },
	}
}
</script>

<style scoped>
.referral--terms a{
    color: #6D75F6;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
}
.referral--terms{
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}
.tools{
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    background-color: #2D2D2D !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.eye--btn{
    border-right: 1px solid #585859;
}
.form-control {
    background-color: transparent;
    border: 1px solid #585859;
    color: #fff;
}
.copy--btn{
    border-left: 1px solid #585859;
}
.copy--btn, .eye--btn{
    padding: 0px 9px;
}
.credentials:disabled{
    background: #2D2D2D;
    border-color: #2D2D2D !important;
}
.title--modal{
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}
.desc--title_modal a{
    color: #6D75F6;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
.desc--title_modal{
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 100;
}
.number--step{
    color: #8C8C8C;
    font-family: Avenir Next;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
.title__step{
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.action--generate .title{
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
.btn--purple:not(:disabled):hover {
    background-color: #545EED;
}
.btn--purple {
    color: #fff;
    background-color: #6D75F6;
    border-radius: 4px;
    transition: background-color 0.25s;
}
.desc__step{
    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
}
.after__have-key a{
    color: #6D75F6 !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}
.after__have-key{
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
}
.list__step h6{
    color: #FFF;
    font-family: Avenir Next;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
.button--cr__close {
	width: 27px;
	height: 27px;
	border-radius: 50%;
	background-color: #fff;
	color: black;
}

.cr--main {
	gap: 14px;
}

.cr--list-role {
	gap: 24px;
}

.cr--title {
	color: #FFF;
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.cr--role__card {
	border-radius: 8px;
	background-image: linear-gradient(to bottom, #FFFFFF33, #ffffff00);
	/* background-image: 1px solid rgba(255, 255, 255, 0.20); */
	padding: 1px;
}

.cr--role__card-inner {
	background: #222222;
	border-radius: 8px;
	backdrop-filter: blur(2px);
}

.cr--role__main {
	gap: 4px;
}

.cr--role__title {
	color: #FFF;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.cr--role__description {
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
</style>

<style>
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#modal--generate_referral .modal-content{
    border-radius: 12px !important;
    background-color: #1f1f1f;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	color: #fff;
}

.cr__modal + .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.cr__dialog {
	max-width: none;
}

.cr__content--referral {
	width: 650px !important;
}
</style>
