import axios from "../../config/axios"
import ApiRoute from '../api.route';
import { base_url_machine_learning } from '../../config/base_url'

export async function getAudio(){
    return axios({
        method : 'GET',
        url : ApiRoute.contents + '/audio'
      }).then((res) => {
        const data = {
            status:res.data.status, 
            data:res.data.data, 
            message:res.data.message
        }
        return data;
      }).catch((error) => {
        const data = {status : false , response : error }    
        return data;
      })
}

export async function downloadAudio(formData) {
  return axios.post(base_url_machine_learning + ApiRoute.prosa + "/export", formData)
      .then(res => {
          const data = res.data
          return data;
      })
      .catch((error) => {
          const data = { status: false, response: error }
          return data;
      })
}