<template>
  <div class="loading-screen" :class="type == 'generate' ? 'position-absolute bg-layer-1' : 'loading--fixed bg-blur'">
    <vue-lottie
      ref="lottieAnimation"
      :options="animationOptions"
      :height="200" :width="200"
    ></vue-lottie>
  </div>
</template>

<script>
import VueLottie from 'vue-lottie';
import loadingAnimation from '@/assets/soca-load.json';

export default {
  components: {
    VueLottie,
  },
  props: {
    type: {
      validator(value) {
        return ['default', 'generate'].includes(value);
      },
      default: 'default',
    },
  },
  data() {
    return {
      animationOptions: {
        animationData: loadingAnimation,
        loop: true,
        autoplay: true,
        scale: 0.1,
      },
    };
  },
  methods: {
    onAnimationLoaded() {
      const lottieAnimation = this.$refs.lottieAnimation.$lottie;
      console.log(lottieAnimation)
      // Gunakan lottieAnimation untuk mengakses API Lottie
    },
  },
};
</script>

<style scoped>
.loading-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 499;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.5);  */
  /* backdrop-filter: blur(5px);  */
}

.bg-layer-1 {
  background-color: #1F1F1F;
  border-radius:10px;
}

.bg-blur {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

.loading--fixed {
  position: fixed;
  top: 0;
}
</style>
