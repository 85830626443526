<template>
    <div class="choose-avatar">
        <b-modal id="choose-avatar-modal" ref="plans" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
            <div class="container-fluid text-center">
                <div class="d-flex align-items-center justify-content-between">
                    <h4>Choose Avatars</h4>
                    <div class="d-flex">
                        <select id="inputState" class="form-control mr-3">
                            <option selected>All</option>
                            <option>Male</option>
                            <option>Female</option>
                        </select>
                        <img src="@/assets/images/icons/ic_close_modal.svg" alt="Close" class="cursor-pointer img-fluid">
                    </div>
                </div>
                <div class="card-container mt-4">
                    <div v-for="(data, index) in listAvatar" :key="index" class="card-avatar">
                        <img :src="require(`@/assets/images/${data.img}`)" alt="" class="">
                        <div class="d-flex align-items-center justify-content-between mt-3">
                            <h5 class="mb-0 font-weight-normal">{{ data.name }}</h5>
                            <button class="btn btn-purple">Use</button>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            listAvatar : [
                {
                    img : 'Mutia.svg',
                    name : 'Mutia',
                    path : null,
                },
                {
                    img : 'Syarif.svg',
                    name : 'Syarif',
                    path : null,
                },
                {
                    img : 'Mutia.svg',
                    name : 'Marina',
                    path : null,
                },
                {
                    img : 'Syarif.svg',
                    name : 'Rahman',
                    path : null,
                },
            ]
        }
    },
}
</script>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

.choose-avatar {
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#choose-avatar-modal .modal-content{
    background-color: #222222;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
}
</style>

<style scoped>
.choose-avatar h4 {
    font-size: 20px;
}

.card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 3rem;
}

.card-avatar {
    border-radius: 10px;
    display: grid;
}

.card-avatar img {
    width: 100%;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
}

.form-control {
    border-radius: 20px;
    background-color: #222222;
    border: 1px solid #585859;
    color: white;
}
</style>