<template>
    <div>
        <b-modal id="modal-create-content" ref="subs" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md">
            <div class="float-right">
                <img src="@/assets/images/icons/ic_close_grup.svg" @click="$bvModal.hide('modal-create-content')" alt="close" class="mr-auto" type="button">
            </div>
            <div class="container-md px-4 pt-3">
                <h2 v-if="users" class="font-weight-normal mb-2">Halo, <span class="font-weight-bold">{{ users.name }}</span></h2>
                <h2 v-else class="font-weight-normal mb-2">Halo</h2>
                <h4 class="font-weight-normal">Ingin buat konten apa hari ini?</h4>

                <div class="card mt-3 type-question" type="button" @click="addQuiz('quiz')">
                    <div class="card-body p-2">
                        <div class="container-fluid my-3 p-0">
                            <div class="row align-items-start mx-1">
                                <div class="col-2">
                                    <img src="@/assets/images/icons/ic_quiz_content.svg" alt="quiz" class="img-fluid">
                                </div>
                                <div class="col-10">
                                    <div class="ml-3">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h4>Quiz</h4>
                                            <img src="@/assets/images/icons/ic_arrow_content.svg" alt="next">
                                        </div>
                                        <p class="font-weight-normal mb-0 w-75 mt-2">Kreasikan konten pembalajaran dengan berbagai macam kuis</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="card mt-3 type-question" type="button" @click="addQuiz('quiz')">
                    <div class="card-body p-2">
                        <div class="container-fluid my-3 p-0">
                            <div class="row align-items-start mx-1">
                                <div class="col-2">
                                    <img src="@/assets/images/icons/ic_polls_content.svg" alt="polls" class="img-fluid">
                                </div>
                                <div class="col-10">
                                    <div class="ml-3">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h4>Polls</h4>
                                            <img src="@/assets/images/icons/ic_arrow_content.svg" alt="next">
                                        </div>
                                        <p class="font-weight-normal mb-0 w-75 mt-2">Dapatkan data yang akurat melalui pengalaman poling yang menyenangkan</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="card mt-3 type-question" type="button" @click="addCourse">
                    <div class="card-body p-2">
                        <div class="container-fluid my-3 p-0">
                            <div class="row align-items-start mx-1">
                                <div class="col-2">
                                    <img src="@/assets/images/icons/ic_course_content.svg" alt="course" class="img-fluid">
                                </div>
                                <div class="col-10">
                                    <div class="ml-3">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h4>Course</h4>
                                            <img src="@/assets/images/icons/ic_arrow_content.svg" alt="next">
                                        </div>
                                        <p class="font-weight-normal mb-0 w-75 mt-2">Bagikan pengetahuan & materi pembelajaran ke semua pengguna</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card bg-generative mt-3 mb-5" type="button" @click="addQuiz('generative-ai')">
                    <div class="card-body p-2">
                        <div class="container-fluid my-3 p-0">
                            <div class="row align-items-start mx-1">
                                <div class="col-2 d-flex justify-content-center">
                                    <img src="@/assets/images/icons/ic_generative_ai.svg" alt="ai" class="img-fluid">
                                </div>
                                <div class="col-10">
                                    <div class="ml-3">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h4 class="text-white">Generative AI <small>(beta)</small></h4>
                                            <img src="@/assets/images/icons/ic_arrow_content_white.svg" alt="next">
                                        </div>
                                        <p class="font-weight-normal mb-0 w-75 mt-2 text-white">Membuat quiz secara instant dengan bantuan kecerdasan buatan</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {postContent} from '@/services/quiz-creator/quiz-creator.service'

export default {
    data(){
        return {
            users: {}
        }
    },

    mounted(){
        setTimeout(() => {
            if(localStorage.getItem('users')){
                this.users = JSON.parse(localStorage.getItem('users'))
            }
        }, 500);
    },

    methods: {
        addQuiz(tipe){
            if(this.users){
                localStorage.removeItem("listQuestionCreator");
                localStorage.removeItem("newListQuestionCreator")
                localStorage.removeItem("questionNew")
                localStorage.removeItem("listQuestion")
                localStorage.removeItem("listQuestionCreate")
                localStorage.removeItem("listQuestionCreatePolling")
                localStorage.removeItem("backUpPollingQuestion")
                localStorage.removeItem("backUpCreateQuestion")
                localStorage.removeItem("backUpUpdateQuestion")
                localStorage.removeItem("quiz_name")
                localStorage.removeItem("quiz_description")
                localStorage.removeItem("images_cover")
                localStorage.removeItem("category_id")
                localStorage.removeItem("createTag")
                localStorage.removeItem("quiz_name_polling")
                localStorage.removeItem("quiz_description_polling")
                localStorage.removeItem("images_cover_polling")
                localStorage.removeItem("category_id_polling")
                localStorage.removeItem("materiAi")
                localStorage.removeItem("gradeAi")
                localStorage.removeItem("subGradeAi")
                localStorage.removeItem("descriptionAi")
                localStorage.removeItem('lengthQuestionAi')
                localStorage.removeItem("grade")
                localStorage.removeItem("sub_grade")
                localStorage.removeItem("index_grade")


                this.$store.state.dataQuiz.category_id = null,
                this.$store.state.dataQuiz.quiz_name = null,
                this.$store.state.dataQuiz.description = null,
                this.$store.state.dataQuiz.images_cover = null

                let formData = {
                    content_type : tipe
                };

                postContent(formData)
                .then((response) =>{
                    if(response.status && response.status == true){
                        if(tipe == 'quiz'){
                            this.$router.push({ name: 'Create-My-Quiz'})
                        } else if (tipe == 'polling'){
                            this.$router.push({ name: 'Setup-Polling'})
                        } else if(tipe == 'generative-ai') {
                            // if(this.users.subscribe == true) {
                                this.$router.push({ name: 'Generative-Ai'})
                            // }
                        }
                    }
                })
            } else {
                this.$bvModal.show('signIn')
            }
        },
        addCourse() {
            if(this.users){
                // if(this.users.subscribe == true) {
                    this.$router.push({ name: 'Create-My-Course'})
                // }
            } else {
                this.$bvModal.show('signIn')
            }
        }
    }
}
</script>

<style scoped>
h3 {
    font-size: 22px;
}
.btn-close{
    background-color: #EDEDED;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.card {
    border-radius: 10px !important
}

.bg-generative {
    border: 0 !important;
    background: rgb(11,189,251);
    background: linear-gradient(135deg, rgba(11,189,251,1) 0%, rgba(188,59,242,1) 56%);
}

.type-question:hover{
    border-radius: 10px !important;
    background: rgb(255,255,255);
    background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, #0bbdfb, #bc3bf2) border-box;
    border: 2px solid transparent;
}

</style>

<style>
#modal-create-content .modal-content{
    border-radius: 10px !important;
}
</style>