<template>
    <div class="upgrade-plans">
        <b-modal id="upgrade-plans-modal" ref="plans" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
            <div class="px-3 py-2">
                <div class="d-flex justify-content-between align-items-center text-white mb-5">
                    <div></div>
                    <h3 >Upgrade your plans</h3>
                    <button class="btn btn-close d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('upgrade-plans-modal')"><img src="@/assets/images/icons/ic_close_grup.svg" alt=""></button>
                </div>
                <div class="container-fluid text-white">
                    <div class="row align-items-start text-center mt-3 mx-3">
                        <div class="col-md-4">
                            <div class="basic mx-2">
                                <h3 class="plans-titles mt-5 mb-4">{{ package1.package_name }}</h3>
                                <h3 class="mt-3 price-title" style="font-weight:800">{{ package1.price_usd == 0 ? 'Free' : package1.price_IDR }}</h3>
                                <span class="font-weight-normal">&nbsp;</span>
                                <br>
                                <span class="font-weight-normal anually">&nbsp;</span>
                                <div class="mx-4 mt-4 mb-5 text-left">
                                    <h5>Quiz</h5>
                                    <p class="my-2">Unlimited Quiz Import</p>
                                    <p class="my-2">{{ package1.player_limitation }} Players</p>
                                    <p class="mb-2">{{ package1.question_generates }} Question Generations</p>
                                    <!-- <p class="mb-2">{{ package1.quiz_avatar }} Avatars</p> -->

                                    <h5 class="mt-4">Audio & Video</h5>
                                    <p class="my-2">{{ package1.voice_type }} Voice Types</p>
                                    <p class="mb-2">{{ package1.voice_language }} Voice Languages</p>
                                    <p class="mb-2">{{ package1.audio_duration }} mins Audio</p>
                                    <p class="mb-2">{{ package1.video_duration != 0 ? package1.video_duration : '-'  }}</p>
                                    <p class="mb-4">{{ package1.content_avatar != null ? package1.content_avatar : '-' }}</p>
                                    <button v-if="package1.use_package == false" type="button" class="btn btn-purple text-white btn-lg btn-block">Use Plan</button>
                                    <button v-else type="button" class="btn btn-current text-white btn-lg btn-block" disabled>Current Plan</button>
                                </div>
                            </div>  
                        </div>
                        <div class="col-md-4 position-relative">
                            <div class="most-populer d-flex justify-content-center position-absolute">
                                <div class="inner-populer">
                                    <p class="mx-4 my-2 font-weight-normal mb-0" style="font-size:12px">Most Popular</p>
                                </div>
                            </div>
                            <div class="edison mx-2">
                                <h3 class="plans-titles mt-5 mb-4">{{ package2.package_name }}</h3>
                                <div class="d-flex align-items-center justify-content-center mt-3 ">
                                    <h4 class="discount-price font-weight-normal mr-1">${{ package2.strike_price_usd }}</h4>
                                    <h3 class="price-title d-flex align-items-end justify-content-center">${{ package2.price_usd }}</h3>
                                </div>
                                <span class="font-weight-normal">/user/month</span>
                                <br>
                                <span class="font-weight-normal anually">${{ package2.price_usd*12 }} billed annually</span>
                                <div class="mx-4 mt-4 mb-5 text-left">
                                    <h5>Quiz</h5>
                                    <p class="my-2">Unlimited Quiz Import</p>
                                    <p class="my-2">{{ package2.player_limitation }} players</p>
                                    <p class="my-2">{{ package2.question_generates }} Question Generations</p>
                                    <!-- <p class="my-2">{{ package2.quiz_avatar }} Avatars</p> -->

                                    <h5 class="mt-4">Audio & Video</h5>
                                    <p class="my-2">{{ package2.voice_type }} Voice Types</p>
                                    <p class="mb-2">{{ package2.voice_language }} Voice Languages</p>
                                    <p class="mb-2">{{ package2.audio_duration }} mins Audio</p>
                                    <p class="mb-2">{{ package2.video_duration }} mins Video</p>
                                    <p class="mb-4">{{ package2.content_avatar }} Premium Avatars</p>
                                    <button v-if="package2.use_package == false" type="button" class="btn btn-purple text-white btn-lg btn-block" @click="postSubscriptions(package2._id)">Use Plan</button>
                                    <button v-else type="button" class="btn btn-current text-white btn-lg btn-block" disabled>Current Plan</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="einstein mx-2">
                                <h3 class="plans-titles mt-5 mb-4">{{ package3.package_name }}</h3>
                                <div class="d-flex align-items-center justify-content-center mt-3 ">
                                    <h4 class="discount-price font-weight-normal mr-1">${{ package3.strike_price_usd }}</h4>
                                    <h3 class="price-title d-flex align-items-end justify-content-center">${{ package3.price_usd }}</h3>
                                </div>
                                <span class="font-weight-normal">/user/month</span>
                                <br>
                                <span class="font-weight-normal anually">${{ package3.price_usd*12 }} billed annually</span>
                                <div class="mx-4 mt-4 mb-5 text-left">
                                    <h5>Quiz</h5>
                                    <p class="my-2">Unlimited Quiz Import</p>
                                    <p class="my-2">{{ package3.player_limitation }} players</p>
                                    <p class="my-2">{{ package3.question_generates }} Question Generations</p>
                                    <!-- <p class="my-2">{{ package3.quiz_avatar }} Avatars</p> -->

                                    <h5 class="mt-4">Audio & Video</h5>
                                    <p class="my-2">{{ package3.voice_type }} Voice Types</p>
                                    <p class="mb-2">{{ package3.voice_language }} Voice Languages</p>
                                    <p class="mb-2">{{ package3.audio_duration }} mins Audio</p>
                                    <p class="mb-2">{{ package3.video_duration }} mins Video</p>
                                    <p class="mb-4">{{ package3.content_avatar }} Avatars</p>
                                    <button v-if="package3.use_package == false" type="button" class="btn btn-purple text-white btn-lg btn-block" @click="postSubscriptions(package3._id)">Use Plan</button>
                                    <button v-else type="button" class="btn btn-current text-white btn-lg btn-block" disabled>Current Plan</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center text-white mt-5 mb-2">
                    <h4 class="m-0 font-weight-normal">Don't see what you need? <span class="plans-titles cursor-pointer">Contact us.</span></h4>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { packageSubscriptionsModal, subscriptions } from '@/services/subscriptions/subscriptions.service'

export default {
    data() {
        return {
            package1: [],
            package2: [],
            package3: [],
        }
    },

    mounted(){
        this.getPackageSubscriptions()
    },

    methods:{
        getPackageSubscriptions(){
            packageSubscriptionsModal()
            .then((response) => {
                if(response.status == true) {
                    this.package1 = response.data[0]
                    this.package2 = response.data[1]
                    this.package3 = response.data[2]
                }
            })
        },

        postSubscriptions(id){
            this.$vs.loading({
                type: 'sound'
            });

            let payload = {
                package_id : id
            }
            subscriptions(payload)
            .then((response) => {
                if(response.status == true){
                    this.$vs.loading.close();
                    this.$router.push({ name : 'Subscriptions', query : { id : response.data.subscription_id}})
                } else {
                    this.$vs.loading.close();
                }
            })
        }
    }
}
</script>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

.upgrade-plans {
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#upgrade-plans-modal .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1169px){
    #upgrade-plans-modal .modal-lg, .modal-xl {
        max-width: 70vw;
    }
}
</style>

<style scoped>
h3{
    font-size: 24px;
}

h5 {
    font-size: 16px;
}
.basic, .edison, .einstein, .list-border{
    border: 2px solid;
    border-radius: 14px;
}

.basic span, .edison span, .einstein span{
    font-size: 12px;
}

.anually {
    font-size: 12px !important;
}

.basic, .einstein {
    border-color: #2D2D2D;
}

.edison{
    border-color: #BC3BF2;
}

.list-border {
    border-color: #222222
}

.plans-titles {
    color: #6D75F6;
}

.most-populer{
    top: -15px;
    left: 0;
    right: 0px;
}

.inner-populer{
    background-color: #BC3BF2;
    border-radius: 50px;
    width: auto;
}

.btn-purple {
    background-color: #6D75F6
}

.price-title{
    font-size: 40px;
}

.discount-price{
    font-size: 20px;
    text-decoration-line: line-through;
    color: #8C8C8C;
}

.btn-current{
    background-color: #2D2D2D;
}
</style>