<template>
  <div class="ap">
    <div class="ap--content d-flex align-items-center justify-content-between text-white py-1 px-2">
      <button
        type="button"
        class="ap__toggle btn p-0 d-flex align-items-center justify-content-center"
        :disabled="isLoading"
        :title="`${isPlaying ? 'Pause' : 'Play'} ${name}`"
        @click="toggleAudioPlayer"
      >
        <font-awesome-icon
          :icon="`fa-solid ${isPlaying ? 'fa-pause' : 'fa-play'}`"
          class="ap__toggle-icon"
          :class="{ 'ap__toggle-icon--small': size === 'small' }"
        />
      </button>
      <div class="py-2">
        <div ref="waveform" style="width: 500px"></div>
        <!-- <vue-wave-surfer style="width: 200px" :src="src" :options="options"></vue-wave-surfer> -->
        <!-- <av-waveform
          ref-link="audioPlayer"
          :audio-controls="false"
          :canv-width="200"
          :canv-height="30"
          :playtime="false"
          played-line-color="#6D75F6"
          noplayed-line-color="#8C8C8C"
          playtime-slider-color="transparent"
        /> -->
      </div>
      <p class="ap__time m-0">{{ calculateRemainingTime }}</p>
      <audio ref="audioPlayer" :src="src" preload="metadata" />
    </div>
  </div>
</template>

<script>
import getBlobDuration from 'get-blob-duration';
import WaveSurfer from 'wavesurfer.js'

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      validator(value) {
        return ['small', 'medium'].includes(value);
      },
      default: 'medium',
    },
	img: {
		type: String,
		default: ''
	}
  },
  emits: ['play'],
  data() {
    return {
      options: {
        waveColor: '#8C8C8C',
        progressColor: '#6D75F6',
        cursorColor: 'transparent',
        barWidth: 1,
        height: 50,
        media: "",
        mediaControls: false,
        autoplay: false,
        interact: true,
      },
      duration: 0,
      audioDurationStream: 0,
      sliderMax: 100,
      currentTime: 0,
      isLoading: false,
      isPlaying: false,
      doSliding: false,
      wavesurfer: null,
      currentTimeSurfer: 0,
      totalDurationSurfer: 0,
    };
  },
  computed: {
    calculateRemainingTime() {
      const remainingTime = this.totalDurationSurfer - this.currentTimeSurfer;
      return this.calculateTime(remainingTime);
    },
  },
  methods: {
    async loadedmetadataListener() {
      // WaveSurfer.create({
      //   container: this.$refs.waveform,
      //   waveColor: '#8C8C8C',
      //   progressColor: '#6D75F6',
      //   cursorColor: 'transparent',
      //   barWidth: 1,
      //   height: 50,
      //   mediaControls: false,
      //   autoplay: false,
      //   interact: true,
      //   media: document.querySelector('audio'),
      //   mediaContainer: document.querySelector('audio'),
      // })
      if (this.src.search('blob:') >= 0) {
        this.duration = await getBlobDuration(this.src);
        this.audioDurationStream = await getBlobDuration(this.src);
      } else {
        this.duration = this.$refs.audioPlayer.duration;
        this.audioDurationStream = this.$refs.audioPlayer.duration;
      }
      this.sliderMax = Math.floor(this.duration);
    },
    timeupdateListener() {
      const currentSeconds = parseFloat(this.$refs.audioPlayer.currentTime.toFixed(1));
      if (!this.doSliding) {
        this.currentTime = currentSeconds
        this.audioDurationStream = this.duration - currentSeconds;
        if (currentSeconds == Math.floor(this.duration)) {
          this.audioDurationStream = this.duration;
        }
      }

      if (currentSeconds >= parseFloat(this.$refs.audioPlayer.duration.toFixed(1)) && this.$refs.audioPlayer.paused) {
        this.isPlaying = false;
      }
    },
    calculateTime(secs) {
      const minutes = Math.max(Math.floor(secs / 60), 0);
      const seconds = Math.max(Math.floor(secs % 60), 0);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    },
    async toggleAudioPlayer() {
      if (!this.isLoading) {
        this.isLoading = true;

        try {
          if (this.isPlaying) {
            // await this.$refs.audioPlayer.pause();
            this.wavesurfer.pause();
            this.isPlaying = false;
          } else {
            // await this.$refs.audioPlayer.play();
            this.wavesurfer.play();
            this.isPlaying = true;
            this.$emit('play', this.pauseAudioPlayer, this._uid);
          }
        } catch (error) {
          console.log(error);
        }

        this.isLoading = false;
      }
    },
    async pauseAudioPlayer() {
      this.isLoading = true;

      try {
        if (this.isPlaying) {
          this.wavesurfer.pause();
          // await this.$refs.audioPlayer.pause();
          this.isPlaying = false;
        }
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    },
    handleInputSlider() {
      this.doSliding = true;
    },
    handleMouseupSlider() {
      if (this.doSliding) {
        this.doSliding = false;
      }
    },
    handleChangeSlider(e) {
      this.$refs.audioPlayer.currentTime = parseFloat(e.target.value).toFixed(1);
    },
  },
  mounted() {
    this.$refs.audioPlayer.addEventListener('loadedmetadata', this.loadedmetadataListener);
    this.$refs.audioPlayer.addEventListener('timeupdate', this.timeupdateListener);
    this.wavesurfer = WaveSurfer.create({
      container: this.$refs.waveform,
      waveColor: "#8C8C8C",
      progressColor: "#6D75F6",
      height: 25,
      barHeight: 2,
      barWidth: 2,
      barGap: 1.5,
      barRadius: 2,
    });

    this.wavesurfer.on("ready", () => {
      this.totalDurationSurfer = this.wavesurfer.getDuration();
    });

    this.wavesurfer.on("audioprocess", () => {
      this.currentTimeSurfer = this.wavesurfer.getCurrentTime();
    });

    this.wavesurfer.on("finish", () => {
      this.isPlaying = false;
    });

    this.wavesurfer.load(this.src);
    document.addEventListener('mouseup', this.handleMouseupSlider);
  },
  beforeDestroy() {
    this.$refs.audioPlayer.removeEventListener('loadedmetadata', this.loadedmetadataListener);
    this.$refs.audioPlayer.removeEventListener('timeupdate', this.timeupdateListener);
    document.removeEventListener('mouseup', this.handleMouseupSlider);
  },
};
</script>

<style scoped>
.ap {
padding: 1.3px;
background-image: linear-gradient(to bottom, #FFFFFF33, #FFFFFF11);
border-radius: 30px;
}

.ap--content {
background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
border-radius: 30px;
gap: 6px;
}

.ap--img {
	max-width: 35px;
	max-height: 35px;
}

.ap__toggle {
  background-color: #2d2d2d;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.ap__toggle-icon {
  font-size: 14px;
  color: #fff;
}

.ap__toggle-icon--small {
  font-size: 16px;
}

.ap__name {
  font-weight: 500;
}

.ap__name--small {
  font-size: 13px;
}

.ap__slider {
  width: 100%;
  accent-color: #6D75F6;
  cursor: pointer;
}

.ap__slider--small {
  height: 4px;
}

.ap__time {
  font-size: 10px;
}
</style>
